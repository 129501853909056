import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Form, Input, Divider, message } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { useMutation, useQuery } from "react-query"
import * as COMMONS from "common/common"
import * as API from "common/api"

const Login = (props) => {
  const { logo, publicSettings } = props
  const navigate = useNavigate()
  const [form] = Form.useForm()

  useQuery(API.QUERY_KEY_ADMIN_CHECK_SESSION, API.ADMIN_CHECK_SESSION, {
    onSuccess: () => {
      navigate(COMMONS.ADMIN_STORES_ROUTE, {
        state: { type: COMMONS.ADMIN_MEMBERS_ROUTE },
      })
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const loginMutation = useMutation(API.ADMIN_LOGIN, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_LOGIN_MSG)
      navigate(COMMONS.ADMIN_STORES_ROUTE, {
        state: { type: COMMONS.ADMIN_MEMBERS_ROUTE },
      })
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        message.warning(COMMONS.ERROR_ADMIN_LOGIN_MISMATCH_MSG)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const loginHandler = (formData) => {
    const data = {
      username: formData["loginUsername"],
      password: formData["loginPassword"],
    }

    loginMutation.mutate(data)
  }

  return (
    <>
      <div className="flex h-screen">
        <div className="w-11/12 sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-5 m-auto z-10 bg-white bg-opacity-75 animate__animated animate__fadeIn">
          <div className="flex mb-4">
            <img
              src={logo ? API.UPLOADS_URL + logo : "logo.svg"}
              alt="ロゴ"
              className="mx-auto rounded max-w-full"
              style={{ maxHeight: "150px" }}
            />
          </div>
          <div className="mb-10">
            <p className="text-lg text-center">
              {publicSettings?.TITLE || process.env.REACT_APP_SYSTEM_NAME}
            </p>
          </div>
          <Form
            name="loginForm"
            onFinish={loginHandler}
            size="large"
            form={form}
          >
            <Form.Item
              name="loginUsername"
              rules={[
                {
                  required: true,
                  message: "ユーザー名を入力してください",
                },
              ]}
            >
              <Input
                disabled={loginMutation.isLoading}
                name="loginUsername"
                autoCapitalize="none"
                prefix={<UserOutlined />}
                placeholder="ユーザー名"
                autoComplete="username"
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="loginPassword"
              rules={[
                {
                  required: true,
                  message: "パスワードを入力してください",
                },
              ]}
            >
              <Input.Password
                disabled={loginMutation.isLoading}
                name="loginPassword"
                prefix={<LockOutlined />}
                type="password"
                placeholder="パスワード"
                autoComplete="current-password"
              />
            </Form.Item>
            <Form.Item className="text-center">
              <Button
                type="primary"
                htmlType="submit"
                className="px-8"
                loading={loginMutation.isLoading}
              >
                ログイン
              </Button>
            </Form.Item>
          </Form>
          {process.env.REACT_APP_ENV !== "PRODUCTION" &&
            process.env.REACT_APP_TEST_USER &&
            process.env.REACT_APP_TEST_USER_PASSWORD && (
              <>
                <Divider />
                <div className="text-center">
                  <Button
                    type="primary"
                    className="m-1"
                    loading={loginMutation.isLoading}
                    onClick={() => {
                      const data = {
                        username: process.env.REACT_APP_TEST_USER,
                        password: process.env.REACT_APP_TEST_USER_PASSWORD,
                      }

                      loginMutation.mutate(data)
                    }}
                  >
                    テストログイン
                  </Button>
                </div>
              </>
            )}
        </div>
      </div>
    </>
  )
}

export default Login
