import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  Row,
  Col,
  Table,
  Form,
  Input,
  message,
  Divider,
  Tooltip,
  Modal,
  Upload,
  Image,
  Alert,
} from "antd"
import {
  EditOutlined,
  MessageOutlined,
  RobotOutlined,
  EyeOutlined,
  ClusterOutlined,
  DeleteOutlined,
  CameraTwoTone,
  ExclamationCircleOutlined,
} from "@ant-design/icons"
import { useQuery, useMutation, useQueryClient } from "react-query"
import moment from "moment"
import styled from "styled-components"
import * as COMMONS from "common/common"
import * as API from "common/api"
import { socket } from "common/socket"

const checkRichMenuImageSize = (param, callback) => {
  const isSt1M = param.file.size / 1024 / 1024 < 1

  if (isSt1M) {
    const reader = new FileReader()
    reader.readAsDataURL(param.file)
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result
      const image = document.createElement("img")

      image.src = _loadedImageUrl
      image.addEventListener("load", async () => {
        const { width, height } = image

        if (width === 1200 && height === 810) {
          callback({
            file: param.file,
            preview: _loadedImageUrl,
          })
        } else {
          message.warn(COMMONS.WARN_IMAGE_SIZE_DIFFERENT)
        }
      })
    })
  } else {
    message.warn(COMMONS.WARN_IMAGE_TOO_BIG)
  }
}

const CustomUpload = styled(Upload)`
  .ant-upload {
    width: 100%;
  }
`

const Settings = (props) => {
  const { stores, publicSettings, auth } = props
  const navigate = useNavigate()

  const [storeForm] = Form.useForm()
  const [systemColorForm] = Form.useForm()
  const [systemTitleForm] = Form.useForm()
  const [defaultRichmenuForm] = Form.useForm()
  const [memberRichmenuForm] = Form.useForm()
  const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()
  const queryClient = useQueryClient()

  const [logo, setLogo] = useState(undefined)
  const [favicon, setFavicon] = useState(undefined)
  const [selectedStore, setSelectedStore] = useState(undefined)

  const [storeImageFile, setStoreImageFile] = useState(undefined)
  const [isStoreModalVisible, setIsStoreModalVisible] = useState(false)
  const [storeStatus, setStoreStatus] = useState({
    isNew: false,
    storeId: undefined,
  })

  const [richmenus, setRichmenus] = useState({})
  const [isRichmenuModalVisible, setIsRichmenuModalVisible] = useState(false)
  const [defaultRichmenuImageFile, setDefaultRichmenuImageFile] =
    useState(undefined)
  const [memberRichmenuImageFile, setMemberRichmenuImageFile] =
    useState(undefined)

  useQuery(API.QUERY_KEY_LOGO, () => API.GET_LOGO(), {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data) {
          setLogo(response.data)
        } else {
          setLogo(undefined)
        }
      }
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  useQuery(API.QUERY_KEY_FAVICON, () => API.GET_FAVICON(), {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data) {
          setFavicon(response.data)
        } else {
          setFavicon(undefined)
        }
      }
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  useQuery(
    API.QUERY_KEY_ADMIN_RICHMENUS,
    () => API.ADMIN_GET_RICHMENUS({ storeId: selectedStore }),
    {
      enabled: isRichmenuModalVisible && !!selectedStore,
      onSuccess: (response) => {
        if (isMountedRef.current) {
          if (response?.data) {
            setRichmenus(response.data)

            if (response.data.defaultRM?.picUrl) {
              defaultRichmenuForm.setFieldsValue({
                richmenuImage: response.data.defaultRM.picUrl,
                linkA: response.data.defaultRM?.link1,
                linkB: response.data.defaultRM?.link2,
                linkC: response.data.defaultRM?.link3,
              })

              setDefaultRichmenuImageFile({
                preview: `${API.UPLOADS_URL}${response.data.defaultRM.picUrl}`,
              })
            } else {
              defaultRichmenuForm.resetFields()

              setDefaultRichmenuImageFile(undefined)
            }

            if (response.data.memberRM?.picUrl) {
              memberRichmenuForm.setFieldsValue({
                richmenuImage: response.data.memberRM.picUrl,
                linkA: response.data.memberRM?.link1,
                linkB: response.data.memberRM?.link2,
                linkC: response.data.memberRM?.link3,
              })

              setMemberRichmenuImageFile({
                preview: `${API.UPLOADS_URL}${response.data.memberRM.picUrl}`,
              })
            } else {
              memberRichmenuForm.resetFields()

              setMemberRichmenuImageFile(undefined)
            }
          } else {
            setRichmenus({})

            defaultRichmenuForm.resetFields()
            memberRichmenuForm.resetFields()

            setDefaultRichmenuImageFile(undefined)
            setMemberRichmenuImageFile(undefined)
          }
        }
      },
      onError: (error) => {
        if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
          navigate(COMMONS.PERMISSION_ERROR_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
          message.warning(COMMONS.ERROR_SESSION_MSG)
          navigate(COMMONS.ADMIN_LOGIN_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        } else {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        }
      },
    }
  )

  const logoUploadMutation = useMutation(API.ADMIN_UPLOAD_LOGO, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_UPDATE_MSG)
      queryClient.invalidateQueries(API.QUERY_KEY_LOGO)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const faviconUploadMutation = useMutation(API.ADMIN_UPLOAD_FAVICON, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_UPDATE_MSG)
      queryClient.invalidateQueries(API.QUERY_KEY_FAVICON)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const storeUpdateMutation = useMutation(API.ADMIN_UPDATE_STORE, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_UPDATE_MSG)
      hideStoreModal()
      queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_STORES)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const systemColorUpdateMutation = useMutation(API.ADMIN_UPDATE_SYSTEM_COLOR, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_UPDATE_MSG)
      queryClient.invalidateQueries(API.QUERY_KEY_PUBLIC_SETTINGS)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const systemTitleUpdateMutation = useMutation(API.ADMIN_UPDATE_SYSTEM_TITLE, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_UPDATE_MSG)
      queryClient.invalidateQueries(API.QUERY_KEY_PUBLIC_SETTINGS)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const richmenuUpdateMutation = useMutation(API.ADMIN_UPDATE_RICHMENU, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_UPDATE_MSG)
      queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_RICHMENUS)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const richmenuDeleteMutation = useMutation(API.ADMIN_DELETE_RICHMENU, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_DELETE_MSG)
      queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_RICHMENUS)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  useEffect(() => {
    if (!auth) {
      navigate(COMMONS.PERMISSION_ERROR_ROUTE)
    }
  }, [auth, navigate])

  useEffect(() => {
    socket.on("store", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_STORES)
      }
    })

    socket.on("systemSetting", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_PUBLIC_SETTINGS)
      }
    })

    socket.on("favicon", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_FAVICON)
      }
    })

    socket.on("logo", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_LOGO)
      }
    })

    return () => {
      socket.off("store")
      socket.off("systemSetting")
      socket.off("favicon")
      socket.off("logo")
    }

    // eslint-disable-next-line
  }, [])

  const handleStore = (data) => {
    const paramData = {
      storeId: data.storeIdFormItem,
      name: data.storeNameFormItem || "",
      picUrl: storeImageFile.file,
      remarks: data.storeRemarksFormItem || "",
    }

    storeUpdateMutation.mutate(paramData)
  }

  const handleSystemColor = (data) => {
    const paramData = {
      value: data.systemColorFormItem,
    }

    if (publicSettings?.PRIMARY_COLOR !== data.systemColorFormItem) {
      systemColorUpdateMutation.mutate(paramData)
    }
  }

  const handleSystemTitle = (data) => {
    const paramData = {
      value: data.systemTitleFormItem,
    }

    if (publicSettings?.TITLE !== data.systemTitleFormItem) {
      systemTitleUpdateMutation.mutate(paramData)
    }
  }

  const handleRichmenuUpdate = (type, field, data) => {
    const paramData = {
      storeId: selectedStore,
      type: type,
      picUrl: field.file ? field.file : undefined,
      link1: data.linkA,
      link2: data.linkB,
      link3: data.linkC,
    }

    richmenuUpdateMutation.mutate(paramData)
  }

  const handleRichmenuDelete = (type) => {
    const paramData = {
      storeId: selectedStore,
      type: type,
    }

    Modal.confirm({
      title: "確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "リッチメニューを削除してもよろしいでしょうか？",
      okText: "削除",
      okType: "danger",
      cancelText: "閉じる",
      centered: true,
      onOk() {
        richmenuDeleteMutation.mutate(paramData)
      },
    })
  }

  const showStoreModal = (isNew, store = undefined) => {
    setStoreStatus({
      isNew: isNew,
      storeId: store ? store.storeId : undefined,
    })

    if (!isNew) {
      storeForm.setFieldsValue({
        storeIdFormItem: store?.storeId || "",
        storeNameFormItem: store?.name || "",
        storeRemarksFormItem: store?.remarks || "",
      })
      setStoreImageFile({ preview: `${API.UPLOADS_URL}${store.picUrl}` })
    }

    setIsStoreModalVisible(true)
  }

  const hideStoreModal = () => {
    setStoreStatus({
      isNew: false,
      storeId: undefined,
    })
    setStoreImageFile(undefined)
    setIsStoreModalVisible(false)
  }

  const showRichmenuModal = (store) => {
    setSelectedStore(store.storeId || undefined)
    setIsRichmenuModalVisible(true)
  }

  const hideRichmenuModal = () => {
    setSelectedStore(undefined)
    setIsRichmenuModalVisible(false)
  }

  const handleAction = (event) => {
    const eventName = event?.currentTarget?.getAttribute("data-action-name")
    const eventValue = event?.currentTarget?.getAttribute("data-action-value")

    if (eventName && eventValue) {
      if (eventName === "storeUpdate") {
        const foundStore = stores.find(
          (store) => store.storeId === Number(eventValue)
        )

        if (foundStore) {
          showStoreModal(false, foundStore)
        }
      } else if (eventName === "richmenu") {
        const foundStore = stores.find(
          (store) => store.storeId === Number(eventValue)
        )

        if (foundStore) {
          showRichmenuModal(foundStore)
        }
      }
    }
  }

  const storeColumns = [
    {
      title: "ID",
      dataIndex: "storeId",
      align: "center",
      width: 50,
    },
    {
      title: "店舗画像",
      dataIndex: "picUrl",
      align: "center",
      width: 80,
      render: (picUrl) =>
        picUrl ? (
          <Image
            src={`${API.UPLOADS_URL}${picUrl}`}
            preview={{
              mask: <EyeOutlined />,
              src: `${API.UPLOADS_URL}${picUrl}`,
              maskClassName: "rounded-full",
            }}
            className="w-full rounded object-contain"
            style={{
              maxHeight: "70px",
            }}
            fallback="/no-image.png"
          />
        ) : (
          "ー"
        ),
    },
    {
      title: "店舗名",
      dataIndex: "name",
      align: "center",
      width: 200,
    },
    {
      title: "LINEチャンネル名",
      dataIndex: "botLineName",
      align: "center",
      width: 200,
      render: (store) => (
        <>
          {store.botLineName ? (
            store.botLineMode === "bot" ? (
              <>
                <RobotOutlined
                  className="m-1"
                  style={{ color: publicSettings?.PRIMARY_COLOR }}
                />
                {store.botLineName}
              </>
            ) : (
              <>
                <MessageOutlined
                  className="m-1"
                  style={{ color: publicSettings?.PRIMARY_COLOR }}
                />
                {store.botLineName}
              </>
            )
          ) : (
            "ー"
          )}
        </>
      ),
    },
    {
      title: "備考",
      dataIndex: "remarks",
      align: "center",
      width: 200,
    },
    {
      title: "登録日",
      dataIndex: "createdAt",
      align: "center",
      width: 150,
      render: (updatedAt) => (
        <>
          {moment(updatedAt).format("YYYY/M/D")}
          <br />
          {moment(updatedAt).format("HH:mm")}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      align: "center",
      width: 100,
      render: (store) => (
        <>
          <Tooltip title="リッチメニュー設定" placement="top">
            <Button
              size="large"
              className="m-1"
              icon={<ClusterOutlined />}
              data-action-name="richmenu"
              data-action-value={store.storeId}
              onClick={handleAction}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="変更" placement="top">
            <Button
              size="large"
              className="m-1"
              icon={<EditOutlined />}
              data-action-name="storeUpdate"
              data-action-value={store.storeId}
              onClick={handleAction}
            />
          </Tooltip>
        </>
      ),
    },
  ]

  return (
    <>
      <Card title="設定" bordered={false}>
        <Row gutter={[16, 16]}>
          {auth && auth.auth && auth.auth === COMMONS.AUTH_MASTER ? (
            <Col xs={24}>
              <Card
                title="システム管理"
                bordered={true}
                type="inner"
                headStyle={{
                  color: "#FFF",
                  backgroundColor: publicSettings?.PRIMARY_COLOR,
                }}
                style={{
                  borderColor: publicSettings?.PRIMARY_COLOR,
                }}
              >
                <Row gutter={[8, 8]}>
                  <Col xs={24} md={12} className="text-center">
                    <Row gutter={[8, 8]}>
                      <Col xs={24} md={12} className="text-center">
                        <Card title="ロゴ設定">
                          <Upload
                            accept=".jpg, .jpeg, .png, .svg"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={() => {
                              return false
                            }}
                            onChange={async (param) => {
                              logoUploadMutation.mutate(param.file)
                            }}
                          >
                            {logo ? (
                              <img
                                src={`${API.UPLOADS_URL}${logo}`}
                                alt="ロゴ"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            ) : (
                              "アップロード"
                            )}
                          </Upload>
                        </Card>
                      </Col>
                      <Col xs={24} md={12} className="text-center">
                        <Card title="ファビコン設定">
                          <Upload
                            accept=".ico"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={() => {
                              return false
                            }}
                            onChange={async (param) => {
                              faviconUploadMutation.mutate(param.file)
                            }}
                          >
                            {favicon ? (
                              <img
                                src={`${API.UPLOADS_URL}${favicon}`}
                                alt="ファビコン "
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            ) : (
                              "アップロード"
                            )}
                          </Upload>
                        </Card>
                      </Col>
                      <Col xs={24} className="text-center">
                        <Card title="タイトル設定">
                          <Form
                            form={systemTitleForm}
                            name="systemTitleForm"
                            onFinish={handleSystemTitle}
                            layout="vertical"
                            preserve={false}
                            size="large"
                            initialValues={{
                              systemTitleFormItem: publicSettings?.TITLE || "",
                            }}
                          >
                            <Row justify="center">
                              <Col>
                                <Form.Item
                                  name="systemTitleFormItem"
                                  rules={[
                                    {
                                      required: true,
                                      message: "必須です",
                                    },
                                    {
                                      whitespace: true,
                                      message: "必須です",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="例：面接システム"
                                    onPressEnter={(e) => e.preventDefault()}
                                    allowClear
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row justify="center">
                              <Col>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  loading={systemTitleUpdateMutation.isLoading}
                                >
                                  タイトルを保存
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12} className="text-center">
                    <Card title="色設定" className="h-full">
                      <Row gutter={[4, 4]} justify="center">
                        {COMMONS.THEME_COLORS.map((color) => (
                          <Col key={color}>
                            <div
                              className="w-6 h-6 rounded-full cursor-pointer"
                              style={{
                                backgroundColor: color,
                                border:
                                  publicSettings?.PRIMARY_COLOR === color
                                    ? "2px solid white"
                                    : "none",
                                boxShadow:
                                  publicSettings?.PRIMARY_COLOR === color
                                    ? "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                                    : "none",
                              }}
                              onClick={() =>
                                publicSettings?.PRIMARY_COLOR === color
                                  ? ""
                                  : handleSystemColor({
                                      systemColorFormItem: color,
                                    })
                              }
                            ></div>
                          </Col>
                        ))}
                      </Row>
                      <Divider />
                      <Row justify="center">
                        <Form
                          form={systemColorForm}
                          name="systemColorForm"
                          onFinish={handleSystemColor}
                          layout="vertical"
                          preserve={false}
                          size="large"
                          initialValues={{
                            systemColorFormItem: COMMONS.THEME_COLORS.find(
                              (c) => c === publicSettings?.PRIMARY_COLOR
                            )
                              ? ""
                              : publicSettings?.PRIMARY_COLOR,
                          }}
                        >
                          <Row justify="center">
                            <Col>
                              <Form.Item
                                name="systemColorFormItem"
                                rules={[
                                  {
                                    required: true,
                                    message: "必須です",
                                  },
                                  {
                                    whitespace: true,
                                    message: "必須です",
                                  },
                                ]}
                              >
                                <Input
                                  addonBefore="カスタム色"
                                  placeholder="例：#060606"
                                  onPressEnter={(e) => e.preventDefault()}
                                  allowClear
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row justify="center">
                            <Col>
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={systemColorUpdateMutation.isLoading}
                              >
                                カスタム色を設定する
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          ) : (
            ""
          )}
          <Col xs={24}>
            <Card
              title="店舗管理"
              bordered={true}
              type="inner"
              headStyle={{
                color: "#FFF",
                backgroundColor: publicSettings?.PRIMARY_COLOR,
              }}
              style={{
                borderColor: publicSettings?.PRIMARY_COLOR,
              }}
              bodyStyle={{
                padding: 0,
              }}
            >
              <Card bordered={false}>
                <Table
                  bordered
                  size="large"
                  columns={storeColumns}
                  scroll={{ x: "max-content" }}
                  dataSource={
                    stores
                      ? stores.map((store) => {
                          return {
                            key: store.storeId,
                            storeId: store.storeId,
                            picUrl: store.picUrl,
                            name: store.name,
                            botLineName: store,
                            remarks: store.remarks || "ー",
                            createdAt: store.createdAt,
                            action: store,
                          }
                        })
                      : []
                  }
                />
              </Card>
            </Card>
          </Col>
        </Row>
      </Card>
      <Modal
        title="リッチメニュー設定"
        visible={isRichmenuModalVisible}
        onCancel={hideRichmenuModal}
        footer={null}
        getContainer={false}
        destroyOnClose
        centered
      >
        <Row>
          <Alert
            message=""
            description={
              <Col span={24}>
                <Image
                  src="/template.png"
                  alt="テンプレート"
                  preview={{
                    mask: <EyeOutlined />,
                    src: "/template.png",
                    maskClassName: "rounded",
                  }}
                  className="w-full rounded object-contain"
                  fallback="/no-image.png"
                />
                <p className="my-4">
                  リッチメニューの画像は以下の要件を満たす必要があります
                </p>
                <ol className="custom-ol list-none list-outside mb-2">
                  <li>画像フォーマット：JPEGまたはPNG</li>
                  <li>画像の幅サイズ（ピクセル）：1200px</li>
                  <li>画像の高さサイズ（ピクセル）：810px</li>
                  <li>最大ファイルサイズ：1MB</li>
                </ol>
              </Col>
            }
            type="warning"
          />
          <Divider>登録前のリッチメニュー</Divider>
          <Col span={24} className="border border-custom-gray p-4">
            <Form
              name="defaultRichmenuForm"
              form={defaultRichmenuForm}
              onFinish={(data) => {
                handleRichmenuUpdate(
                  "defaultRM",
                  defaultRichmenuImageFile,
                  data
                )
              }}
              layout="vertical"
              requiredMark={false}
              preserve={false}
              initialValues={{
                richmenuImage: undefined,
              }}
              scrollToFirstError
            >
              <Row gutter={[0, 0]}>
                <Col span={24} className="mb-4">
                  <Row justify="end">
                    <Col>
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          handleRichmenuDelete("defaultRM")
                        }}
                        loading={richmenuDeleteMutation.isLoading}
                        disabled={richmenus.defaultRM ? false : true}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="richmenuImage"
                    className="whitespace-pre-wrap text-center"
                    help={`※このリッチメニューは、まだ会員ではないユーザーに\n表示されます`}
                    valuePropName="file"
                  >
                    <CustomUpload
                      accept=".jpg, .jpeg, .png"
                      listType="picture"
                      maxCount={1}
                      showUploadList={false}
                      beforeUpload={() => {
                        return false
                      }}
                      onChange={async (param) => {
                        checkRichMenuImageSize(
                          param,
                          setDefaultRichmenuImageFile
                        )
                      }}
                    >
                      {defaultRichmenuImageFile?.preview ? (
                        <img
                          src={defaultRichmenuImageFile.preview}
                          alt="登録前のリッチメニュー"
                          style={{
                            width: "100%",
                            maxHeight: "405px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <Row
                          justify="center"
                          align="middle"
                          style={{
                            height: "405px",
                            backgroundColor: "#fafafa",
                            border: "1px dashed #d9d9d9",
                          }}
                          className="rounded cursor-pointer"
                        >
                          <Col>
                            <Button
                              type="dashed"
                              icon={
                                <CameraTwoTone
                                  twoToneColor={publicSettings?.PRIMARY_COLOR}
                                />
                              }
                            >
                              アップロード
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </CustomUpload>
                  </Form.Item>
                  <Form.Item
                    name="linkA"
                    className="mt-4"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "必須です",
                      },
                      {
                        type: "url",
                        message: "入力のURLが有効なURLではありません",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="A部分のリンク先："
                      placeholder={`例：${API.API_URL}`}
                      onPressEnter={(e) => e.preventDefault()}
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    name="linkB"
                    className="mt-4"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "必須です",
                      },
                      {
                        type: "url",
                        message: "入力のURLが有効なURLではありません",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="B部分のリンク先："
                      placeholder={`例：${API.API_URL}`}
                      onPressEnter={(e) => e.preventDefault()}
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    name="linkC"
                    className="mt-4"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "必須です",
                      },
                      {
                        type: "url",
                        message: "入力のURLが有効なURLではありません",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="C部分のリンク先："
                      placeholder={`例：${API.API_URL}`}
                      onPressEnter={(e) => e.preventDefault()}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Divider />
                <Col span={24}>
                  <Row justify="center">
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={richmenuUpdateMutation.isLoading}
                        disabled={
                          defaultRichmenuImageFile?.file ||
                          defaultRichmenuForm.getFieldValue("richmenuImage")
                            ? false
                            : true
                        }
                      >
                        設定する
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
          <Divider>登録後のリッチメニュー</Divider>
          <Col span={24} className="border border-custom-gray p-4">
            <Form
              name="memberRichmenuForm"
              form={memberRichmenuForm}
              onFinish={(data) => {
                handleRichmenuUpdate("memberRM", memberRichmenuImageFile, data)
              }}
              layout="vertical"
              requiredMark={false}
              preserve={false}
              initialValues={{
                richmenuImage: undefined,
              }}
              scrollToFirstError
            >
              <Row gutter={[0, 0]}>
                <Col span={24} className="mb-4">
                  <Row justify="end">
                    <Col>
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          handleRichmenuDelete("memberRM")
                        }}
                        loading={richmenuDeleteMutation.isLoading}
                        disabled={richmenus.memberRM ? false : true}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="richmenuImage"
                    className="whitespace-pre-wrap text-center"
                    help={`※このリッチメニューは、会員のユーザーに\n表示されます`}
                    valuePropName="file"
                  >
                    <CustomUpload
                      accept=".jpg, .jpeg, .png"
                      listType="picture"
                      maxCount={1}
                      showUploadList={false}
                      beforeUpload={() => {
                        return false
                      }}
                      onChange={async (param) => {
                        checkRichMenuImageSize(
                          param,
                          setMemberRichmenuImageFile
                        )
                      }}
                    >
                      {memberRichmenuImageFile?.preview ? (
                        <img
                          src={memberRichmenuImageFile.preview}
                          alt="登録前のリッチメニュー"
                          style={{
                            width: "100%",
                            maxHeight: "405px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <Row
                          justify="center"
                          align="middle"
                          style={{
                            height: "405px",
                            backgroundColor: "#fafafa",
                            border: "1px dashed #d9d9d9",
                          }}
                          className="rounded cursor-pointer"
                        >
                          <Col>
                            <Button
                              type="dashed"
                              icon={
                                <CameraTwoTone
                                  twoToneColor={publicSettings?.PRIMARY_COLOR}
                                />
                              }
                            >
                              アップロード
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </CustomUpload>
                  </Form.Item>
                  <Form.Item
                    name="linkA"
                    className="mt-4"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "必須です",
                      },
                      {
                        type: "url",
                        message: "入力のURLが有効なURLではありません",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="A部分のリンク先："
                      placeholder={`例：${API.API_URL}`}
                      onPressEnter={(e) => e.preventDefault()}
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    name="linkB"
                    className="mt-4"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "必須です",
                      },
                      {
                        type: "url",
                        message: "入力のURLが有効なURLではありません",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="B部分のリンク先："
                      placeholder={`例：${API.API_URL}`}
                      onPressEnter={(e) => e.preventDefault()}
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    name="linkC"
                    className="mt-4"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "必須です",
                      },
                      {
                        type: "url",
                        message: "入力のURLが有効なURLではありません",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="C部分のリンク先："
                      placeholder={`例：${API.API_URL}`}
                      onPressEnter={(e) => e.preventDefault()}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Divider />
                <Col span={24}>
                  <Row justify="center">
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={richmenuUpdateMutation.isLoading}
                        disabled={
                          memberRichmenuImageFile?.file ||
                          memberRichmenuForm.getFieldValue("richmenuImage")
                            ? false
                            : true
                        }
                      >
                        設定する
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="店舗管理"
        visible={isStoreModalVisible}
        onCancel={hideStoreModal}
        footer={null}
        getContainer={false}
        destroyOnClose
        centered
      >
        <Row>
          <Col span={24}>
            <Form
              name="storeForm"
              form={storeForm}
              onFinish={handleStore}
              layout="vertical"
              requiredMark={true}
              preserve={false}
              size="large"
              initialValues={{
                storeIdFormItem: undefined,
                storeNameFormItem: "",
                storeRemarksFormItem: "",
              }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item name="storeIdFormItem" hidden>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="storeImageFormItem" label="店舗画像">
                    <Upload
                      accept=".jpg, .jpeg, .png, .svg"
                      listType="picture-card"
                      showUploadList={false}
                      beforeUpload={() => {
                        return false
                      }}
                      onChange={async (param) => {
                        setStoreImageFile({
                          file: param.file,
                          preview: await COMMONS.GET_BASE_64(param.file),
                        })
                      }}
                    >
                      {storeImageFile ? (
                        <img
                          src={storeImageFile.preview}
                          alt="店舗画像"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        "アップロード"
                      )}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="storeNameFormItem"
                    label="店舗名"
                    rules={[
                      {
                        required: true,
                        message: "店舗名は必須です",
                      },
                      {
                        whitespace: true,
                        message: "店舗名は必須です",
                      },
                      {
                        type: "string",
                        max: 10,
                        message: "店舗名は10文字を超えることはできません",
                      },
                    ]}
                  >
                    <Input
                      placeholder="例：店舗１"
                      onPressEnter={(e) => e.preventDefault()}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="storeRemarksFormItem" label="備考">
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 5, maxRows: 15 }}
                      maxLength={250}
                      placeholder="例：本店"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={storeUpdateMutation.isLoading}
                    >
                      {storeStatus.isNew ? "作成" : "変更"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default Settings
