import liff from "@line/liff"
import { Layout, message, Spin } from "antd"
import * as API from "common/api"
import * as COMMONS from "common/common"
import { socket } from "common/socket"
import { changeAntdTheme } from "mini-dynamic-antd-theme"
import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet-async"
import {
  useIsFetching,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query"
import { useNavigate, useParams } from "react-router-dom"

const ClientMain = (props) => {
  const { children } = props
  const { liffId, storeId } = useParams()
  const { Content } = Layout

  const navigate = useNavigate()
  const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()
  const queryClient = useQueryClient()
  const personalInfoRef = useRef()
  const [personalInfo, setPersonalInfo] = useState({})
  const [storeInfo, setStoreInfo] = useState(undefined)
  const [accessToken, setAccessToken] = useState(undefined)
  const [logo, setLogo] = useState(undefined)
  const [favicon, setFavicon] = useState(undefined)
  const [publicSettings, setPublicSettings] = useState({
    PRIMARY_COLOR: COMMONS.PRIMARY_COLOR,
    PRIMARY_LIGHT_COLOR: COMMONS.PRIMARY_LIGHT_COLOR,
  })

  useQuery(
    [API.QUERY_KEY_CLIENT_PERSONAL_INFO, accessToken],
    () => API.CLIENT_GET_PERSONAL_INFO(accessToken),
    {
      enabled: !!accessToken,
      onSuccess: (response) => {
        if (isMountedRef.current) {
          setPersonalInfo(response?.data || {})
        }
      },
      onError: (error) => {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      },
    }
  )
  useQuery(API.QUERY_KEY_LOGO, () => API.GET_LOGO(), {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data) {
          setLogo(response.data)
        }
      }
    },
  })

  useQuery(API.QUERY_KEY_FAVICON, () => API.GET_FAVICON(), {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data) {
          setFavicon(response.data)
        }
      }
    },
  })

  useQuery(API.QUERY_KEY_PUBLIC_SETTINGS, () => API.GET_PUBLIC_SETTINGS(), {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data) {
          handlePublicSettings(response.data)
        }
      }
    },
  })

  const checkStoreMutation = useMutation(API.CLIENT_CHECK_STORE, {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        setStoreInfo(response?.data)
      }
    },
    onError: (error) => {
      message.error(COMMONS.ERROR_SYSTEM_MSG)
    },
  })

  const handlePublicSettings = (data) => {
    changeAntdTheme(
      data[API.SETTINGS_KEY_SYSTEM_COLOR]
        ? data[API.SETTINGS_KEY_SYSTEM_COLOR].settingValueString
        : COMMONS.PRIMARY_COLOR
    )

    setPublicSettings({
      PRIMARY_COLOR: data[API.SETTINGS_KEY_SYSTEM_COLOR]
        ? data[API.SETTINGS_KEY_SYSTEM_COLOR].settingValueString
        : COMMONS.PRIMARY_COLOR,
      PRIMARY_LIGHT_COLOR: data[API.SETTINGS_KEY_SYSTEM_COLOR]
        ? COMMONS.COLOR_ADJUST(
            data[API.SETTINGS_KEY_SYSTEM_COLOR].settingValueString,
            200
          )
        : COMMONS.PRIMARY_LIGHT_COLOR,
      TITLE: data[API.SETTINGS_KEY_SYSTEM_TITLE]
        ? data[API.SETTINGS_KEY_SYSTEM_TITLE].settingValueString
        : process.env.REACT_APP_SYSTEM_NAME,
    })
  }
  useEffect(() => {
    personalInfoRef.current = personalInfo
  }, [personalInfo])

  useEffect(() => {
    try {
      setAccessToken(liff.getAccessToken())
    } catch (err) {
      navigate(`${COMMONS.CLIENT_LOGIN_ROUTE}/${liffId}`)
    }
    // eslint-disable-next-line
  }, [liffId])

  useEffect(() => {
    if (accessToken) {
      const paramData = {
        liffId: liffId,
        accessToken: accessToken,
      }

      checkStoreMutation.mutate(paramData)
    }

    // eslint-disable-next-line
  }, [liffId, storeId])

  useEffect(() => {
    socket.on("systemSetting", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_PUBLIC_SETTINGS)
      }
    })

    socket.on("favicon", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_FAVICON)
      }
    })

    socket.on("logo", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_LOGO)
      }
    })
    socket.on("member", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response?.memberId === personalInfoRef.current?.memberId) {
          queryClient.invalidateQueries({
            queryKey: [API.QUERY_KEY_CLIENT_PERSONAL_INFO],
          })
        }
      }
    })

    return () => {
      socket.off("systemSetting")
      socket.off("favicon")
      socket.off("logo")
      socket.off("member")
    }

    // eslint-disable-next-line
  }, [])

  const childrenWithProps = React.Children.map(children, (element) =>
    React.cloneElement(element, {
      logo: logo,
      publicSettings: publicSettings,
      accessToken: accessToken,
      storeInfo: storeInfo,
      personalInfo: personalInfo,
    })
  )

  return (
    <>
      <Helmet>
        {favicon ? <link rel="icon" href={API.UPLOADS_URL + favicon} /> : ""}
        {publicSettings.TITLE ? (
          <title>{`${storeInfo?.name || ""}`}</title>
        ) : (
          ""
        )}
      </Helmet>
      <div className="flex flex-col w-full min-h-full bg-custom-yellow">
        <Spin
          spinning={useIsFetching() > 0 ? true : false}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1001,
          }}
        >
          <Layout className="min-h-screen bg-custom-yellow">
            <Content key={JSON.stringify(publicSettings)}>
              <div className="w-full lg:w-4/5 xl:w-3/5 m-auto">
                {childrenWithProps}
              </div>
            </Content>
          </Layout>
        </Spin>
      </div>
    </>
  )
}

export default ClientMain
