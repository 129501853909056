import React, { useEffect, useRef, useState } from "react"
import { Tag, Input } from "antd"
import Resizer from "react-image-file-resizer"

/* ROUTES */
export const ADMIN_LOGIN_ROUTE = "/login"
export const ADMIN_STORES_ROUTE = "/stores"
export const ADMIN_MEMBERS_ROUTE = "/members"
export const ADMIN_AUDIENCES_ROUTE = "/audiences"
export const ADMIN_SETTINGS_ROUTE = "/settings"

export const CLIENT_LOGIN_ROUTE = "/liff/login"
export const CLIENT_ECLOGIN_ROUTE = "/liff/eclogin"
export const CLIENT_EC_STATUS_CHECK_ROUTE = "/liff/ecstatus"
export const CLIENT_REGISTER_ROUTE = "/liff/register"
export const CLIENT_PROFILE_UPDATE_ROUTE = "/liff/update"
export const CLIENT_MEMBERSHIP_ROUTE = "/liff/membership"

export const LINE_ACCESS_ROUTE = "/line-access"
export const LINE_FRIEND_ROUTE = "/line-friend"
export const NOT_FOUND_ROUTE = "/404"
export const PERMISSION_ERROR_ROUTE = "/401"

/* ALERT MESSAGES */
export const ERROR_ADMIN_LOGIN_MISMATCH_MSG =
  "ユーザー名またはパスワードが間違っています"
export const ERROR_EC_EMAIL_USER_EXIST =
  "メールユーザーはEC CUBEに既に存在します"
export const ERROR_PASSWORD_MISMATCH_MSG = "パスワードが間違っています"
export const ERROR_SYSTEM_MSG = "システムエラー"
export const ERROR_SESSION_MSG = "もう一度ログインしてください"
export const ERROR_401_MSG = "許可が足りないです。"
export const ERROR_404_MSG = "アクセスしたページは存在しません"
export const ERROR_LINE_403_MSG = "LINEアプリからアクセスしてください"
export const ERROR_LINE_FRIEND_MSG = "LINEで友だち追加してアクセスしてください"
export const ERROR_NO_STORE = "店舗が存在しません"
export const ERROR_TELEPHONE_UNIQUE_MSG = "この電話番号はすでに登録されています"
export const WARN_POSTAL_CODE_WRONG_MSG = "郵便番号を確認してください"
export const WARN_USER_EXIST_MSG = "ユーザーはすでに存在しています"
export const WARN_AUDIENCE_NAME_EXIST_MSG =
  "このオーディエンス名はすでに存在しています"
export const WARN_MEMBER_CODE_NOT_EXIST_MSG =
  "この会員コードを持つユーザーはありません"
export const WARN_IMAGE_TOO_BIG = "画像サイズが大きすぎます"
export const WARN_IMAGE_SIZE_DIFFERENT = "画像サイズが違います"
export const INFO_CAMERA_PERMISSION_MSG = "カメラの許可をお願いします"
export const SUCCESS_LOGIN_MSG = "ログインしました。"
export const SUCCESS_LOGOUT_MSG = "ログアウトしました。"
export const SUCCESS_CREATE_MSG = "作成成功"
export const SUCCESS_REGISTER_MSG = "登録成功"
export const SUCCESS_UPDATE_MSG = "変更成功"
export const SUCCESS_SAVE_MSG = "保存成功"
export const SUCCESS_DELETE_MSG = "削除成功"
export const SUCCESS_AUDIENCE_SYNC_MSG = "オーディエンス同期成功"
export const ECCUBE_INTEGRATION_MSG = "LINE連携ログイン完了 "

/* RESPONSE TYPE */
export const RESPONSE_PERMISSION_ERROR = 401
export const RESPONSE_SESSION_ERROR = 403
export const RESPONSE_CONFLICT_ERROR = 409
export const RESPONSE_SYSTEM_ERROR = 500

/* THEME COLOR */
export const PRIMARY_COLOR = "#99CA29"
export const PRIMARY_LIGHT_COLOR = "#f6fcd4"
export const GRAY_COLOR = "#9CA3AF"

export const THEME_COLORS = [
  "#99CA29",
  "#EA638C",
  "#DB5461",
  "#BB3E03",
  "#E85D75",
  "#C76D7E",
  "#AB92BF",
  "#8D6B94",
  "#4EA5D9",
  "#134074",
  "#005F73",
  "#8DA9C4",
  "#72A1E5",
  "#0A9396",
  "#84A07C",
  "#3C787E",
  "#77878B",
  "#9a8c98",
  "#9F8082",
  "#b5838d",
  "#6d6875",
  "#30343F",
  "#2E382E",
]

/* AUTH TYPE */
export const AUTH_MASTER = "master"

export const CHECK_CUSTOMER_STATUS = (data) => {
  console.log(data)
  if (data) {
    return data?.customerStatus === 2
  }

  return false
}

export const CHECK_IS_COMPLETE = (data) => {
  //Check all fields and if it's complete then return true or not then false
  if (data) {
    if (
      data?.lastName &&
      data?.firstName &&
      data?.lastNameKana &&
      data?.firstNameKana &&
      data?.memberSince &&
      data?.postalCode &&
      data?.address &&
      data?.phoneNumber &&
      data?.city &&
      data?.email &&
      // data?.password &&
      data?.prefecture
    ) {
      return true
    } else {
      return false
    }
  }

  return false
}

export const GENDER = [
  { label: "男性", value: "male" },
  { label: "女性", value: "female" },
]

export const GET_GENDER_BY_VALUE = (value) => {
  switch (value) {
    case "male":
      return "男性"
    case "female":
      return "女性"
    default:
      return "ー"
  }
}

export const PET_GENDER = [
  { label: "オス", value: "male" },
  { label: "メス", value: "female" },
]

export const GET_PET_GENDER_BY_VALUE = (value) => {
  switch (value) {
    case "male":
      return "オス"
    case "female":
      return "メス"
    default:
      return "ー"
  }
}

export const PET_SIZE = [
  { label: "小型犬", value: "small" },
  { label: "中型犬", value: "medium" },
  { label: "大型犬", value: "large" },
]

export const GET_PET_SIZE_BY_VALUE = (value) => {
  switch (value) {
    case "small":
      return "小型犬"
    case "medium":
      return "中型犬"
    case "large":
      return "大型犬"
    default:
      return "ー"
  }
}

export const GET_AUDIENCE_STATUS = (value) => {
  switch (value) {
    case "IN_PROGRESS":
      return (
        <Tag color="#fcbf49" className="p-2">
          準備中
        </Tag>
      )
    case "READY":
      return (
        <Tag color="#06d6a0" className="p-2">
          成功
        </Tag>
      )
    case "FAILED":
      return (
        <Tag color="#ff6b6b" className="p-2">
          エラー
        </Tag>
      )
    case "EXPIRED":
      return (
        <Tag color="#f77f00" className="p-2">
          期限切れ
        </Tag>
      )
    case "INACTIVE":
      return (
        <Tag color="#38a3a5" className="p-2">
          無効
        </Tag>
      )
    case "ACTIVATING":
      return (
        <Tag color="#52b788" className="p-2">
          有効化中
        </Tag>
      )
    default:
      break
  }
}

const GET_SESSION_STORAGE_OR_DEFAULT = (key, defaultValue) => {
  const stored = sessionStorage.getItem(key)
  if (!stored) {
    return defaultValue
  }
  return JSON.parse(stored)
}

export const USE_SESSION_STORAGE = (key, defaultValue) => {
  const [value, setValue] = useState(
    GET_SESSION_STORAGE_OR_DEFAULT(key, defaultValue),
  )

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

const GET_LOCAL_STORAGE_OR_DEFAULT = (key, defaultValue) => {
  const stored = localStorage.getItem(key)
  if (!stored) {
    return defaultValue
  }
  return JSON.parse(stored)
}

export const USE_LOCAL_STORAGE = (key, defaultValue) => {
  const [value, setValue] = useState(
    GET_LOCAL_STORAGE_OR_DEFAULT(key, defaultValue),
  )

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

/* CHECK MOUNTED REACT */
export function USE_IS_MOUNTED_REF() {
  const isMountedRef = useRef(null)

  useEffect(() => {
    isMountedRef.current = true
    return () => (isMountedRef.current = false)
  })

  return isMountedRef
}

export function GET_BASE_64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export function COLOR_ADJUST(color, amount) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2),
      )
  )
}

export function POSTAL_CODE_INSERT_CHARACTER(str, index, value) {
  return str.substr(0, index) + value + str.substr(index)
}

export class NUMERIC_INPUT extends React.Component {
  onChange = (e) => {
    const { value } = e.target
    const reg = /^-?\d*(\.\d*)?$/

    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      this.props.onChange(value)
    }
  }

  render() {
    return (
      <Input
        {...this.props}
        onChange={this.onChange}
        onPressEnter={(e) => e.preventDefault()}
      />
    )
  }
}

export const RESIZE_FILE = (file, type) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri)
      },
      type,
    )
  })

/**
 * Japan's region and subregional names
 *
 * Note: the addition of Tokai as a subregion of Chubu does not include all
 * known Tokai prefectures; only Aichi, Gifu, Mie.
 */
export const REGION_HOKKAIDO = "HOKKAIDO"
export const REGION_TOHOKU = "TOHOKU"
export const REGION_KANTO = "KANTO"
export const REGION_CHUBU = "CHUBU"
export const REGION_TOKAI = "TOKAI"
export const REGION_KINKI = "KINKI"
export const REGION_CHUKOKU = "CHUKOKU"
export const REGION_SHIKOKU = "SHIKOKU"
export const REGION_KYUSHU_OKINAWA = "KYUSHU_OKINAWA"

/**
 * List of prefectures
 */
export const PREFECTURES = [
  { value: 1, label: "北海道" },
  { value: 2, label: "青森県" },
  { value: 3, label: "岩手県" },
  { value: 4, label: "宮城県" },
  { value: 5, label: "秋田県" },
  { value: 6, label: "山形県" },
  { value: 7, label: "福島県" },
  { value: 8, label: "茨城県" },
  { value: 9, label: "栃木県" },
  { value: 10, label: "群馬県" },
  { value: 11, label: "埼玉県" },
  { value: 12, label: "千葉県" },
  { value: 13, label: "東京都" },
  { value: 14, label: "神奈川県" },
  { value: 15, label: "新潟県" },
  { value: 16, label: "富山県" },
  { value: 17, label: "石川県" },
  { value: 18, label: "福井県" },
  { value: 19, label: "山梨県" },
  { value: 20, label: "長野県" },
  { value: 21, label: "岐阜県" },
  { value: 22, label: "静岡県" },
  { value: 23, label: "愛知県" },
  { value: 24, label: "三重県" },
  { value: 25, label: "滋賀県" },
  { value: 26, label: "京都府" },
  { value: 27, label: "大阪府" },
  { value: 28, label: "兵庫県" },
  { value: 29, label: "奈良県" },
  { value: 30, label: "和歌山県" },
  { value: 31, label: "鳥取県" },
  { value: 32, label: "島根県" },
  { value: 33, label: "岡山県" },
  { value: 34, label: "広島県" },
  { value: 35, label: "山口県" },
  { value: 36, label: "徳島県" },
  { value: 37, label: "香川県" },
  { value: 38, label: "愛媛県" },
  { value: 39, label: "高知県" },
  { value: 40, label: "福岡県" },
  { value: 41, label: "佐賀県" },
  { value: 42, label: "長崎県" },
  { value: 43, label: "熊本県" },
  { value: 44, label: "大分県" },
  { value: 45, label: "宮崎県" },
  { value: 46, label: "鹿児島県" },
  { value: 47, label: "沖縄県" },
]

export const SELECT_REGION_PLACEHOLDER_TEXT = "地域を選択"

/**
 * List of prefectures per regional group
 */
export const PREFECTURAL_REGIONS = [
  { label: "東海3県(愛知/岐阜/三重)", value: REGION_TOKAI },
  {
    label: "その他中部(新潟/富山/石川/福井/山梨/⻑野/静岡)",
    value: REGION_CHUBU,
  },
  { label: "近畿(京都/大阪/滋賀/兵庫/奈良/和歌山)", value: REGION_KINKI },
  { label: "関東(東京/神奈川/埼玉/千葉/茨城/栃木/群馬)", value: REGION_KANTO },
  { label: "中国(鳥取/島根/岡山/広島/山口)", value: REGION_CHUKOKU },
  { label: "四国(徳島/香川/愛媛/高知)", value: REGION_SHIKOKU },
  {
    label: "九州沖縄(福岡/佐賀/⻑崎/熊本/大分/宮崎/鹿児島/沖縄)",
    value: REGION_KYUSHU_OKINAWA,
  },
  { label: "東北(⻘森/岩手/秋田/宮城/山形/福島)", value: REGION_TOHOKU },
  { label: "北海道", value: REGION_HOKKAIDO },
]

//URL
export const FORGOT_URL = `${process.env.REACT_APP_EC_URL}/forgot`
