import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Sidebar, Topbar } from "./components"
import { Grid, Layout, Spin, message } from "antd"
import { useIsFetching, useQuery, useQueryClient } from "react-query"
import { Helmet } from "react-helmet-async"
import { changeAntdTheme } from "mini-dynamic-antd-theme"
import * as COMMONS from "common/common"
import * as API from "common/api"
import { socket } from "common/socket"

const AdminMain = (props) => {
  const { children } = props
  const navigate = useNavigate()
  const { Header, Sider, Content } = Layout
  const { useBreakpoint } = Grid

  const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()
  const queryClient = useQueryClient()

  const [isCollapsed, setIsCollapsed] = useState(true)

  const [auth, setAuth] = useState({})
  const [stores, setStores] = useState([])
  const [logo, setLogo] = useState(undefined)
  const [favicon, setFavicon] = useState(undefined)
  const [publicSettings, setPublicSettings] = useState({
    PRIMARY_COLOR: COMMONS.PRIMARY_COLOR,
    PRIMARY_LIGHT_COLOR: COMMONS.PRIMARY_LIGHT_COLOR,
  })
  const [currentStore, setCurrentStore] = COMMONS.USE_LOCAL_STORAGE(
    "currentStore",
    {}
  )

  const breakpoint = useBreakpoint()
  const isHamburger = !breakpoint.xl

  const collapse = () => {
    setIsCollapsed(true)
  }

  const expand = () => {
    setIsCollapsed(false)
  }

  const collapseToggle = () => {
    if (isCollapsed) {
      expand()
    } else {
      collapse()
    }
  }

  useQuery(API.QUERY_KEY_LOGO, () => API.GET_LOGO(), {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data) {
          setLogo(response.data)
        }
      }
    },
    onError: (error) => {
      message.error(COMMONS.ERROR_SYSTEM_MSG)
    },
  })

  useQuery(API.QUERY_KEY_FAVICON, () => API.GET_FAVICON(), {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data) {
          setFavicon(response.data)
        }
      }
    },
    onError: (error) => {
      message.error(COMMONS.ERROR_SYSTEM_MSG)
    },
  })

  useQuery(API.QUERY_KEY_PUBLIC_SETTINGS, () => API.GET_PUBLIC_SETTINGS(), {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data) {
          handlePublicSettings(response.data)
        }
      }
    },
    onError: (error) => {
      message.error(COMMONS.ERROR_SYSTEM_MSG)
    },
  })

  useQuery(API.QUERY_KEY_ADMIN_AUTH, API.ADMIN_GET_AUTH, {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data) {
          setAuth(response.data)
        } else {
          setAuth({})
        }
      }
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  useQuery(API.QUERY_KEY_ADMIN_STORES, API.ADMIN_GET_STORES, {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data && response?.data?.length > 0) {
          setStores(response.data)
        } else {
          setStores([])
        }
      }
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const handlePublicSettings = (data) => {
    changeAntdTheme(
      data[API.SETTINGS_KEY_SYSTEM_COLOR]
        ? data[API.SETTINGS_KEY_SYSTEM_COLOR].settingValueString
        : COMMONS.PRIMARY_COLOR
    )

    setPublicSettings({
      PRIMARY_COLOR: data[API.SETTINGS_KEY_SYSTEM_COLOR]
        ? data[API.SETTINGS_KEY_SYSTEM_COLOR].settingValueString
        : COMMONS.PRIMARY_COLOR,
      PRIMARY_LIGHT_COLOR: data[API.SETTINGS_KEY_SYSTEM_COLOR]
        ? COMMONS.COLOR_ADJUST(
            data[API.SETTINGS_KEY_SYSTEM_COLOR].settingValueString,
            200
          )
        : COMMONS.PRIMARY_LIGHT_COLOR,
      TITLE: data[API.SETTINGS_KEY_SYSTEM_TITLE]
        ? data[API.SETTINGS_KEY_SYSTEM_TITLE].settingValueString
        : process.env.REACT_APP_SYSTEM_NAME,
    })
  }

  const childrenWithProps = React.Children.map(children, (element) =>
    React.cloneElement(element, {
      auth: auth,
      stores: stores,
      publicSettings: publicSettings,
      currentStore: currentStore,
      setCurrentStore: setCurrentStore,
    })
  )

  useEffect(() => {
    if (isHamburger) {
      collapse()
    } else {
      expand()
    }
  }, [isHamburger])

  useEffect(() => {
    socket.on("systemSetting", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_PUBLIC_SETTINGS)
      }
    })

    socket.on("favicon", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_FAVICON)
      }
    })

    socket.on("logo", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_LOGO)
      }
    })

    socket.on("store", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_STORES)
      }
    })

    return () => {
      socket.off("systemSetting")
      socket.off("favicon")
      socket.off("logo")
      socket.off("store")
    }

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Helmet>
        {favicon ? <link rel="icon" href={API.UPLOADS_URL + favicon} /> : ""}
        {publicSettings.TITLE ? <title>{publicSettings.TITLE}</title> : ""}
      </Helmet>
      <div className="flex flex-col w-full min-h-full">
        <Spin
          spinning={useIsFetching() > 0 ? true : false}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1001,
          }}
        >
          <Layout className="min-h-full">
            {isHamburger ? (
              isCollapsed ? (
                <div></div>
              ) : (
                <div
                  onClick={collapse}
                  className="absolute top-0 left-0 bg-black bg-opacity-50 w-full h-full z-50"
                ></div>
              )
            ) : isCollapsed ? (
              <div
                style={{
                  flex: "0 0 80px",
                  minWidth: "80px",
                  maxWidth: "80px",
                  overflow: "hidden",
                }}
              ></div>
            ) : (
              <div
                style={{
                  flex: "0 0 200px",
                  minWidth: "200px",
                  maxWidth: "200px",
                  overflow: "hidden",
                }}
              ></div>
            )}
            <Sider
              theme="light"
              collapsible
              collapsed={isCollapsed}
              collapsedWidth={isHamburger ? 0 : 80}
              trigger={null}
              className="fixed top-0 left-0 h-full shadow z-50"
            >
              <Sidebar
                isHamburger={isHamburger}
                isCollapsed={isCollapsed}
                collapseToggle={collapseToggle}
                auth={auth}
                stores={stores}
                logo={logo}
                publicSettings={publicSettings}
                currentStore={currentStore}
              />
            </Sider>
            <Layout className="min-h-screen">
              <Header className="p-0 bg-white shadow">
                <Topbar
                  isHamburger={isHamburger}
                  collapseToggle={collapseToggle}
                  auth={auth}
                  stores={stores}
                  publicSettings={publicSettings}
                  currentStore={currentStore}
                  setCurrentStore={setCurrentStore}
                />
              </Header>
              <Content
                className="m-5 bg-white border-solid border border-gray-200 shadow"
                key={JSON.stringify(publicSettings)}
              >
                {childrenWithProps}
              </Content>
            </Layout>
          </Layout>
        </Spin>
      </div>
    </>
  )
}

export default AdminMain
