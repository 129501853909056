import { ShopOutlined } from "@ant-design/icons"
import { Col, Row } from "antd"
import * as API from "common/api"

const TopBarComponent = ({ publicSettings, storeInfo }) => {
  return (
    <>
      <div className="w-full px-12">
        <Row justify="center" align="center">
          <Col>
            {storeInfo?.picUrl ? (
              <img
                style={{ maxHeight: "150px" }}
                alt="ロゴ"
                src={`${API.UPLOADS_URL}${storeInfo.picUrl}`}
                className="rounded max-w-full"
              />
            ) : (
              <ShopOutlined
                style={{
                  color: publicSettings?.PRIMARY_COLOR,
                  fontSize: "3rem",
                }}
              />
            )}
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <p
              className="font-bold text-center text-2xl mt-4"
              style={{ color: publicSettings?.PRIMARY_COLOR }}
            >
              MEMBER'S CARD
            </p>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default TopBarComponent
