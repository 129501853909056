import * as API from "common/api"
import React from "react"

const EcStatus = (props) => {
  const { logo } = props

  return (
    <>
      <div className="flex h-screen">
        <div className=" mx-auto w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-5 z-10 bg-white bg-opacity-75 animate__animated animate__fadeIn">
          <div className="flex mt-6 p-4 mb-6">
            <img
              src={logo ? API.UPLOADS_URL + logo : "logo.svg"}
              alt="ロゴ"
              className="mx-auto rounded max-w-full"
              style={{ maxHeight: "150px" }}
            />
          </div>
          <div className="mb-6">
            <p className="text-xl text-center mb-1 whitespace-pre font-bold text-center text-custom-orange w-full">
              {`このアカウントは\n 現在「仮登録」の状態です。`}
            </p>
          </div>
          <div className="mb-6">
            <p className="text-[13px] text-center whitespace-normal w-full">
              通販サイトへの会員登録時、登録メールアドレス宛に
              <br />
              ご本人様確認用のメールをお送りしておりますので、
              <br />
              <span className="text-custom-orange">
                メール本文内のURLをクリックすると、
                <br /> 本会員登録が完了
              </span>
              となります。
            </p>
          </div>
          <div className="mb-6">
            <div className="mb-6 p-3 border-2 border-custom-orange w-full h-auto">
              <p className=" text-xs text-center font-bold text-custom-orange mb-2">
                メールが届かない場合
              </p>
              <p className="text-[9px] whitespace-normal">
                迷惑メール等に振り分けられている場合もありますので、ご確認ください。見つからない場合、ドメイン指定受信や拒否リスト設定をしているか、ご入力いただいたメールアドレスが間違っている可能性があります。
                <br />
                再度お手続きいただくか、Lovelybone（052-614-5741※9:00~17:00（月～金）土日・祝休
                み）まで、ご連絡ください。
              </p>
            </div>
            <div className="w-full text-base text-center border-1  ">
              <a
                className="text-custom-orange"
                href="https://lovelybone.testweb-demo.com/contact"
              >
                お問い合わせはこちら
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EcStatus
