import React, { Suspense } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import {
  Minimal as MinimalLayout,
  AdminMain as AdminMainLayout,
  ClientMain as ClientMainLayout,
} from "./layouts"
import { ErrorBoundaryComponent } from "components"
import * as COMMONS from "common/common"

import {
  AdminLogin as AdminLoginView,
  AdminMembers as AdminMembersView,
  AdminStores as AdminStoresView,
  AdminAudiences as AdminAudiencesView,
  AdminSettings as AdminSettingsView,
  ClientLogin as ClientLoginView,
  ClientEcLogin as ClientEcLoginView,
  ClientEcStatus as ClientEcStatusinView,
  ClientRegister as ClientRegisterView,
  ClientProfileUpdate as ClientProfileUpdateView,
  ClientMembership as ClientMembershipView,
  LineAccess as LineAccessView,
  LineFriend as LineFriendView,
  PermissionError as PermissionErrorView,
  NotFound as NotFoundView,
} from "./views"

const RouteManagement = () => {
  return (
    <ErrorBoundaryComponent>
      <Suspense>
        <Routes>
          <Route
            path="/"
            element={<Navigate replace to={COMMONS.ADMIN_LOGIN_ROUTE} />}
          />
          <Route
            path={COMMONS.ADMIN_LOGIN_ROUTE}
            element={
              <MinimalLayout>
                <AdminLoginView />
              </MinimalLayout>
            }
          />
          <Route
            path={COMMONS.ADMIN_STORES_ROUTE}
            element={
              <AdminMainLayout>
                <AdminStoresView />
              </AdminMainLayout>
            }
          />
          <Route
            path={`${COMMONS.ADMIN_MEMBERS_ROUTE}/:id`}
            element={
              <AdminMainLayout>
                <AdminMembersView />
              </AdminMainLayout>
            }
          />
          <Route
            path={`${COMMONS.ADMIN_AUDIENCES_ROUTE}/:id`}
            element={
              <AdminMainLayout>
                <AdminAudiencesView />
              </AdminMainLayout>
            }
          />
          <Route
            path={COMMONS.ADMIN_SETTINGS_ROUTE}
            element={
              <AdminMainLayout>
                <AdminSettingsView />
              </AdminMainLayout>
            }
          />
          <Route
            path={`${COMMONS.CLIENT_LOGIN_ROUTE}/:liffId`}
            element={
              <MinimalLayout>
                <ClientLoginView />
              </MinimalLayout>
            }
          />
          <Route
            path={`${COMMONS.CLIENT_ECLOGIN_ROUTE}/:liffId/:storeId`}
            element={
              <MinimalLayout>
                <ClientEcLoginView />
              </MinimalLayout>
            }
          />
          <Route
            path={`${COMMONS.CLIENT_EC_STATUS_CHECK_ROUTE}/:liffId/:storeId`}
            element={
              <MinimalLayout>
                <ClientEcStatusinView />
              </MinimalLayout>
            }
          />
          <Route
            path={`${COMMONS.CLIENT_REGISTER_ROUTE}/:liffId/:storeId`}
            element={
              <ClientMainLayout>
                <ClientRegisterView />
              </ClientMainLayout>
            }
          />
          <Route
            path={`${COMMONS.CLIENT_PROFILE_UPDATE_ROUTE}/:liffId/:storeId`}
            element={
              <ClientMainLayout>
                <ClientProfileUpdateView />
              </ClientMainLayout>
            }
          />
          <Route
            path={`${COMMONS.CLIENT_MEMBERSHIP_ROUTE}/:liffId/:storeId`}
            element={
              <ClientMainLayout>
                <ClientMembershipView />
              </ClientMainLayout>
            }
          />
          <Route
            path={COMMONS.LINE_ACCESS_ROUTE}
            element={
              <MinimalLayout>
                <LineAccessView />
              </MinimalLayout>
            }
          />
          <Route
            path={COMMONS.LINE_FRIEND_ROUTE}
            element={
              <MinimalLayout>
                <LineFriendView />
              </MinimalLayout>
            }
          />
          <Route
            path={COMMONS.PERMISSION_ERROR_ROUTE}
            element={
              <MinimalLayout>
                <PermissionErrorView />
              </MinimalLayout>
            }
          />
          <Route
            path={COMMONS.NOT_FOUND_ROUTE}
            element={
              <MinimalLayout>
                <NotFoundView />
              </MinimalLayout>
            }
          />
          <Route
            path="*"
            element={<Navigate replace to={COMMONS.NOT_FOUND_ROUTE} />}
          />
        </Routes>
      </Suspense>
    </ErrorBoundaryComponent>
  )
}

export default RouteManagement
