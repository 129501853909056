import React from "react"
import ReactDOM from "react-dom"
import { ConfigProvider } from "antd"
import jaJP from "antd/lib/locale/ja_JP"
import { HelmetProvider } from "react-helmet-async"
import { QueryClientProvider, QueryClient } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

import App from "./App"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={jaJP}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
)
