import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Col, Row, Card, Divider, Empty, Avatar } from "antd"
import { ShopTwoTone } from "@ant-design/icons"
import styled from "styled-components"
import * as COMMONS from "common/common"
import * as API from "common/api"

const CustomAvatar = styled(Avatar)`
  & {
    line-height: 0 !important;
  }

  & > img {
    object-fit: contain !important;
  }
`

const Stores = (props) => {
  const { auth, stores, setCurrentStore, publicSettings } = props
  const navigate = useNavigate()
  const type = props.location?.state?.type

  const handleStore = (storeId) => {
    setCurrentStore(storeId)

    switch (type) {
      case COMMONS.ADMIN_MEMBERS_ROUTE:
        navigate(`${COMMONS.ADMIN_MEMBERS_ROUTE}/${storeId}`)
        break
      default:
        navigate(`${COMMONS.ADMIN_MEMBERS_ROUTE}/${storeId}`)
        break
    }
  }

  useEffect(() => {
    if (auth && auth.stores && auth.stores.length === 1) {
      handleStore(auth.stores[0])
    }

    // eslint-disable-next-line
  }, [auth])

  return (
    <>
      <Card title="店舗選択" bordered={false}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Row gutter={[8, 8]} justify="start">
              {stores.length > 0 ? (
                stores.map((store) => (
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 4 }}
                    key={store.storeId}
                  >
                    <Card
                      bordered
                      style={{
                        borderColor:
                          publicSettings?.PRIMARY_COLOR ||
                          COMMONS.PRIMARY_COLOR,
                      }}
                      onClick={() => handleStore(store.storeId)}
                      className="cursor-pointer"
                    >
                      <div className="m-2">
                        <Row justify="center">
                          <Col>
                            {store.picUrl ? (
                              <CustomAvatar
                                size={80}
                                src={`${API.UPLOADS_URL}${store.picUrl}`}
                              />
                            ) : (
                              <ShopTwoTone
                                twoToneColor={
                                  publicSettings?.PRIMARY_COLOR ||
                                  COMMONS.PRIMARY_COLOR
                                }
                                style={{
                                  fontSize: 80,
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Divider />
                        <Row justify="center">
                          <Col>{store.name}</Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col span={24}>
                  <Row justify="center">
                    <Col>
                      <Empty />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default Stores
