import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  Col,
  message,
  Row,
  Table,
  Form,
  Modal,
  Input,
  InputNumber,
  Select,
  Checkbox,
  Divider,
  DatePicker,
  Tooltip,
} from "antd"
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons"
import { useQuery, useMutation, useQueryClient } from "react-query"
import styled from "styled-components"
import moment from "moment"
import { socket } from "common/socket"
import * as COMMONS from "common/common"
import * as API from "common/api"

const StyledTable = styled(Table)`
  td {
    word-break: break-all;
  }
`

const CustomDatePicker = styled(DatePicker)`
  .ant-picker-input > input {
    text-align: center;
  }
`

const { Option } = Select

const Audiences = (props) => {
  const { currentStore } = props
  const storeId = currentStore

  const navigate = useNavigate()
  const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()
  const queryClient = useQueryClient()
  const [audienceForm] = Form.useForm()
  const [membersFilterForm] = Form.useForm()

  const [store, setStore] = useState({})
  const [audiences, setAudiences] = useState([])
  const [members, setMembers] = useState([])
  // const [selectedMemberRowKeys, setSelectedMemberRowKeys] = useState([])

  const [isAudienceModalVisible, setIsAudienceModalVisible] = useState(false)

  // Combine filter conditions to enable single query
  const [filters, setFilters] = useState({
    countVisitMin: undefined,
    countVisitMax: undefined,
    lastVisitMin: undefined,
    lastVisitMax: undefined,
    petBirthDayMin: undefined,
    petBirthDayMax: undefined,
    petBirthMonth: undefined,
    region: undefined,
    petSize: undefined,
    petGender: undefined,
  })

  // const [filterGender, setFilterGender] = useState(undefined)
  // const [filterAgeMin, setFilterAgeMin] = useState(undefined)
  // const [filterAgeMax, setFilterAgeMax] = useState(undefined)
  // const [filterOccupation, setFilterOccupation] = useState(undefined)
  // const [filterAddress, setFilterAddress] = useState(undefined)

  useQuery(
    [API.QUERY_KEY_ADMIN_STORE, { storeId: storeId }],
    () => API.ADMIN_GET_STORE(storeId),
    {
      enabled: Number.isInteger(storeId),
      onSuccess: (response) => {
        if (isMountedRef.current) {
          if (response?.data) {
            setStore(response.data)
          } else {
            setStore({})
          }
        }
      },
      onError: (error) => {
        if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
          navigate(COMMONS.PERMISSION_ERROR_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
          message.warning(COMMONS.ERROR_SESSION_MSG)
          navigate(COMMONS.ADMIN_LOGIN_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        } else {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        }
      },
    },
  )

  useQuery(
    [API.QUERY_KEY_ADMIN_AUDIENCES, { storeId: storeId }],
    () => API.ADMIN_GET_AUDIENCES(storeId),
    {
      enabled: Number.isInteger(storeId),
      onSuccess: (response) => {
        if (isMountedRef.current) {
          setAudiences(response?.data || [])
        }
      },
      onError: (error) => {
        if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
          navigate(COMMONS.PERMISSION_ERROR_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
          message.warning(COMMONS.ERROR_SESSION_MSG)
          navigate(COMMONS.ADMIN_LOGIN_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        } else {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        }
      },
    },
  )

  useQuery(
    [
      API.QUERY_KEY_ADMIN_MEMBERS_LIST,
      {
        storeId: storeId,
        // filterGender: filterGender,
        // filterAgeMin: filterAgeMin,
        // filterAgeMax: filterAgeMax,
        // filterOccupation: filterOccupation,
        // filterAddress: filterAddress,
        filters: filters,
      },
    ],
    () =>
      API.ADMIN_GET_MEMBERS_LIST(
        storeId,
        // filterGender,
        // filterAgeMin,
        // filterAgeMax,
        filters.countVisitMin,
        filters.countVisitMax,
        filters.lastVisitMin,
        filters.lastVisitMax,
        // filterOccupation,
        // filterAddress
        filters.petBirthDayMin,
        filters.petBirthDayMax,
        filters.petBirthMonth,
        filters.region,
        filters.petSize,
        filters.petGender,
      ),
    {
      enabled: Number.isInteger(storeId) && isAudienceModalVisible,
      onSuccess: (response) => {
        if (isMountedRef.current) {
          setMembers(response.data || [])
        }
      },
      onError: (error) => {
        if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
          navigate(COMMONS.PERMISSION_ERROR_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
          message.warning(COMMONS.ERROR_SESSION_MSG)
          navigate(COMMONS.ADMIN_LOGIN_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        } else {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        }
      },
    },
  )

  const audienceCreateMutation = useMutation(API.ADMIN_CREATE_AUDIENCE, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_CREATE_MSG)
      hideAudienceModal()
      queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_AUDIENCES)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_CONFLICT_ERROR) {
        message.warning(COMMONS.WARN_AUDIENCE_NAME_EXIST_MSG)
      } else if (
        error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR
      ) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const audienceDeleteMutation = useMutation(API.ADMIN_DELETE_AUDIENCE, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_DELETE_MSG)
      queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_AUDIENCES)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const handleCreate = (data) => {
    // if (
    //   members.filter((m) => selectedMemberRowKeys.includes(m.memberId)).length >
    //   0
    // ) {
    let paramData = {
      storeId: store.storeId,
      audienceName: data.audienceName,
      remarks: data.remarks,
      lineIds: members.map((m) => m.lineId),
      // lineIds: members
      //   .filter((m) => selectedMemberRowKeys.includes(m.memberId))
      //   .map((m) => m.lineId),
    }

    audienceCreateMutation.mutate(paramData)
    // }
    // else {
    //   message.warning('会員数がゼロの場合、オーディエンスは作成されません。会員を選択してください')
    // }
  }

  const handleAudienceDelete = (audience) => {
    const paramData = {
      storeId: store.storeId,
      audienceGroupId: audience.audienceGroupId,
    }

    Modal.confirm({
      title: "確認",
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: (
        <p>
          <span className="text-red-600">{audience.description || "ー"}</span>
          を削除してもよろしいでしょうか？
        </p>
      ),
      okText: "削除",
      okButtonProps: {
        size: "large",
        type: "primary",
        danger: true,
      },
      cancelText: "閉じる",
      cancelButtonProps: {
        size: "large",
      },
      centered: true,
      onOk() {
        audienceDeleteMutation.mutate(paramData)
      },
    })
  }

  useEffect(() => {
    socket.on("audience", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.storeId && response.storeId === parseInt(storeId)) {
          queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_AUDIENCES)
        }
      }
    })

    socket.on("member", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.storeId && response.storeId === parseInt(storeId)) {
          queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_MEMBERS_LIST)
        }
      }
    })

    return () => {
      socket.off("audience")
      socket.off("member")
    }

    // eslint-disable-next-line
  }, [])

  const clearFilters = () => {
    // setFilterGender(undefined)
    // setFilterAgeMin(undefined)
    // setFilterAgeMax(undefined)
    // setFilterOccupation(undefined)
    // setFilterAddress(undefined)

    setFilters({
      countVisitMin: undefined,
      countVisitMax: undefined,
      lastVisitMin: undefined,
      lastVisitMax: undefined,
      petBirthDayMin: undefined,
      petBirthDayMax: undefined,
      petBirthMonth: undefined,
      region: undefined,
      petSize: undefined,
      petGender: undefined,
    })

    membersFilterForm.resetFields()
  }

  const showAudienceModal = () => {
    setIsAudienceModalVisible(true)
  }

  const hideAudienceModal = () => {
    clearFilters()
    // setSelectedMemberRowKeys([])
    setIsAudienceModalVisible(false)
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "audienceGroupId",
      align: "center",
      sorter: (a, b) => a.audienceGroupId - b.audienceGroupId,
      sortDirections: ["ascend", "descend"],
      render: (audienceGroupId) => audienceGroupId ?? "ー",
    },
    {
      title: "オーディエンス名",
      dataIndex: "description",
      align: "center",
      sorter: (a, b) =>
        (a.description || "").localeCompare(b.description || ""),
      sortDirections: ["ascend", "descend"],
      render: (description) => description ?? "ー",
    },
    {
      title: "備考",
      dataIndex: "remarks",
      align: "center",
      render: (remarks) => remarks ?? "ー",
    },
    {
      title: "サイズ",
      dataIndex: "audienceCount",
      align: "center",
      sorter: (a, b) => a.audienceCount - b.audienceCount,
      sortDirections: ["ascend", "descend"],
      render: (audienceCount) => audienceCount ?? "ー",
    },
    {
      title: "状態",
      dataIndex: "status",
      align: "center",
      render: (status) => (status ? COMMONS.GET_AUDIENCE_STATUS(status) : "ー"),
    },
    {
      title: "作成日",
      dataIndex: "createdAt",
      align: "center",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ["ascend", "descend"],
      render: (createdAt) =>
        createdAt ? (
          <>
            {moment(createdAt).format("YYYY/M/D")}
            <br />
            {moment(createdAt).format("HH:mm")}
          </>
        ) : (
          "ー"
        ),
    },
    {
      title: "",
      dataIndex: "action",
      align: "center",
      width: 50,
      render: (audience) => (
        <>
          <Tooltip title="削除" placement="top">
            <Button
              size="large"
              className="m-1"
              icon={<DeleteOutlined />}
              danger
              onClick={() => {
                handleAudienceDelete(audience)
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ]

  const memberColumns = [
    {
      title: "ID",
      dataIndex: "memberId",
      align: "center",
      width: 50,
      sorter: (a, b) => a.memberId - b.memberId,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "氏名",
      dataIndex: "fullName",
      align: "center",
      sorter: (a, b) => (a.fullName || "").localeCompare(b.fullName || ""),
      sortDirections: ["ascend", "descend"],
    },
    // {
    //   title: "年齢",
    //   dataIndex: "dateOfBirth",
    //   align: "center",
    //   sorter: (a, b) =>
    //     (a.dateOfBirth ? moment(a.dateOfBirth).toDate() : "") -
    //     (b.dateOfBirth ? moment(b.dateOfBirth).toDate() : ""),
    //   render: (dateOfBirth) => (
    //     <>
    //       {dateOfBirth
    //         ? moment().diff(moment(dateOfBirth, "YYYY-MM-DD"), "year")
    //         : "ー"}
    //       才
    //     </>
    //   ),
    // },
    // {
    //   title: "性別",
    //   dataIndex: "gender",
    //   align: "center",
    // },
    // {
    //   title: "郵便番号",
    //   dataIndex: "postalCode",
    //   align: "center",
    // },
    // {
    //   title: "住所",
    //   dataIndex: "address",
    //   align: "center",
    // },
    {
      title: "来店回数",
      dataIndex: "countVisit",
      align: "center",
      render: (countVisit) => <>{countVisit || "ー"}回</>,
    },
    {
      title: "来店日（最後）",
      dataIndex: "lastVisit",
      align: "center",
      sorter: (a, b) => (a.lastVisit || "").localeCompare(b.lastVisit || ""),
      sortDirections: ["ascend", "descend"],
      render: (lastVisit) => (
        <>{lastVisit ? moment(lastVisit).format("YYYY/M/D") : "ー"}</>
      ),
    },
    {
      title: "有効期限",
      dataIndex: "activeUntil",
      align: "center",
      fixed: "right",
      sorter: (a, b) =>
        (a.activeUntil || "").localeCompare(b.activeUntil || ""),
      sortDirections: ["ascend", "descend"],
      render: (activeUntil) => (
        <>{activeUntil ? moment(activeUntil).format("YYYY/M/D") : "ー"}</>
      ),
    },
  ]

  const handleFilter = (data) => {
    setFilters({
      countVisitMin: data.countVisitMinFilter,
      countVisitMax: data.countVisitMaxFilter,
      lastVisitMin: data.lastVisitMinFilter
        ? moment(data.lastVisitMinFilter).format("YYYY-MM-DD")
        : data.lastVisitMinFilter,
      lastVisitMax: data.lastVisitMaxFilter
        ? moment(data.lastVisitMaxFilter).format("YYYY-MM-DD")
        : data.lastVisitMaxFilter,
      petBirthDayMin: data.petBirthDayMinFilter
        ? moment(data.petBirthDayMinFilter).format("YYYY-MM-DD")
        : data.petBirthDayMinFilter,
      petBirthDayMax: data.petBirthDayMaxFilter
        ? moment(data.petBirthDayMaxFilter).format("YYYY-MM-DD")
        : data.petBirthDayMaxFilter,
      petBirthMonth: data.petBirthMonthFilter,
      region: data.regionFilter,
      petSize: data.petSizeFilter,
      petGender: data.petGenderFilter,
    })
    // if (data.genderFilter !== filterGender) {
    //   setFilterGender(data.genderFilter)
    // }
    // if (data.ageMinFilter !== filterAgeMin) {
    //   setFilterAgeMin(data.ageMinFilter)
    // }
    // if (data.ageMaxFilter !== filterAgeMax) {
    //   setFilterAgeMax(data.ageMaxFilter)
    // }
    // if (data.occupationFilter !== filterOccupation) {
    //   setFilterOccupation(data.occupationFilter)
    // }
    // if (data.addressFilter !== filterAddress) {
    //   setFilterAddress(data.addressFilter)
    // }
  }

  return (
    <>
      <Card title={`オーディエンス：${store.name || ""}`} bordered={false}>
        <Row className="mb-1" align="middle" justify="end">
          <Col>
            <Row gutter={[8, 8]}>
              <Col>
                <Button
                  size="large"
                  icon={<PlusOutlined />}
                  onClick={showAudienceModal}
                >
                  新規作成
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <StyledTable
              size="large"
              columns={columns}
              dataSource={
                audiences.length > 0
                  ? audiences.map((a) => {
                      return {
                        key: a.audienceGroupId,
                        audienceGroupId: a?.audienceGroupId,
                        description: a?.description,
                        remarks: a?.remarks,
                        audienceCount: a?.audienceCount,
                        status: a?.status,
                        createdAt: a?.createdAt,
                        action: a,
                      }
                    })
                  : []
              }
              scroll={{ x: "max-content" }}
              locale={{ emptyText: "オーディエンスのデータがありません" }}
              bordered={true}
              pagination={{
                responsive: true,
                showTotal: (total, range) =>
                  `全${total}件中${range[0]}～${range[1]}件目`,
                defaultCurrent: 1,
                defaultPageSize: 20,
                position: ["bottomCenter"],
              }}
            />
          </Col>
        </Row>
      </Card>
      <Modal
        title="新規作成"
        visible={isAudienceModalVisible}
        onCancel={hideAudienceModal}
        footer={null}
        destroyOnClose
        centered
        width={720}
      >
        <Row>
          <Divider>会員検索</Divider>
          <Col xs={24} className="mb-4">
            <div className="flex justify-end">
              <Button size="large" onClick={clearFilters}>
                フィルタークリア
              </Button>
            </div>
          </Col>
          <Col xs={24} className="mb-4 p-4 bg-gray-100">
            <Form
              size="large"
              name="membersFilterForm"
              form={membersFilterForm}
              onFinish={handleFilter}
              initialValues={{
                countVisitMinFilter: undefined,
                countVisitMaxFilter: undefined,
                lastVisitMinFilter: undefined,
                lastVisitMaxFilter: undefined,
                petBirthDayMinFilter: undefined,
                petBirthDayMaxFilter: undefined,
                petBirthMonthFilter: undefined,
                regionFilter: undefined,
                petSizeFilter: undefined,
                petGenderFilter: undefined,
              }}
            >
              <Row gutter={[16, 16]} justify="start">
                {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item help="性別" name="genderFilter" className="mb-4">
                    <Select placeholder="性別を選択してください" allowClear>
                      {COMMONS.GENDER.map((gender) => (
                        <Option key={gender.value} value={gender.value}>
                          {gender.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="職業"
                    name="occupationFilter"
                    className="mb-4"
                  >
                    <Input placeholder="例：社会人" allowClear />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="最低年齢"
                    name="ageMinFilter"
                    className="mb-4"
                  >
                    <InputNumber
                      placeholder="例：18"
                      addonAfter="才"
                      min={1}
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="最大年齢"
                    name="ageMaxFilter"
                    className="mb-4"
                  >
                    <InputNumber
                      placeholder="例：20"
                      addonAfter="才"
                      min={1}
                      className="w-full"
                    />
                  </Form.Item>
                </Col> */}
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="最低来店回数"
                    name="countVisitMinFilter"
                    className="mb-0"
                  >
                    <InputNumber
                      placeholder="例：1"
                      addonAfter="回"
                      min={1}
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="最大来店回数"
                    name="countVisitMaxFilter"
                    className="mb-0"
                  >
                    <InputNumber
                      placeholder="例：5"
                      addonAfter="回"
                      min={1}
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="来店日から"
                    name="lastVisitMinFilter"
                    className="mb-0"
                  >
                    <CustomDatePicker className="w-full" inputReadOnly />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="来店日まで"
                    name="lastVisitMaxFilter"
                    className="mb-0"
                  >
                    <CustomDatePicker className="w-full" inputReadOnly />
                  </Form.Item>
                </Col>
                {/* <Col xs={{ span: 24 }}>
                  <Form.Item
                    help="郵便番号・都道府県・住所"
                    name="addressFilter"
                    className="mb-4"
                  >
                    <Input placeholder="例：愛知県名古屋○○" allowClear />
                  </Form.Item>
                </Col> */}
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="ペット誕生日から"
                    name="petBirthDayMinFilter"
                    className="mb-0"
                  >
                    <CustomDatePicker className="w-full" inputReadOnly />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="ペット誕生日まで"
                    name="petBirthDayMaxFilter"
                    className="mb-0"
                  >
                    <CustomDatePicker className="w-full" inputReadOnly />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="ペット誕生月"
                    name="petBirthMonthFilter"
                    className="mb-0"
                  >
                    <Select placeholder="誕生月を選択" allowClear>
                      <Option value={1}>１月</Option>
                      <Option value={2}>２月</Option>
                      <Option value={3}>３月</Option>
                      <Option value={4}>４月</Option>
                      <Option value={5}>５月</Option>
                      <Option value={6}>６月</Option>
                      <Option value={7}>７月</Option>
                      <Option value={8}>８月</Option>
                      <Option value={9}>９月</Option>
                      <Option value={10}>１０月</Option>
                      <Option value={11}>１１月</Option>
                      <Option value={12}>１２月</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="飼い主住所"
                    name="regionFilter"
                    className="mb-0"
                  >
                    <Select
                      options={COMMONS.PREFECTURAL_REGIONS}
                      placeholder={COMMONS.SELECT_REGION_PLACEHOLDER_TEXT}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="ペットのサイズ"
                    name="petSizeFilter"
                    className="mb-0"
                    valuePropName="checked"
                  >
                    <Checkbox.Group options={COMMONS.PET_SIZE} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    help="ペットの性別"
                    name="petGenderFilter"
                    className="mb-0"
                    valuePropName="checked"
                  >
                    <Checkbox.Group options={COMMONS.PET_GENDER} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]} className="mt-4" justify="center">
                <Col>
                  <Button type="dashed" htmlType="submit" className="px-8">
                    検索
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Divider>オーディエンス作成</Divider>
          <Col span={24}>
            <Form
              name="audienceForm"
              form={audienceForm}
              onFinish={handleCreate}
              layout="vertical"
              size="large"
              requiredMark={true}
              preserve={false}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="audienceName"
                    label="オーディエンス名"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "オーディエンス名は必須です",
                      },
                    ]}
                  >
                    <Input
                      placeholder="例：オーディエンス１"
                      onPressEnter={(e) => e.preventDefault()}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="remarks" label="備考">
                    <Input.TextArea
                      allowClear
                      bordered
                      showCount
                      autoSize={{ minRows: 5, maxRows: 15 }}
                      maxLength={250}
                      placeholder="例：18才以上オーディエンス"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Table
                    bordered
                    size="large"
                    title={() => "オーディエンスの会員"}
                    // footer={() => (
                    //   <p className="text-center">
                    //     ※オーディエンスに入れたい会員を選択して作成します。
                    //   </p>
                    // )}
                    columns={memberColumns}
                    scroll={{ x: "max-content" }}
                    // rowSelection={{
                    //   selectedMemberRowKeys,
                    //   onChange: (selectedRowKeys) => {
                    //     setSelectedMemberRowKeys(selectedRowKeys)
                    //   },
                    // }}
                    dataSource={
                      members
                        ? members.map((member) => {
                            return {
                              key: member.memberId,
                              memberId: member.memberId,
                              fullName: `${member.lastName || "ー"}${
                                member.firstName || "ー"
                              }`,
                              // dateOfBirth: member.dateOfBirth,
                              // gender: COMMONS.GET_GENDER_BY_VALUE(
                              //   member.gender
                              // ),
                              // postalCode: `〒${
                              //   member.zipPostal
                              //     ? COMMONS.POSTAL_CODE_INSERT_CHARACTER(
                              //         member.zipPostal,
                              //         3,
                              //         "-"
                              //       )
                              //     : "ー"
                              // }`,
                              // address: member.address || "ー",
                              // occupation: member.occupation || "ー",
                              countVisit: member.countVisit,
                              lastVisit: member.lastVisit,
                              activeUntil: member.activeUntil,
                            }
                          })
                        : []
                    }
                    locale={{ emptyText: "会員がありません" }}
                    pagination={{
                      responsive: true,
                      position: ["bottomCenter"],
                    }}
                  />
                </Col>
                <Divider />
                <Col span={24}>
                  <Form.Item
                    className="text-center"
                    style={{ marginBottom: 0 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="px-8"
                      loading={audienceCreateMutation.isLoading}
                    >
                      作成
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default Audiences
