import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { message } from "antd"
import { useMutation, useQuery } from "react-query"
import liff from "@line/liff"
import * as COMMONS from "common/common"
import * as API from "common/api"

const Login = (props) => {
  const { liffId } = useParams()
  const navigate = useNavigate()
  const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()

  const [accessToken, setAccessToken] = useState(undefined)
  const [storeId, setStoreId] = useState(undefined)

  useQuery(
    [API.QUERY_KEY_CLIENT_PERSONAL_INFO, accessToken],
    () => API.CLIENT_GET_PERSONAL_INFO(accessToken),
    {
      enabled: !!accessToken && !!storeId,
      onSuccess: (response) => {
        if (isMountedRef.current) {
          if (response?.data?.ecLogin) {
            // if (COMMONS.CHECK_CUSTOMER_STATUS(response?.data)) {
            //   navigate(`${COMMONS.CLIENT_ECLOGIN_ROUTE}/${liffId}/${storeId}`)
            // } else {
            navigate(`${COMMONS.CLIENT_ECLOGIN_ROUTE}/${liffId}/${storeId}`)
            // }
          } else {
            if (COMMONS.CHECK_IS_COMPLETE(response?.data)) {
              if (COMMONS.CHECK_CUSTOMER_STATUS(response?.data)) {
                navigate(
                  `${COMMONS.CLIENT_MEMBERSHIP_ROUTE}/${liffId}/${storeId}`
                )
              } else {
                navigate(
                  `${COMMONS.CLIENT_EC_STATUS_CHECK_ROUTE}/${liffId}/${storeId}`
                )
              }
            } else {
              navigate(
                // `${COMMONS.CLIENT_EC_STATUS_CHECK_ROUTE}/${liffId}/${storeId}`
                `${COMMONS.CLIENT_REGISTER_ROUTE}/${liffId}/${storeId}`
              )
            }
          }
        }
      },
      onError: (error) => {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      },
    }
  )

  const checkStoreMutation = useMutation(API.CLIENT_CHECK_STORE, {
    onSuccess: (response) => {
      if (response?.data?.storeId) {
        setStoreId(response.data.storeId)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
    onError: (error) => {
      message.error(COMMONS.ERROR_SYSTEM_MSG)
    },
  })

  const handleLineLogin = () => {
    liff.getFriendship().then((data) => {
      if (data.friendFlag) {
        const paramData = {
          liffId: liffId,
          accessToken: liff.getAccessToken(),
        }

        setAccessToken(liff.getAccessToken())

        checkStoreMutation.mutate(paramData)
      } else {
        navigate(COMMONS.LINE_FRIEND_ROUTE)
      }
    })
  }

  useEffect(() => {
    setTimeout(() => {
      liff
        .init({
          liffId: liffId,
        })
        .then(() => {
          if (!liff.isLoggedIn()) {
            liff.login()
          }

          if (process.env.REACT_APP_ENV === "PRODUCTION") {
            if (liff.isInClient()) {
              handleLineLogin()
            } else {
              navigate(COMMONS.LINE_ACCESS_ROUTE)
            }
          } else {
            handleLineLogin()
          }
        })
        .catch((err) => {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        })
    }, 500)
    // eslint-disable-next-line
  }, [])

  return <></>
}

export default Login
