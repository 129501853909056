import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import {
  Card,
  Row,
  Col,
  Table,
  message,
  Button,
  Divider,
  Form,
  Input,
  Image,
  InputNumber,
  Select,
  Checkbox,
  Tooltip,
  Tag,
  Modal,
  DatePicker as AntdDatePicker,
  Descriptions,
  Alert,
  Switch,
} from "antd"
import {
  EyeOutlined,
  HistoryOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  BarcodeOutlined,
  CameraOutlined,
  EditOutlined,
} from "@ant-design/icons"
import { useQuery, useQueryClient, useMutation } from "react-query"
import DatePicker from "react-mobile-datepicker"
import { isMobile } from "react-device-detect"
import BarcodeScannerComponent from "react-qr-barcode-scanner"
import styled from "styled-components"
import moment from "moment"
import "moment/locale/ja"
import * as COMMONS from "common/common"
import * as API from "common/api"
import { socket } from "common/socket"

moment.locale("ja")

const { Option } = Select

const CustomDatePicker = styled(AntdDatePicker)`
  .ant-picker-input > input {
    text-align: center;
  }
`

const Members = (props) => {
  const { currentStore, publicSettings } = props
  const storeId = currentStore
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()
  const barcodeRef = useRef(null)
  const [memberRenewForm] = Form.useForm()
  const [memberEditForm] = Form.useForm()
  const [memberRenewDeviceForm] = Form.useForm()
  const [memberRenewConfirmForm] = Form.useForm()
  const [memberPointForm] = Form.useForm()
  const [memberFilterForm] = Form.useForm()

  const [members, setMembers] = useState([])
  const [store, setStore] = useState({})
  const [currentMember, setCurrentMember] = useState({})
  const [confirmMember, setConfirmMember] = useState({})
  const [renewPickerValue, setRenewPickerValue] = useState(moment().toDate())
  const [renewConfirmPickerValue, setRenewConfirmPickerValue] = useState(
    moment().toDate(),
  )
  const [editPickerValue, setEditPickerValue] = useState(moment().toDate())
  const [isMemberVisit, setIsMemberVisit] = useState(false)

  const [stopCameraStream, setStopCameraStream] = useState(false)
  const [isRenewPickerOpen, setIsRenewPickerOpen] = useState(false)
  const [isEditPickerOpen, setIsEditPickerOpen] = useState(false)
  const [isRenewConfirmPickerOpen, setIsRenewConfirmPickerOpen] =
    useState(false)
  const [isMemberRenewModalVisible, setIsMemberRenewModalVisible] =
    useState(false)
  const [isMemberEditModalVisible, setIsMemberEditModalVisible] =
    useState(false)
  const [isMemberDetailModalVisible, setIsMemberDetailModalVisible] =
    useState(false)
  const [isMemberRenewSelectModalVisible, setIsMemberRenewSelectModalVisible] =
    useState(false)
  const [isMemberRenewDeviceModalVisible, setIsMemberRenewDeviceModalVisible] =
    useState(false)
  const [isMemberRenewCameraModalVisible, setIsMemberRenewCameraModalVisible] =
    useState(false)
  const [
    isMemberRenewConfirmModalVisible,
    setIsMemberRenewConfirmModalVisible,
  ] = useState(false)

  const [paginationPerPage, setPaginationPerPage] = useState(20)
  const [paginationPage, setPaginationPage] = useState(1)
  const [paginationTotal, setPaginationTotal] = useState(0)
  const [paginationSort, setPaginationSort] = useState("desc")
  const [paginationSortKey, setPaginationSortKey] = useState("memberId")

  // Combine filter conditions to enable single query
  const [filters, setFilters] = useState({
    displayName: undefined,
    fullName: undefined,
    furiganaName: undefined,
    countVisitMin: undefined,
    countVisitMax: undefined,
    lastVisitMin: undefined,
    lastVisitMax: undefined,
    lineFriend: undefined,
    petBirthDayMin: undefined,
    petBirthDayMax: undefined,
    petBirthMonth: undefined,
    region: undefined,
    petSize: undefined,
    petGender: undefined,
  })

  // const [filterGender, setFilterGender] = useState(undefined)
  // const [filterAgeMin, setFilterAgeMin] = useState(undefined)
  // const [filterAgeMax, setFilterAgeMax] = useState(undefined)
  // const [filterTelephone, setFilterTelephone] = useState(undefined)
  // const [filterOccupation, setFilterOccupation] = useState(undefined)
  // const [filterAddress, setFilterAddress] = useState(undefined)

  useQuery(
    [API.QUERY_KEY_ADMIN_STORE, { storeId: storeId }],
    () => API.ADMIN_GET_STORE(storeId),
    {
      enabled: Number.isInteger(storeId),
      onSuccess: (response) => {
        if (isMountedRef.current) {
          if (response?.data) {
            setStore(response.data)
          } else {
            setStore({})
          }
        }
      },
      onError: (error) => {
        if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
          navigate(COMMONS.PERMISSION_ERROR_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
          message.warning(COMMONS.ERROR_SESSION_MSG)
          navigate(COMMONS.ADMIN_LOGIN_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        } else {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        }
      },
    },
  )

  useQuery(
    [
      API.QUERY_KEY_ADMIN_MEMBERS,
      {
        storeId: storeId,
        paginationPerPage: paginationPerPage,
        paginationPage: paginationPage,
        paginationSort: paginationSort,
        paginationSortKey: paginationSortKey,
        // filterGender: filterGender,
        // filterAgeMin: filterAgeMin,
        // filterAgeMax: filterAgeMax,
        // filterTelephone: filterTelephone,
        // filterOccupation: filterOccupation,
        // filterAddress: filterAddress,
        filters: filters,
      },
    ],
    () =>
      API.ADMIN_GET_MEMBERS(
        storeId,
        paginationPerPage,
        paginationPage,
        paginationSort,
        paginationSortKey,
        filters.displayName,
        filters.fullName,
        filters.furiganaName,
        // filterGender,
        // filterAgeMin,
        // filterAgeMax,
        filters.countVisitMin,
        filters.countVisitMax,
        filters.lastVisitMin,
        filters.lastVisitMax,
        // filterTelephone,
        // filterOccupation,
        // filterAddress,
        filters.lineFriend,
        filters.petBirthDayMin,
        filters.petBirthDayMax,
        filters.petBirthMonth,
        filters.region,
        filters.petSize,
        filters.petGender,
      ),
    {
      enabled: Number.isInteger(storeId),
      keepPreviousData: true,
      onSuccess: (response) => {
        if (isMountedRef.current) {
          if (response?.data?.rows && response.data.rows.length > 0) {
            setMembers(response.data.rows)
          } else {
            setMembers([])
          }

          setPaginationPerPage(response.data.pp || 20)
          setPaginationPage(response.data.p || 1)
          setPaginationTotal(response.data.count || 0)
          setPaginationSort(response.data.sort || "desc")
          setPaginationSortKey(response.data.sortKey || "memberId")
        }
      },
      onError: (error) => {
        if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
          navigate(COMMONS.PERMISSION_ERROR_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
          message.warning(COMMONS.ERROR_SESSION_MSG)
          navigate(COMMONS.ADMIN_LOGIN_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        } else {
          message.error(COMMONS.ERROR_SYSTEM_MSG)
        }
      },
    },
  )

  const memberRenewMutation = useMutation(API.ADMIN_RENEW_MEMBER, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_UPDATE_MSG)
      hideMemberRenewModal()
      hideMemberRenewConfirmModal()
      queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_MEMBERS)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const memberEditMutation = useMutation(API.ADMIN_RENEW_MEMBER, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_UPDATE_MSG)
      hideMemberEditModal()
      hideMemberRenewConfirmModal()
      queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_MEMBERS)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const memberGetMutation = useMutation(API.ADMIN_GET_MEMBER, {
    onSuccess: (response) => {
      setCurrentMember(response?.data || {})
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const memberDeleteMutation = useMutation(API.ADMIN_DELETE_MEMBER, {
    onSuccess: () => {
      message.success(COMMONS.SUCCESS_DELETE_MSG)
      queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_MEMBERS)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const memberCheckMutation = useMutation(API.ADMIN_CHECK_MEMBER, {
    onSuccess: (response) => {
      showMemberRenewConfirmModal(response?.data || {})
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_CONFLICT_ERROR) {
        message.warning(COMMONS.WARN_MEMBER_CODE_NOT_EXIST_MSG)
        hideMemberRenewCameraModal()
      } else if (
        error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR
      ) {
        navigate(COMMONS.PERMISSION_ERROR_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
        message.warning(COMMONS.ERROR_SESSION_MSG)
        navigate(COMMONS.ADMIN_LOGIN_ROUTE)
      } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  useEffect(() => {
    socket.on("member", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (response.storeId && response.storeId === parseInt(storeId)) {
          queryClient.invalidateQueries(API.QUERY_KEY_ADMIN_MEMBERS)
        }
      }
    })

    return () => {
      socket.off("member")
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isMemberRenewModalVisible && currentMember?.memberId) {
      setRenewPickerValue(
        currentMember?.activeUntil
          ? moment(currentMember?.activeUntil)
              .add(1, "year")
              .subtract(1, "day")
              .toDate()
          : moment(currentMember?.memberSince)
              .add(1, "year")
              .subtract(1, "day")
              .toDate(),
      )

      memberRenewForm.setFieldsValue({
        memberId: currentMember?.memberId,
        activeUntil: isMobile
          ? currentMember?.activeUntil
            ? moment(currentMember?.activeUntil)
                .add(1, "year")
                .subtract(1, "day")
                .format("YYYY-MM-DD")
            : moment(currentMember?.memberSince)
                .add(1, "year")
                .subtract(1, "day")
                .format("YYYY-MM-DD")
          : currentMember?.activeUntil
            ? moment(currentMember?.activeUntil)
                .add(1, "year")
                .subtract(1, "day")
            : moment(currentMember?.memberSince)
                .add(1, "year")
                .subtract(1, "day"),
      })
    }
  }, [currentMember, memberRenewForm, isMemberRenewModalVisible])

  useEffect(() => {
    if (isMemberEditModalVisible && currentMember?.memberId) {
      setEditPickerValue(
        currentMember?.memberSince
          ? moment(currentMember?.memberSince).toDate()
          : moment().toDate(),
      )

      memberEditForm.setFieldsValue({
        memberId: currentMember?.memberId,
        memberSince: isMobile
          ? currentMember?.memberSince || ""
          : currentMember?.memberSince
            ? moment(currentMember?.memberSince)
            : undefined,
      })
    }
  }, [currentMember, memberEditForm, isMemberEditModalVisible])

  useEffect(() => {
    setTimeout(() => {
      if (barcodeRef && barcodeRef.current) {
        barcodeRef.current.focus()
      }
    }, 1000)
  })

  const handleMemberRenew = (data) => {
    const paramData = {
      memberId: data.memberId,
      activeUntil: moment(data.activeUntil).format("YYYY-MM-DD"),
      storeId: storeId,
    }

    memberRenewMutation.mutate(paramData)
  }

  const handleMemberEdit = (data) => {
    const paramData = {
      memberId: data.memberId,
      memberSince: moment(data.memberSince).format("YYYY-MM-DD"),
      storeId: storeId,
      kakeruPointIsAdd: data.kakeruPointIsAdd,
      kakeruPoint: data.kakeruPoint,
    }

    memberEditMutation.mutate(paramData)
  }

  const handleMemberDelete = (member) => {
    const paramData = {
      memberId: member.memberId,
      storeId: storeId,
    }

    Modal.confirm({
      title: "確認",
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: (
        <p>
          <span className="text-red-600">{`${member.lastName || "ー"}${
            member.firstName || "ー"
          }`}</span>
          様の会員を削除してもよろしいでしょうか？
        </p>
      ),
      okText: "削除",
      okButtonProps: {
        size: "large",
        type: "primary",
        danger: true,
      },
      cancelText: "閉じる",
      cancelButtonProps: {
        size: "large",
      },
      centered: true,
      onOk() {
        memberDeleteMutation.mutate(paramData)
      },
    })
  }
  const handleMemberPointUpdate = (data) => {
    const paramData = {
      memberSince: moment(confirmMember?.memberSince).format("YYYY-MM-DD"),
      storeId: storeId,
      kakeruPointIsAdd: data.kakeruPointIsAdd,
      kakeruPoint: data.kakeruPoint,
      memberId: confirmMember?.memberId,
      memberVisitId: confirmMember?.memberVisitId,
      visitDate: moment().toISOString(),
    }

    memberEditMutation.mutate(paramData)
  }

  const handleMemberDetail = (memberId) => {
    const paramData = {
      memberId: memberId,
      storeId: storeId,
    }

    memberGetMutation.mutate(paramData)
  }

  const handleMemberCheck = (data) => {
    const paramData = {
      memberCode: data.memberCode,
      storeId: storeId,
      isMemberVisit: isMemberVisit,
    }

    memberCheckMutation.mutate(paramData)
  }

  const showMemberDetailModal = (memberId) => {
    handleMemberDetail(memberId)
    setIsMemberDetailModalVisible(true)
  }

  const hideMemberDetailModal = () => {
    setCurrentMember({})
    setIsMemberDetailModalVisible(false)
  }

  const showMemberRenewModal = (memberId) => {
    handleMemberDetail(memberId)
    setIsMemberRenewModalVisible(true)
  }

  const hideMemberRenewModal = () => {
    setCurrentMember({})
    setIsMemberRenewModalVisible(false)
  }

  const showMemberEditModal = (memberId) => {
    handleMemberDetail(memberId)
    setIsMemberEditModalVisible(true)
  }

  const hideMemberEditModal = () => {
    setCurrentMember({})
    setIsMemberEditModalVisible(false)
  }

  const showMemberRenewSelectModal = (isVisit = false) => {
    setIsMemberVisit(isVisit)
    setIsMemberRenewSelectModalVisible(true)
  }

  const hideMemberRenewSelectModal = () => {
    setIsMemberRenewSelectModalVisible(false)
  }

  const showMemberRenewDeviceModal = () => {
    hideMemberRenewSelectModal()
    setIsMemberRenewDeviceModalVisible(true)
  }

  const hideMemberRenewDeviceModal = () => {
    setIsMemberRenewDeviceModalVisible(false)
  }

  const showMemberRenewCameraModal = () => {
    hideMemberRenewSelectModal()
    setStopCameraStream(false)
    setIsMemberRenewCameraModalVisible(true)
  }

  const hideMemberRenewCameraModal = () => {
    setStopCameraStream(true)
    setIsMemberRenewCameraModalVisible(false)
  }

  const showMemberRenewConfirmModal = (member = {}) => {
    setConfirmMember(member)
    hideMemberRenewDeviceModal()
    hideMemberRenewCameraModal()

    if (!isMemberVisit) {
      setRenewConfirmPickerValue(
        member?.activeUntil
          ? moment(member?.activeUntil)
              .add(1, "year")
              .subtract(1, "day")
              .toDate()
          : moment(member?.memberSince)
              .add(1, "year")
              .subtract(1, "day")
              .toDate(),
      )

      memberRenewConfirmForm.setFieldsValue({
        memberId: member?.memberId,
        activeUntil: isMobile
          ? member?.activeUntil
            ? moment(member?.activeUntil)
                .add(1, "year")
                .subtract(1, "day")
                .format("YYYY-MM-DD")
            : moment(member?.memberSince)
                .add(1, "year")
                .subtract(1, "day")
                .format("YYYY-MM-DD")
          : member?.activeUntil
            ? moment(member?.activeUntil).add(1, "year").subtract(1, "day")
            : moment(member?.memberSince).add(1, "year").subtract(1, "day"),
      })
    }

    setIsMemberRenewConfirmModalVisible(true)
  }

  const hideMemberRenewConfirmModal = () => {
    setConfirmMember({})
    setIsMemberVisit(false)
    setIsMemberRenewConfirmModalVisible(false)
  }

  const memberColumns = [
    {
      title: "ID",
      dataIndex: "memberId",
      align: "center",
      width: 50,
      sorter: () => {},
    },
    {
      title: "写真",
      dataIndex: "picUrl",
      align: "center",
      width: 70,
      render: (picUrl) =>
        picUrl !== "" && picUrl !== null ? (
          <Image
            preview={{
              mask: <EyeOutlined />,
              src: picUrl,
              maskClassName: "rounded-full",
            }}
            width={50}
            height={50}
            className="rounded-full"
            src={`${picUrl}/small`}
            fallback="/no-image.png"
          />
        ) : (
          <Image
            src="/no-image.png"
            width={50}
            height={50}
            className="rounded-full"
            preview={{
              mask: <EyeOutlined />,
              src: "/no-image.png",
              maskClassName: "rounded-full",
            }}
          />
        ),
    },
    {
      title: "LINE名",
      dataIndex: "displayName",
      align: "center",
    },
    {
      title: "氏名",
      dataIndex: "fullName",
      align: "center",
    },
    // {
    //   title: "年齢",
    //   dataIndex: "dateOfBirth",
    //   align: "center",
    //   width: 100,
    //   sorter: () => {},
    //   render: (dateOfBirth) => (
    //     <>
    //       {dateOfBirth
    //         ? moment().diff(moment(dateOfBirth, "YYYY-MM-DD"), "year")
    //         : "ー"}
    //       才
    //     </>
    //   ),
    // },
    // {
    //   title: "性別",
    //   dataIndex: "gender",
    //   align: "center",
    //   width: 100,
    // },
    {
      title: "入会日",
      dataIndex: "memberSince",
      align: "center",
      width: 150,
      sorter: () => {},
      render: (memberSince) => (
        <>{memberSince ? moment(memberSince).format("YYYY/M/D") : "ー"}</>
      ),
    },
    {
      title: "来店回数",
      dataIndex: "countVisit",
      align: "center",
      width: 150,
      render: (countVisit) => <>{countVisit || "ー"}回</>,
    },
    {
      title: "来店日（最後）",
      dataIndex: "lastVisit",
      align: "center",
      width: 150,
      render: (lastVisit) => (
        <>{lastVisit ? moment(lastVisit).format("YYYY/M/D") : "ー"}</>
      ),
    },
    {
      title: "有効期限",
      dataIndex: "activeUntil",
      align: "center",
      width: 150,
      sorter: () => {},
      render: (activeUntil) => (
        <>
          {activeUntil ? (
            <Tag
              color={
                moment(activeUntil).isAfter(moment(), "day") ? "green" : "red"
              }
              className="mr-0 p-2"
            >
              {moment(activeUntil).format("YYYY/M/D")}
            </Tag>
          ) : (
            "ー"
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      align: "center",
      width: 300,
      render: (member) => (
        <>
          <Tooltip title="詳細" placement="top">
            <Button
              size="large"
              className="m-1"
              icon={<EyeOutlined />}
              onClick={() => {
                showMemberDetailModal(member?.memberId)
              }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="更新" placement="top">
            <Button
              size="large"
              className="m-1"
              icon={<HistoryOutlined />}
              onClick={() => {
                showMemberRenewModal(member?.memberId)
              }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="変更" placement="top">
            <Button
              size="large"
              className="m-1"
              icon={<EditOutlined />}
              onClick={() => {
                showMemberEditModal(member?.memberId)
              }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="削除" placement="top">
            <Button
              size="large"
              className="m-1"
              icon={<DeleteOutlined />}
              danger
              onClick={() => {
                handleMemberDelete(member)
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ]

  const visitColumns = [
    {
      title: "登録日付",
      dataIndex: "processedAt",
      align: "center",
      render: (processedAt) =>
        processedAt ? moment(processedAt).format("YYYY/M/D HH:mm") : "ー",
    },
    {
      title: "ポイント",
      dataIndex: "pointCount",
      align: "center",
      render: (pointCount) => (
        <span>
          {pointCount > 0 && <span>+</span>}
          {pointCount}
        </span>
      ),
    },
  ]

  const handleTableChange = (paginationData, filters, sorter) => {
    if (paginationData.pageSize !== paginationPerPage) {
      setPaginationPerPage(paginationData.pageSize)
    }

    if (paginationData.current !== paginationPage) {
      setPaginationPage(paginationData.current)
    }

    if (sorter && sorter.order) {
      if (sorter.order === "ascend") {
        if (sorter.order !== paginationSort) {
          setPaginationSort("asc")
        }
      } else if (sorter.order === "descend") {
        if (sorter.order !== paginationSort) {
          setPaginationSort("desc")
        }
      }
    }

    if (sorter && sorter.field) {
      if (sorter.field !== paginationSortKey) {
        setPaginationSortKey(sorter.field)
      }
    }
  }

  const handleFilter = (data) => {
    setFilters({
      displayName: data.displayNameFilter,
      fullName: data.fullNameFilter,
      furiganaName: data.furiganaFilter,
      countVisitMin: data.countVisitMinFilter,
      countVisitMax: data.countVisitMaxFilter,
      lastVisitMin: data.lastVisitMinFilter
        ? moment(data.lastVisitMinFilter).format("YYYY-MM-DD")
        : data.lastVisitMinFilter,
      lastVisitMax: data.lastVisitMaxFilter
        ? moment(data.lastVisitMaxFilter).format("YYYY-MM-DD")
        : data.lastVisitMaxFilter,
      lineFriend: data.lineFriendFilter,
      petBirthDayMin: data.petBirthDayMinFilter
        ? moment(data.petBirthDayMinFilter).format("YYYY-MM-DD")
        : data.petBirthDayMinFilter,
      petBirthDayMax: data.petBirthDayMaxFilter
        ? moment(data.petBirthDayMaxFilter).format("YYYY-MM-DD")
        : data.petBirthDayMaxFilter,
      petBirthMonth: data.petBirthMonthFilter,
      region: data.regionFilter,
      petSize: data.petSizeFilter,
      petGender: data.petGenderFilter,
    })

    // if (data.genderFilter !== filterGender) {
    //   setFilterGender(data.genderFilter)
    // }
    // if (data.ageMinFilter !== filterAgeMin) {
    //   setFilterAgeMin(data.ageMinFilter)
    // }
    // if (data.ageMaxFilter !== filterAgeMax) {
    //   setFilterAgeMax(data.ageMaxFilter)
    // }
    // if (data.telephoneFilter !== filterTelephone) {
    //   setFilterTelephone(data.telephoneFilter)
    // }
    // if (data.occupationFilter !== filterOccupation) {
    //   setFilterOccupation(data.occupationFilter)
    // }
    // if (data.addressFilter !== filterAddress) {
    //   setFilterAddress(data.addressFilter)
    // }
  }

  const clearFilter = () => {
    // setFilterGender(undefined)
    // setFilterAgeMin(undefined)
    // setFilterAgeMax(undefined)
    // setFilterTelephone(undefined)
    // setFilterOccupation(undefined)
    // setFilterAddress(undefined)

    setFilters({
      displayName: undefined,
      fullName: undefined,
      furiganaName: undefined,
      countVisitMin: undefined,
      countVisitMax: undefined,
      lastVisitMin: undefined,
      lastVisitMax: undefined,
      lineFriend: undefined,
      petBirthDayMin: undefined,
      petBirthDayMax: undefined,
      petBirthMonth: undefined,
      region: undefined,
      petSize: undefined,
      petGender: undefined,
    })

    memberFilterForm.resetFields()
  }

  return (
    <>
      <Card
        title={`会員管理：${store.name || ""}`}
        bordered={false}
        extra={
          <>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                showMemberRenewSelectModal(true)
              }}
              className="m-1"
            >
              来店記録
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              size="large"
              onClick={() => {
                showMemberRenewSelectModal()
              }}
              className="m-1"
            >
              会員更新
            </Button>
          </>
        }
      >
        <Row>
          <Col xs={24}>
            <Form
              form={memberFilterForm}
              name="memberFilterForm"
              onFinish={handleFilter}
              size="large"
              initialValues={{
                displayNameFilter: undefined,
                fullNameFilter: undefined,
                // genderFilter: undefined,
                // ageMinFilter: undefined,
                // ageMaxFilter: undefined,
                countVisitMinFilter: undefined,
                countVisitMaxFilter: undefined,
                lastVisitMinFilter: undefined,
                lastVisitMaxFilter: undefined,
                // telephoneFilter: undefined,
                // occupationFilter: undefined,
                // addressFilter: undefined,
                lineFriendFilter: undefined,
                petBirthDayMinFilter: undefined,
                petBirthDayMaxFilter: undefined,
                petBirthMonthFilter: undefined,
                regionFilter: undefined,
                petSizeFilter: undefined,
                petGenderFilter: undefined,
              }}
            >
              <Row gutter={[8, 8]} justify="end" className="mb-4">
                <Col>
                  <Button onClick={clearFilter}>フィルタークリア</Button>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify="start">
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    help="LINE名"
                    name="displayNameFilter"
                    className="mb-4"
                  >
                    <Input placeholder="例：ヤマダ" allowClear />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item help="氏名" name="fullNameFilter" className="mb-4">
                    <Input placeholder="例：山田" allowClear />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    help="氏名（フリガナ）"
                    name="furiganaFilter"
                    className="mb-4"
                  >
                    <Input placeholder="例：ヤマダ" allowClear />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    help="LINE友だち"
                    name="lineFriendFilter"
                    className="mb-4"
                  >
                    <Select placeholder="LINE友だち状態" allowClear>
                      <Option value={1}>LINE友だちしている</Option>
                      <Option value={0}>LINE友だちしてない</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item help="性別" name="genderFilter" className="mb-4">
                    <Select placeholder="性別を選択してください" allowClear>
                      {COMMONS.GENDER.map((gender) => (
                        <Option key={gender.value} value={gender.value}>
                          {gender.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    help="最低年齢"
                    name="ageMinFilter"
                    className="mb-4"
                  >
                    <InputNumber
                      placeholder="例：18"
                      addonAfter="才"
                      min={1}
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    help="最大年齢"
                    name="ageMaxFilter"
                    className="mb-4"
                  >
                    <InputNumber
                      placeholder="例：20"
                      addonAfter="才"
                      min={1}
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    help="電話番号"
                    name="telephoneFilter"
                    className="mb-4"
                  >
                    <Input placeholder="例：080-3641-2545" allowClear />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    help="職業"
                    name="occupationFilter"
                    className="mb-4"
                  >
                    <Input placeholder="例：社会人" allowClear />
                  </Form.Item>
                </Col> */}
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 3 }}
                  xl={{ span: 3 }}
                >
                  <Form.Item
                    help="最低来店回数"
                    name="countVisitMinFilter"
                    className="mb-4"
                  >
                    <InputNumber
                      placeholder="例：1"
                      addonAfter="回"
                      min={1}
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 3 }}
                  xl={{ span: 3 }}
                >
                  <Form.Item
                    help="最大来店回数"
                    name="countVisitMaxFilter"
                    className="mb-4"
                  >
                    <InputNumber
                      placeholder="例：5"
                      addonAfter="回"
                      min={1}
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 3 }}
                  xl={{ span: 3 }}
                >
                  <Form.Item
                    help="来店日から"
                    name="lastVisitMinFilter"
                    className="mb-4"
                  >
                    <CustomDatePicker className="w-full" inputReadOnly />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 3 }}
                  xl={{ span: 3 }}
                >
                  <Form.Item
                    help="来店日まで"
                    name="lastVisitMaxFilter"
                    className="mb-4 w-full"
                  >
                    <CustomDatePicker className="w-full" inputReadOnly />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 3 }}
                  xl={{ span: 3 }}
                >
                  <Form.Item
                    help="ペット誕生日から"
                    name="petBirthDayMinFilter"
                    className="mb-4 w-full"
                  >
                    <CustomDatePicker className="w-full" inputReadOnly />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 3 }}
                  xl={{ span: 3 }}
                >
                  <Form.Item
                    help="ペット誕生日まで"
                    name="petBirthDayMaxFilter"
                    className="mb-4"
                  >
                    <CustomDatePicker className="w-full" inputReadOnly />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    help="ペット誕生月"
                    name="petBirthMonthFilter"
                    className="mb-4"
                  >
                    <Select placeholder="誕生月を選択" allowClear>
                      <Option value={1}>１月</Option>
                      <Option value={2}>２月</Option>
                      <Option value={3}>３月</Option>
                      <Option value={4}>４月</Option>
                      <Option value={5}>５月</Option>
                      <Option value={6}>６月</Option>
                      <Option value={7}>７月</Option>
                      <Option value={8}>８月</Option>
                      <Option value={9}>９月</Option>
                      <Option value={10}>１０月</Option>
                      <Option value={11}>１１月</Option>
                      <Option value={12}>１２月</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    help="飼い主住所"
                    name="regionFilter"
                    className="mb-4"
                  >
                    <Select
                      options={COMMONS.PREFECTURAL_REGIONS}
                      placeholder={COMMONS.SELECT_REGION_PLACEHOLDER_TEXT}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    help="ペットのサイズ"
                    name="petSizeFilter"
                    className="mb-4"
                    valuePropName="checked"
                  >
                    <Checkbox.Group options={COMMONS.PET_SIZE} />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Form.Item
                    help="ペットの性別"
                    name="petGenderFilter"
                    className="mb-4"
                    valuePropName="checked"
                  >
                    <Checkbox.Group options={COMMONS.PET_GENDER} />
                  </Form.Item>
                </Col>
                {/* <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item
                    help="郵便番号・都道府県・住所"
                    name="addressFilter"
                    className="mb-4"
                  >
                    <Input placeholder="例：愛知県名古屋○○" allowClear />
                  </Form.Item>
                </Col> */}
              </Row>
              <Row gutter={[8, 8]} className="mt-4" justify="center">
                <Col>
                  <Button type="primary" htmlType="submit" className="px-12">
                    検索
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Divider />
          <Col xs={24}>
            <Table
              bordered
              size="large"
              columns={memberColumns}
              scroll={{ x: "max-content" }}
              locale={{ emptyText: "会員がありません" }}
              onChange={handleTableChange}
              pagination={{
                responsive: true,
                current: paginationPage,
                pageSize: paginationPerPage,
                total: paginationTotal,
                showTotal: (total, range) =>
                  `全${total}件中${range[0]}～${range[1]}件目`,
                defaultCurrent: 1,
                defaultPageSize: 20,
                position: ["bottomCenter"],
              }}
              dataSource={
                members
                  ? members.map((member) => {
                      return {
                        key: member.memberId,
                        memberId: member.memberId,
                        picUrl: member.picUrl,
                        displayName: member.displayName,
                        fullName: `${member.lastName || "ー"} ${
                          member.firstName || "ー"
                        }`,
                        // dateOfBirth: member.dateOfBirth,
                        // gender: COMMONS.GET_GENDER_BY_VALUE(member.gender),
                        memberSince: member.memberSince,
                        countVisit: member.countVisit,
                        lastVisit: member.lastVisit,
                        activeUntil: member.activeUntil,
                        action: member,
                      }
                    })
                  : []
              }
            />
          </Col>
        </Row>
      </Card>
      <Modal
        title="会員更新"
        visible={isMemberRenewModalVisible}
        onCancel={hideMemberRenewModal}
        footer={null}
        destroyOnClose
        centered
      >
        <Row>
          <Col span={24}>
            <div className="text-center mb-4">
              {currentMember?.picUrl !== "" &&
              currentMember?.picUrl !== null ? (
                <Image
                  preview={{
                    mask: <EyeOutlined />,
                    src: currentMember.picUrl,
                    maskClassName: "rounded-full",
                  }}
                  width={100}
                  height={100}
                  className="rounded-full"
                  src={`${currentMember.picUrl}/large`}
                  fallback="/no-image.png"
                />
              ) : (
                <Image
                  src="/no-image.png"
                  width={100}
                  height={100}
                  className="rounded-full"
                  preview={{
                    mask: <EyeOutlined />,
                    src: "/no-image.png",
                    maskClassName: "rounded-full",
                  }}
                />
              )}
            </div>
            <p className="text-center font-bold text-xl">
              {currentMember?.lastName || "ー"}{" "}
              {currentMember?.firstName || "ー"}様
            </p>
            <p className="text-center text-sm">
              （{currentMember?.lastNameKana || "ー"}{" "}
              {currentMember?.firstNameKana || "ー"}）
            </p>
            <Divider>会員情報</Divider>
            <Descriptions column={1} layout="horizontal" bordered>
              <Descriptions.Item label="会員ID" className="text-center">
                {currentMember?.memberId || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="会員コード" className="text-center">
                {currentMember?.memberCode || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="入会日" className="text-center">
                {currentMember?.memberSince
                  ? moment(currentMember.memberSince).format("YYYY/M/D")
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="来店回数" className="text-center">
                {currentMember?.memberVisits &&
                currentMember?.memberVisits.length > 0
                  ? currentMember.memberVisits.length
                  : "ー"}
                回
              </Descriptions.Item>
              <Descriptions.Item label="来店日（最後）" className="text-center">
                {currentMember?.lastVisit
                  ? moment(currentMember.lastVisit).format("YYYY/M/D")
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="有効期限" className="text-center">
                {currentMember?.activeUntil ? (
                  <Tag
                    color={
                      moment(currentMember.activeUntil).isAfter(moment(), "day")
                        ? "green"
                        : "red"
                    }
                    className="mr-0"
                  >
                    {moment(currentMember.activeUntil).format("YYYY/M/D")}
                  </Tag>
                ) : (
                  "ー"
                )}
              </Descriptions.Item>
              {/* <Descriptions.Item label="ポイント" className="text-center">
                <span
                  className="font-bold"
                  style={{ color: publicSettings?.PRIMARY_COLOR }}
                >
                  {currentMember?.point ?? "ー"}pt
                </span>
              </Descriptions.Item> */}
            </Descriptions>
          </Col>
          <Divider>会員更新</Divider>
          <Col span={24}>
            <Form
              name="memberRenewForm"
              form={memberRenewForm}
              onFinish={handleMemberRenew}
              layout="vertical"
              preserve={false}
              size="large"
            >
              <Row justify="center">
                <Col>
                  <Form.Item name="memberId" hidden>
                    <Input />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="activeUntil"
                    label="有効期限更新"
                    rules={[
                      {
                        required: true,
                        message: "必須です",
                      },
                    ]}
                  >
                    {isMobile ? (
                      <Input
                        placeholder="例：有効期限を選択してください"
                        readOnly
                        className="cursor-pointer"
                        onPressEnter={(e) => e.preventDefault()}
                        onClick={() => {
                          setIsRenewPickerOpen(true)
                        }}
                      />
                    ) : (
                      <CustomDatePicker
                        style={{ width: "280px" }}
                        disabledDate={(currentDate) =>
                          moment().isSameOrAfter(currentDate, "day")
                        }
                        inputReadOnly
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24} className="text-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={memberRenewMutation.isLoading}
                    className="px-12"
                  >
                    更新
                  </Button>
                </Col>
              </Row>
            </Form>
            {isMobile ? (
              <DatePicker
                isOpen={isRenewPickerOpen}
                value={renewPickerValue}
                theme="ios"
                confirmText="確定"
                cancelText="キャンセル"
                min={moment().toDate()}
                onSelect={(time) => {
                  setIsRenewPickerOpen(false)

                  memberRenewForm.setFieldsValue({
                    activeUntil: moment(time).format("YYYY-MM-DD"),
                  })

                  setRenewPickerValue(time)
                }}
                onCancel={() => {
                  setIsRenewPickerOpen(false)
                }}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Modal>
      <Modal
        title="会員変更"
        visible={isMemberEditModalVisible}
        onCancel={hideMemberEditModal}
        footer={null}
        destroyOnClose
        centered
      >
        <Row>
          <Col span={24}>
            <div className="text-center mb-4">
              {currentMember?.picUrl !== "" &&
              currentMember?.picUrl !== null ? (
                <Image
                  preview={{
                    mask: <EyeOutlined />,
                    src: currentMember.picUrl,
                    maskClassName: "rounded-full",
                  }}
                  width={100}
                  height={100}
                  className="rounded-full"
                  src={`${currentMember.picUrl}/large`}
                  fallback="/no-image.png"
                />
              ) : (
                <Image
                  src="/no-image.png"
                  width={100}
                  height={100}
                  className="rounded-full"
                  preview={{
                    mask: <EyeOutlined />,
                    src: "/no-image.png",
                    maskClassName: "rounded-full",
                  }}
                />
              )}
            </div>
            <p className="text-center font-bold text-xl">
              {currentMember?.lastName || "ー"}{" "}
              {currentMember?.firstName || "ー"}様
            </p>
            <p className="text-center text-sm">
              （{currentMember?.lastNameKana || "ー"}{" "}
              {currentMember?.firstNameKana || "ー"}）
            </p>
            <Divider>会員情報</Divider>
            <Descriptions column={1} layout="horizontal" bordered>
              <Descriptions.Item label="会員ID" className="text-center">
                {currentMember?.memberId || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="会員コード" className="text-center">
                {currentMember?.memberCode || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="入会日" className="text-center">
                {currentMember?.memberSince
                  ? moment(currentMember.memberSince).format("YYYY/M/D")
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="来店回数" className="text-center">
                {currentMember?.memberVisits &&
                currentMember?.memberVisits.length > 0
                  ? currentMember.memberVisits.length
                  : "ー"}
                回
              </Descriptions.Item>
              <Descriptions.Item label="来店日（最後）" className="text-center">
                {currentMember?.lastVisit
                  ? moment(currentMember.lastVisit).format("YYYY/M/D")
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="有効期限" className="text-center">
                {currentMember?.activeUntil ? (
                  <Tag
                    color={
                      moment(currentMember.activeUntil).isAfter(moment(), "day")
                        ? "green"
                        : "red"
                    }
                    className="mr-0"
                  >
                    {moment(currentMember.activeUntil).format("YYYY/M/D")}
                  </Tag>
                ) : (
                  "ー"
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="オンラインショップP"
                className="text-center"
              >
                <span
                  className="font-bold"
                  style={{ color: publicSettings?.PRIMARY_COLOR }}
                >
                  {currentMember?.point ?? "ー"}pt
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label="販売会・イベントP"
                className="text-center"
              >
                <span
                  className="font-bold"
                  style={{ color: publicSettings?.PRIMARY_COLOR }}
                >
                  {currentMember?.kakeruPoint ?? "ー"}pt
                </span>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Divider>会員変更</Divider>
          <Col span={24}>
            <Form
              name="memberEditForm"
              form={memberEditForm}
              onFinish={handleMemberEdit}
              layout="vertical"
              preserve={false}
              size="large"
              initialValues={{
                kakeruPointIsAdd: true,
              }}
            >
              <Row justify="center">
                <Col>
                  <Form.Item name="memberId" hidden>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="memberSince"
                    label="入会日"
                    rules={[
                      {
                        required: true,
                        message: "必須です",
                      },
                    ]}
                  >
                    {isMobile ? (
                      <Input
                        placeholder="例：入会日を選択してください"
                        readOnly
                        className="cursor-pointer"
                        onPressEnter={(e) => e.preventDefault()}
                        onClick={() => {
                          setIsEditPickerOpen(true)
                        }}
                      />
                    ) : (
                      <CustomDatePicker
                        className="w-full"
                        disabledDate={(currentDate) =>
                          moment().isBefore(currentDate, "day")
                        }
                        inputReadOnly
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="kakeruPoint"
                    label="販売会・イベントP"
                    rules={[
                      {
                        required: true,
                        message: "必須です",
                      },
                    ]}
                  >
                    <InputNumber
                      inputMode="numeric"
                      min={0}
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="flex justify-center">
                  <Form.Item
                    name="kakeruPointIsAdd"
                    className="flex justify-center"
                    valuePropName="checked"
                  >
                    <Switch
                      size="large"
                      checkedChildren="ポイント付与"
                      unCheckedChildren="ポイント消費"
                      defaultChecked
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="text-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={memberEditMutation.isLoading}
                    className="px-12"
                  >
                    変更
                  </Button>
                </Col>
              </Row>
            </Form>
            {isMobile ? (
              <DatePicker
                isOpen={isEditPickerOpen}
                value={editPickerValue}
                theme="ios"
                confirmText="確定"
                cancelText="キャンセル"
                max={moment().add(1, "day").toDate()}
                onSelect={(time) => {
                  setIsEditPickerOpen(false)

                  memberEditForm.setFieldsValue({
                    memberSince: moment(time).format("YYYY-MM-DD"),
                  })

                  setEditPickerValue(time)
                }}
                onCancel={() => {
                  setIsEditPickerOpen(false)
                }}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Modal>
      <Modal
        title="会員詳細"
        visible={isMemberDetailModalVisible}
        onCancel={hideMemberDetailModal}
        footer={null}
        destroyOnClose
        centered
      >
        <Row justify="center">
          <Col span={24}>
            <div className="text-center mb-4">
              {currentMember?.picUrl !== "" &&
              currentMember?.picUrl !== null ? (
                <Image
                  preview={{
                    mask: <EyeOutlined />,
                    src: currentMember.picUrl,
                    maskClassName: "rounded-full",
                  }}
                  width={100}
                  height={100}
                  className="rounded-full"
                  src={`${currentMember.picUrl}/large`}
                  fallback="/no-image.png"
                />
              ) : (
                <Image
                  src="/no-image.png"
                  width={100}
                  height={100}
                  className="rounded-full"
                  preview={{
                    mask: <EyeOutlined />,
                    src: "/no-image.png",
                    maskClassName: "rounded-full",
                  }}
                />
              )}
            </div>
            <p className="text-center font-bold text-xl">
              {currentMember?.lastName || "ー"}{" "}
              {currentMember?.firstName || "ー"}様
            </p>
            <p className="text-center text-sm">
              （{currentMember?.lastNameKana || "ー"}{" "}
              {currentMember?.firstNameKana || "ー"}）
            </p>
            <Divider>会員情報</Divider>
            <Descriptions column={1} layout="horizontal" bordered>
              <Descriptions.Item label="会員ID" className="text-center">
                {currentMember?.memberId || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="会員コード" className="text-center">
                {currentMember?.memberCode || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="入会日" className="text-center">
                {currentMember?.memberSince
                  ? moment(currentMember.memberSince).format("YYYY/M/D")
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="来店回数" className="text-center">
                {currentMember?.memberVisits &&
                currentMember?.memberVisits.length > 0
                  ? currentMember.memberVisits.length
                  : "ー"}
                回
              </Descriptions.Item>
              <Descriptions.Item label="来店日（最後）" className="text-center">
                {currentMember?.lastVisit
                  ? moment(currentMember.lastVisit).format("YYYY/M/D")
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="有効期限" className="text-center">
                {currentMember?.activeUntil ? (
                  <Tag
                    color={
                      moment(currentMember.activeUntil).isAfter(moment(), "day")
                        ? "green"
                        : "red"
                    }
                    className="mr-0"
                  >
                    {moment(currentMember.activeUntil).format("YYYY/M/D")}
                  </Tag>
                ) : (
                  "ー"
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="オンラインショップP"
                className="text-center"
              >
                <span
                  className="font-bold"
                  style={{ color: publicSettings?.PRIMARY_COLOR }}
                >
                  {currentMember?.point ?? "ー"}pt
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label="販売会・イベントP"
                className="text-center"
              >
                <span
                  className="font-bold"
                  style={{ color: publicSettings?.PRIMARY_COLOR }}
                >
                  {currentMember?.kakeruPoint ?? "ー"}pt
                </span>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Divider>個人情報</Divider>
          <Col span={24}>
            <Descriptions column={1} layout="horizontal" bordered>
              <Descriptions.Item label="LINE名" className="text-center">
                {currentMember?.displayName || "ー"}
              </Descriptions.Item>
              {/* <Descriptions.Item label="職業" className="text-center">
                {currentMember?.occupation || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="性別" className="text-center">
                {COMMONS.GET_GENDER_BY_VALUE(currentMember?.gender)}
              </Descriptions.Item>
              <Descriptions.Item label="年齢" className="text-center">
                {currentMember?.dateOfBirth
                  ? moment().diff(
                      moment(currentMember.dateOfBirth, "YYYY-MM-DD"),
                      "year"
                    )
                  : "ー"}
                才
              </Descriptions.Item>
              <Descriptions.Item label="電話番号" className="text-center">
                {currentMember?.telephone || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="住所" className="text-center">
                〒
                {`${
                  currentMember?.zipPostal
                    ? COMMONS.POSTAL_CODE_INSERT_CHARACTER(
                        currentMember.zipPostal,
                        3,
                        "-"
                      )
                    : "ー"
                } ${currentMember?.prefecture || ""}${
                  currentMember?.city || ""
                }${currentMember?.address || ""}`}
              </Descriptions.Item> */}
            </Descriptions>
          </Col>
          {currentMember?.memberPets &&
          currentMember?.memberPets?.length > 0 ? (
            <>
              <Divider>ペットの情報</Divider>
              <Col span={24}>
                <div className="mb-4 flex justify-center">
                  {currentMember?.coverUrl !== "" &&
                  currentMember?.coverUrl !== null ? (
                    <Image
                      preview={{
                        mask: <EyeOutlined />,
                        src: `${API.UPLOADS_URL}${currentMember.coverUrl}`,
                        maskClassName: "rounded-lg",
                      }}
                      style={{ maxHeight: "100px" }}
                      className="w-full max-w-full rounded-lg"
                      src={`${API.UPLOADS_URL}${currentMember.coverUrl}`}
                      fallback="/no-image.png"
                    />
                  ) : (
                    <Image
                      src="/no-image.png"
                      style={{ maxHeight: "100px" }}
                      className="w-full max-w-full rounded-lg"
                      preview={{
                        mask: <EyeOutlined />,
                        src: "/no-image.png",
                        maskClassName: "rounded-lg",
                      }}
                    />
                  )}
                </div>
                <Descriptions column={1} layout="horizontal" bordered>
                  {currentMember.memberPets.map((pet) => (
                    <Descriptions.Item
                      key={pet?.petId}
                      label={
                        <p className="text-base font-bold">{`${
                          pet?.name || "ー"
                        }（${
                          pet?.birthday
                            ? moment().diff(moment(pet.birthday), "years")
                            : "ー"
                        }歳）`}</p>
                      }
                      className="text-center"
                    >
                      <p className="text-sm">
                        {pet?.birthday
                          ? moment(pet.birthday).format("YYYY年M月D日")
                          : "ー年ー月ー日"}
                      </p>
                      <Divider />
                      <p className="text-sm">
                        {COMMONS.GET_PET_SIZE_BY_VALUE(pet?.size)}・
                        {COMMONS.GET_PET_GENDER_BY_VALUE(pet?.gender)}
                      </p>
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </Col>
            </>
          ) : (
            ""
          )}
          <Divider>ポイント履歴</Divider>
          <Col span={24}>
            <Table
              bordered
              size="large"
              columns={visitColumns}
              dataSource={
                currentMember?.kakeruPoints
                  ? currentMember.kakeruPoints.map((kakerupoint) => {
                      return {
                        key: kakerupoint.kakeruPointId,
                        processedAt: kakerupoint.processedAt,
                        pointCount: kakerupoint.point,
                      }
                    })
                  : []
              }
              locale={{ emptyText: "履歴がありません" }}
              pagination={{
                responsive: true,
                defaultPageSize: 20,
                position: ["bottomCenter"],
              }}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="center">
          <Col>
            <Button
              size="large"
              className="px-12"
              onClick={hideMemberDetailModal}
            >
              閉じる
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        title={isMemberVisit ? "来店記録" : "会員更新"}
        visible={isMemberRenewSelectModalVisible}
        onCancel={hideMemberRenewSelectModal}
        footer={null}
        destroyOnClose
        centered
      >
        <Row justify="center" gutter={[16, 16]}>
          <Col>
            <Button
              type="primary"
              size="large"
              style={{ height: "100px", width: "160px" }}
              icon={<BarcodeOutlined />}
              onClick={showMemberRenewDeviceModal}
            >
              装置と手入力
            </Button>
          </Col>
          {isMobile ? (
            <Col>
              <Button
                type="primary"
                size="large"
                style={{ height: "100px", width: "160px" }}
                icon={<CameraOutlined />}
                onClick={showMemberRenewCameraModal}
              >
                カメラ
              </Button>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Divider />
        <Row justify="center">
          <Col>
            <Button
              className="px-12"
              size="large"
              onClick={hideMemberRenewSelectModal}
            >
              閉じる
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        title={`${isMemberVisit ? "来店記録" : "会員更新"}・装置と手入力`}
        visible={isMemberRenewDeviceModalVisible}
        onCancel={hideMemberRenewDeviceModal}
        footer={null}
        destroyOnClose
        centered
      >
        <Form
          name="memberRenewDeviceForm"
          form={memberRenewDeviceForm}
          onFinish={handleMemberCheck}
          layout="vertical"
          preserve={false}
          size="large"
        >
          <Row justify="center">
            <Col>
              <Form.Item
                name="memberCode"
                label="会員コード（バーコード）"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "必須です",
                  },
                ]}
              >
                <Input
                  ref={barcodeRef}
                  placeholder="例：000000000000000"
                  allowClear
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="text-center">
              <Button
                type="primary"
                htmlType="submit"
                className="px-12"
                loading={memberCheckMutation.isLoading}
              >
                確認
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={`${isMemberVisit ? "来店記録" : "会員更新"}・カメラ`}
        visible={isMemberRenewCameraModalVisible}
        onCancel={hideMemberRenewCameraModal}
        footer={null}
        destroyOnClose
        centered
      >
        <div className="barcode-wrapper">
          <div className="barcode-image-wrapper">
            <BarcodeScannerComponent
              stopStream={stopCameraStream}
              onUpdate={(err, result) => {
                if (result) {
                  handleMemberCheck({ memberCode: result.text })
                }
              }}
              onError={(error) => {
                if (error.name === "NotAllowedError") {
                  message.info(COMMONS.INFO_CAMERA_PERMISSION_MSG)
                }
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title={`${isMemberVisit ? "来店記録" : "会員更新"}`}
        visible={isMemberRenewConfirmModalVisible}
        onCancel={hideMemberRenewConfirmModal}
        footer={null}
        destroyOnClose
        centered
      >
        <Row>
          <Col span={24}>
            <div className="text-center mb-4">
              {confirmMember?.picUrl !== "" &&
              confirmMember?.picUrl !== null ? (
                <Image
                  preview={{
                    mask: <EyeOutlined />,
                    src: confirmMember.picUrl,
                    maskClassName: "rounded-full",
                  }}
                  width={100}
                  height={100}
                  className="rounded-full"
                  src={`${confirmMember.picUrl}/large`}
                  fallback="/no-image.png"
                />
              ) : (
                <Image
                  src="/no-image.png"
                  width={100}
                  height={100}
                  className="rounded-full"
                  preview={{
                    mask: <EyeOutlined />,
                    src: "/no-image.png",
                    maskClassName: "rounded-full",
                  }}
                />
              )}
            </div>
            <p className="text-center font-bold text-xl">
              {confirmMember?.lastName || "ー"}{" "}
              {confirmMember?.firstName || "ー"}様
            </p>
            <p className="text-center text-sm">
              （{confirmMember?.lastNameKana || "ー"}{" "}
              {confirmMember?.firstNameKana || "ー"}）
            </p>
            <Divider>会員情報</Divider>
            <Descriptions column={1} layout="horizontal" bordered>
              <Descriptions.Item label="会員ID" className="text-center">
                {confirmMember?.memberId || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="会員コード" className="text-center">
                {confirmMember?.memberCode || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="入会日" className="text-center">
                {confirmMember?.memberSince
                  ? moment(confirmMember.memberSince).format("YYYY/M/D")
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="来店回数" className="text-center">
                {confirmMember?.countVisit || "ー"}回
              </Descriptions.Item>
              <Descriptions.Item label="来店日（最後）" className="text-center">
                {confirmMember?.lastVisit
                  ? moment(confirmMember.lastVisit).format("YYYY/M/D")
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="有効期限" className="text-center">
                {confirmMember?.activeUntil ? (
                  <Tag
                    color={
                      moment(confirmMember.activeUntil).isAfter(moment(), "day")
                        ? "green"
                        : "red"
                    }
                    className="mr-0"
                  >
                    {moment(confirmMember.activeUntil).format("YYYY/M/D")}
                  </Tag>
                ) : (
                  "ー"
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="オンラインショップP"
                className="text-center"
              >
                <span
                  className="font-bold"
                  style={{ color: publicSettings?.PRIMARY_COLOR }}
                >
                  {confirmMember?.point ?? "ー"}pt
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label="販売会・イベントP"
                className="text-center"
              >
                {isMemberVisit ? (
                  <span
                    className="font-bold"
                    style={{ color: publicSettings?.PRIMARY_COLOR }}
                  >
                    {confirmMember?.kakeruPoint + 100 ?? "100"}pt
                  </span>
                ) : (
                  <span
                    className="font-bold"
                    style={{ color: publicSettings?.PRIMARY_COLOR }}
                  >
                    {confirmMember?.kakeruPoint ?? "-"}pt
                  </span>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          {isMemberVisit ? (
            <>
              <Divider>来店登録</Divider>
              <Col span={24}>
                <Alert
                  message={
                    <p className="text-center">
                      来店記録＆100ptを付与しました！
                    </p>
                  }
                  type="success"
                />
              </Col>

              <Form
                form={memberPointForm}
                onFinish={handleMemberPointUpdate}
                className="w-full"
                initialValues={{
                  kakeruPointIsAdd: true,
                  memberId: confirmMember?.memberId,
                  memberVisitId: confirmMember?.memberVisitId,
                }}
                layout="vertical"
                preserve={false}
                size="large"
              >
                <Divider>会員変更</Divider>
                <Col>
                  <Form.Item name="memberId" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item hidden name="memberVisitId">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="販売会・イベントP"
                    required
                    name="kakeruPoint"
                  >
                    <InputNumber
                      className="w-full"
                      inputMode="numeric"
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="flex justify-center">
                  <Form.Item name="kakeruPointIsAdd" valuePropName="checked">
                    <Switch
                      className="flex justify-center"
                      size="large"
                      checkedChildren="ポイント付与"
                      unCheckedChildren="ポイント消費"
                      defaultChecked
                    />
                  </Form.Item>
                </Col>
                <Divider />
                <Col span={24} className="text-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="px-12"
                    loading={memberEditMutation.isLoading}
                  >
                    変更
                  </Button>
                </Col>
              </Form>
            </>
          ) : (
            <>
              <Divider>会員更新</Divider>
              <Col span={24}>
                <Form
                  name="memberRenewConfirmForm"
                  form={memberRenewConfirmForm}
                  onFinish={handleMemberRenew}
                  layout="vertical"
                  preserve={false}
                  size="large"
                >
                  <Row justify="center">
                    <Col>
                      <Form.Item name="memberId" hidden>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name="activeUntil"
                        label="有効期限更新"
                        rules={[
                          {
                            required: true,
                            message: "必須です",
                          },
                        ]}
                      >
                        {isMobile ? (
                          <Input
                            placeholder="例：有効期限を選択してください"
                            readOnly
                            className="cursor-pointer"
                            onPressEnter={(e) => e.preventDefault()}
                            onClick={() => {
                              setIsRenewConfirmPickerOpen(true)
                            }}
                          />
                        ) : (
                          <CustomDatePicker
                            style={{ width: "280px" }}
                            disabledDate={(currentDate) =>
                              moment().isSameOrAfter(currentDate, "day")
                            }
                            inputReadOnly
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={24} className="text-center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={memberRenewMutation.isLoading}
                        className="px-12"
                      >
                        更新
                      </Button>
                    </Col>
                  </Row>
                </Form>
                {isMobile ? (
                  <DatePicker
                    isOpen={isRenewConfirmPickerOpen}
                    value={renewConfirmPickerValue}
                    theme="ios"
                    confirmText="確定"
                    cancelText="キャンセル"
                    min={moment().toDate()}
                    onSelect={(time) => {
                      setIsRenewConfirmPickerOpen(false)

                      memberRenewConfirmForm.setFieldsValue({
                        activeUntil: moment(time).format("YYYY-MM-DD"),
                      })

                      setRenewConfirmPickerValue(time)
                    }}
                    onCancel={() => {
                      setIsRenewConfirmPickerOpen(false)
                    }}
                  />
                ) : (
                  ""
                )}
              </Col>
            </>
          )}
        </Row>
      </Modal>
    </>
  )
}

export default Members
