import React, { useState, useEffect } from "react"
import { Layout, Spin } from "antd"
import { useIsFetching, useQuery, useQueryClient } from "react-query"
import { Helmet } from "react-helmet-async"
import { changeAntdTheme } from "mini-dynamic-antd-theme"
import * as COMMONS from "common/common"
import * as API from "common/api"
import { socket } from "common/socket"

const Minimal = (props) => {
  const { children } = props
  const { Content } = Layout
  const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()
  const queryClient = useQueryClient()

  const [logo, setLogo] = useState(undefined)
  const [favicon, setFavicon] = useState(undefined)
  const [publicSettings, setPublicSettings] = useState({
    PRIMARY_COLOR: COMMONS.PRIMARY_COLOR,
    PRIMARY_LIGHT_COLOR: COMMONS.PRIMARY_LIGHT_COLOR,
  })

  useQuery(API.QUERY_KEY_LOGO, () => API.GET_LOGO(), {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data) {
          setLogo(response.data)
        }
      }
    },
  })

  useQuery(API.QUERY_KEY_FAVICON, () => API.GET_FAVICON(), {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data) {
          setFavicon(response.data)
        }
      }
    },
  })

  useQuery(API.QUERY_KEY_PUBLIC_SETTINGS, () => API.GET_PUBLIC_SETTINGS(), {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        if (response?.data) {
          handlePublicSettings(response.data)
        }
      }
    },
  })

  const handlePublicSettings = (data) => {
    changeAntdTheme(
      data[API.SETTINGS_KEY_SYSTEM_COLOR]
        ? data[API.SETTINGS_KEY_SYSTEM_COLOR].settingValueString
        : COMMONS.PRIMARY_COLOR
    )

    setPublicSettings({
      PRIMARY_COLOR: data[API.SETTINGS_KEY_SYSTEM_COLOR]
        ? data[API.SETTINGS_KEY_SYSTEM_COLOR].settingValueString
        : COMMONS.PRIMARY_COLOR,
      PRIMARY_LIGHT_COLOR: data[API.SETTINGS_KEY_SYSTEM_COLOR]
        ? COMMONS.COLOR_ADJUST(
            data[API.SETTINGS_KEY_SYSTEM_COLOR].settingValueString,
            200
          )
        : COMMONS.PRIMARY_LIGHT_COLOR,
      TITLE: data[API.SETTINGS_KEY_SYSTEM_TITLE]
        ? data[API.SETTINGS_KEY_SYSTEM_TITLE].settingValueString
        : "",
    })
  }

  useEffect(() => {
    socket.on("systemSetting", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_PUBLIC_SETTINGS)
      }
    })

    socket.on("favicon", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_FAVICON)
      }
    })

    socket.on("logo", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        queryClient.invalidateQueries(API.QUERY_KEY_LOGO)
      }
    })

    return () => {
      socket.off("systemSetting")
      socket.off("favicon")
      socket.off("logo")
    }

    // eslint-disable-next-line
  }, [])

  const childrenWithProps = React.Children.map(children, (element) =>
    React.cloneElement(element, { logo: logo, publicSettings: publicSettings })
  )

  return (
    <>
      <Helmet>
        {favicon ? <link rel="icon" href={API.UPLOADS_URL + favicon} /> : ""}
        {publicSettings.TITLE ? <title>{publicSettings.TITLE}</title> : ""}
      </Helmet>
      <div className="flex flex-col w-full min-h-full">
        <Spin
          spinning={useIsFetching() > 0 ? true : false}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1001,
          }}
        >
          <Layout className="min-h-screen">
            <Content className="bg-white" key={JSON.stringify(publicSettings)}>
              {childrenWithProps}
            </Content>
          </Layout>
        </Spin>
      </div>
    </>
  )
}

export default Minimal
