import axios from "axios"
// import moment from "moment"

/* BASE URL */
export const API_URL = process.env.REACT_APP_API_URL

export const BASE_URL = API_URL + "/api"
export const UPLOADS_URL = API_URL + "/uploads/"
export const POSTAL_CODE_URL =
  "https://yubinbango.github.io/yubinbango-data/data"

/* BACKEND API URLS */
export const API_ADMIN_CHECK_SESSION = BASE_URL + "/sess"
export const API_ADMIN_LOGIN = BASE_URL + "/login"
export const API_ADMIN_LOGOUT = BASE_URL + "/logout"
export const API_ADMIN_AUTH = BASE_URL + "/m/auth"
export const API_ADMIN_STORES = BASE_URL + "/m/stores"
export const API_ADMIN_SETTINGS = BASE_URL + "/m/settings"
export const API_CLIENT = BASE_URL + "/liff"

/* QUERY KEY */
export const QUERY_KEY_ADMIN_CHECK_SESSION = "adminCheckSession"
export const QUERY_KEY_ADMIN_AUTH = "adminAuth"
export const QUERY_KEY_ADMIN_STORES = "adminStores"
export const QUERY_KEY_ADMIN_STORE = "adminStore"
export const QUERY_KEY_ADMIN_MEMBERS = "adminMembers"
export const QUERY_KEY_ADMIN_MEMBERS_LIST = "adminMembersList"
export const QUERY_KEY_ADMIN_AUDIENCES = "adminAudiences"
export const QUERY_KEY_ADMIN_RICHMENUS = "adminRichmenus"
export const QUERY_KEY_LOGO = "logo"
export const QUERY_KEY_FAVICON = "favicon"
export const QUERY_KEY_PUBLIC_SETTINGS = "publicSettings"
export const QUERY_KEY_CLIENT_PERSONAL_INFO = "clientPersonalInfo"
export const QUERY_KEY_CLIENT_MEMBERSHIP_INFO = "clientMembershipInfo"

/* SETTINGS KEY */
export const SETTINGS_KEY_SYSTEM_COLOR = "systemColor"
export const SETTINGS_KEY_SYSTEM_TITLE = "systemTitle"

/* SETTINGS LABEL */
export const SETTINGS_LABEL_SYSTEM_COLOR = "システム色"
export const SETTINGS_LABEL_SYSTEM_TITLE = "システムタイトル"

/* BACKEND API REQUESTS */
export async function ADMIN_LOGIN(data) {
  return await axiosInstance.post(API_ADMIN_LOGIN, data)
}

export async function ADMIN_LOGOUT() {
  return await axiosInstance.get(API_ADMIN_LOGOUT)
}

export async function ADMIN_CHECK_SESSION() {
  return await axiosInstance.get(API_ADMIN_CHECK_SESSION)
}

export async function ADMIN_GET_AUTH() {
  return await axiosInstance.get(API_ADMIN_AUTH)
}

export async function ADMIN_GET_STORES() {
  return await axiosInstance.get(API_ADMIN_STORES)
}

export async function ADMIN_GET_STORE(storeId) {
  return await axiosInstance.get(API_ADMIN_STORES + "/" + storeId)
}

export async function ADMIN_UPDATE_STORE(data) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  const formData = new FormData()
  formData.append("name", data.name)
  formData.append("picUrl", data.picUrl)
  formData.append("remarks", data.remarks)

  return await axiosInstance.put(
    API_ADMIN_STORES + "/" + data.storeId,
    formData,
    headerData
  )
}

export async function ADMIN_GET_MEMBERS(
  storeId,
  paginationPerPage,
  paginationPage,
  paginationSort,
  paginationSortKey,
  filterDisplayName,
  filterFullName,
  filterFuriganaName,
  // filterGender,
  // filterAgeMin,
  // filterAgeMax,
  filterCountVisitMin,
  filterCountVisitMax,
  filterLastVisitMin,
  filterLastVisitMax,
  // filterTelephone,
  // filterOccupation,
  // filterAddress,
  filterLineFriend,
  filterPetBirthDayMin,
  filterPetBirthDayMax,
  filterPetBirthMonth,
  filterRegion,
  filterPetSize,
  filterPetGender,
) {
  const paramData = {
    params: {
      pp: paginationPerPage,
      p: paginationPage,
      sort: paginationSort,
      sortKey: paginationSortKey,
      displayName: filterDisplayName,
      fullName: filterFullName,
      furiganaName: filterFuriganaName,
      // gender: filterGender,
      // dateOfBirthMin: filterAgeMin
      //   ? moment().subtract(filterAgeMin, "years").format("YYYY-MM-DD")
      //   : undefined,
      // dateOfBirthMax: filterAgeMax
      //   ? moment().subtract(filterAgeMax, "years").format("YYYY-MM-DD")
      //   : undefined,
      countVisitMin: filterCountVisitMin,
      countVisitMax: filterCountVisitMax,
      lastVisitMin: filterLastVisitMin,
      lastVisitMax: filterLastVisitMax,
      // telephone: filterTelephone,
      // occupation: filterOccupation,
      // address: filterAddress,
      isFriends: filterLineFriend,
      petBirthDayMin: filterPetBirthDayMin,
      petBirthDayMax: filterPetBirthDayMax,
      petBirthMonth: filterPetBirthMonth,
      region: filterRegion,
      petSize: filterPetSize && filterPetSize.join(","),
      petGender: filterPetGender && filterPetGender.join(","),
    },
  }

  return await axiosInstance.get(
    API_ADMIN_STORES + "/" + storeId + "/members",
    paramData
  )
}

export async function ADMIN_GET_MEMBERS_LIST(
  storeId,
  // filterGender,
  // filterAgeMin,
  // filterAgeMax,
  filterCountVisitMin,
  filterCountVisitMax,
  filterLastVisitMin,
  filterLastVisitMax,
  // filterOccupation,
  // filterAddress,
  filterPetBirthDayMin,
  filterPetBirthDayMax,
  filterPetBirthMonth,
  filterRegion,
  filterPetSize,
  filterPetGender,
) {
  const paramData = {
    params: {
      // gender: filterGender,
      // dateOfBirthMin: filterAgeMin
      //   ? moment().subtract(filterAgeMin, "years").format("YYYY-MM-DD")
      //   : undefined,
      // dateOfBirthMax: filterAgeMax
      //   ? moment().subtract(filterAgeMax, "years").format("YYYY-MM-DD")
      //   : undefined,
      countVisitMin: filterCountVisitMin,
      countVisitMax: filterCountVisitMax,
      lastVisitMin: filterLastVisitMin,
      lastVisitMax: filterLastVisitMax,
      // occupation: filterOccupation,
      // address: filterAddress,
      petBirthDayMin: filterPetBirthDayMin,
      petBirthDayMax: filterPetBirthDayMax,
      petBirthMonth: filterPetBirthMonth,
      region: filterRegion,
      petSize: filterPetSize && filterPetSize.join(","),
      petGender: filterPetGender && filterPetGender.join(","),
    },
  }

  return await axiosInstance.get(
    API_ADMIN_STORES + "/" + storeId + "/members/list",
    paramData
  )
}

export async function ADMIN_GET_MEMBER(data) {
  return await axiosInstance.get(
    API_ADMIN_STORES + "/" + data.storeId + "/members/" + data.memberId
  )
}

export async function ADMIN_DELETE_MEMBER(data) {
  return await axiosInstance.delete(
    API_ADMIN_STORES + "/" + data.storeId + "/members/" + data.memberId
  )
}

export async function ADMIN_RENEW_MEMBER(data) {
  return await axiosInstance.put(
    API_ADMIN_STORES + "/" + data.storeId + "/members/" + data.memberId,
    data
  )
}

export async function ADMIN_CHECK_MEMBER(data) {
  return await axiosInstance.post(
    API_ADMIN_STORES + "/" + data.storeId + "/members/barcode",
    data
  )
}

export async function ADMIN_GET_AUDIENCES(storeId) {
  return await axiosInstance.get(
    API_ADMIN_STORES + "/" + storeId + "/audiences"
  )
}

export async function ADMIN_CREATE_AUDIENCE(data) {
  return await axiosInstance.post(
    API_ADMIN_STORES + "/" + data.storeId + "/audiences",
    data
  )
}

export async function ADMIN_DELETE_AUDIENCE(data) {
  return await axiosInstance.delete(
    API_ADMIN_STORES + "/" + data.storeId + "/audiences/" + data.audienceGroupId
  )
}

export async function GET_LOGO() {
  return await axiosInstance.get(BASE_URL + "/logo")
}

export async function ADMIN_UPLOAD_LOGO(logo) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  const formData = new FormData()
  formData.append("picUrl", logo)

  return await axiosInstance.put(BASE_URL + "/m/logo", formData, headerData)
}

export async function GET_FAVICON() {
  return await axiosInstance.get(BASE_URL + "/favicon")
}

export async function ADMIN_UPLOAD_FAVICON(favicon) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  const formData = new FormData()
  formData.append("picUrl", favicon)

  return await axiosInstance.put(BASE_URL + "/m/favicon", formData, headerData)
}

export async function GET_PUBLIC_SETTINGS() {
  return await axiosInstance.get(BASE_URL + "/settings")
}

export async function ADMIN_UPDATE_SYSTEM_COLOR(data) {
  const paramData = {
    label: SETTINGS_LABEL_SYSTEM_COLOR,
    isPublic: true,
    valueString: data.value,
  }

  return await axiosInstance.put(
    API_ADMIN_SETTINGS + "/" + SETTINGS_KEY_SYSTEM_COLOR,
    paramData
  )
}

export async function ADMIN_UPDATE_SYSTEM_TITLE(data) {
  const paramData = {
    label: SETTINGS_LABEL_SYSTEM_TITLE,
    isPublic: true,
    valueString: data.value,
  }

  return await axiosInstance.put(
    API_ADMIN_SETTINGS + "/" + SETTINGS_KEY_SYSTEM_TITLE,
    paramData
  )
}

export async function ADMIN_GET_RICHMENUS(data) {
  return await axiosInstance.get(
    API_ADMIN_STORES + "/" + data.storeId + "/richmenus"
  )
}

export async function ADMIN_UPDATE_RICHMENU(data) {
  const headerData = {
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })

  return await axiosInstance.put(
    API_ADMIN_STORES + "/" + data.storeId + "/richmenus",
    formData,
    headerData
  )
}

export async function ADMIN_DELETE_RICHMENU(data) {
  const param = {
    data: {
      type: data.type,
    },
  }

  return await axiosInstance.delete(
    API_ADMIN_STORES + "/" + data.storeId + "/richmenus",
    param
  )
}

export async function CLIENT_CHECK_STORE(data) {
  const headerData = {
    headers: { "access-token": data.accessToken },
  }

  const paramData = {
    liffId: data.liffId,
  }

  return await axiosInstance.post(API_CLIENT + "/store", paramData, headerData)
}

export async function EC_LOGIN(data) {
  const headerData = {
    headers: { "access-token": data.accessToken },
  }
  return await axiosInstance.post(API_CLIENT + "/eclogin", data, headerData)
}

export async function CLIENT_GET_PERSONAL_INFO(accessToken) {
  const headerData = {
    headers: { "access-token": accessToken },
  }

  return await axiosInstance.get(API_CLIENT + "/personal", headerData)
}

export async function CLIENT_GET_MEMBERSHIP_INFO(accessToken) {
  const headerData = {
    headers: { "access-token": accessToken },
  }

  return await axiosInstance.get(API_CLIENT + "/membership", headerData)
}

export async function CLIENT_REGISTER_PROFILE(data) {
  const headerData = {
    headers: {
      "access-token": data.accessToken,
      "content-type": "multipart/form-data",
    },
  }

  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    if (key === "pets") {
      formData.append(key, JSON.stringify(data[key]))
    } else {
      formData.append(key, data[key])
    }
  })

  return await axiosInstance.post(
    API_CLIENT + "/personal",
    formData,
    headerData
  )
}

export async function CLIENT_UPDATE_PROFILE(data) {
  const headerData = {
    headers: {
      "access-token": data.accessToken,
      "content-type": "multipart/form-data",
    },
  }

  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    if (key === "pets") {
      formData.append(key, JSON.stringify(data[key]))
    } else {
      formData.append(key, data[key])
    }
  })

  return await axiosInstance.post(
    API_CLIENT + "/personal",
    formData,
    headerData
  )
}

/* AXIOS CONFIG */
export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
})

export const GET_ADDRESS_BY_POSTAL_CODE = (postalCode) => {
  const p3 = postalCode.substr(0, 3)

  return fetch(`${POSTAL_CODE_URL}/${p3}.js`)
    .then((response) => response.text())
    .then((text) => text)
    .catch((error) => {})
}
