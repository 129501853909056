import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Row, Col, Button, Popover, Avatar } from "antd"
import {
  MenuOutlined,
  UserOutlined,
  ShopTwoTone,
  CaretDownOutlined,
} from "@ant-design/icons"
import styled from "styled-components"
import * as API from "common/api"
import * as COMMONS from "common/common"

const CustomMenuButton = styled(Button)`
  & {
    border: none;
    box-shadow: none;
  }

  &:hover {
    border: none;
    box-shadow: none;
  }

  &:focus {
    border: none;
    box-shadow: none;
  }

  &:active {
    border: none;
    box-shadow: none;
  }
`

const CustomAvatar = styled(Avatar)`
  & {
    line-height: 0 !important;
  }

  & > img {
    object-fit: contain !important;
  }
`

const Topbar = (props) => {
  const {
    isHamburger,
    collapseToggle,
    auth,
    stores,
    currentStore,
    publicSettings,
    setCurrentStore,
  } = props

  const location = useLocation()
  const navigate = useNavigate()

  const [isStoreSelectorVisible, setIsStoreSelectorVisible] = useState(false)

  const getCurrentLocation = () => {
    return location.pathname.slice(0, location.pathname.lastIndexOf("/")) !== ""
      ? location.pathname.slice(0, location.pathname.lastIndexOf("/"))
      : location.pathname
  }

  const handleStore = (id) => {
    setCurrentStore(id)

    switch (getCurrentLocation()) {
      case COMMONS.ADMIN_MEMBERS_ROUTE:
        navigate(COMMONS.ADMIN_MEMBERS_ROUTE + "/" + id)
        break
      case COMMONS.ADMIN_AUDIENCES_ROUTE:
        navigate(COMMONS.ADMIN_AUDIENCES_ROUTE + "/" + id)
        break
      default:
        break
    }

    setIsStoreSelectorVisible(false)
  }

  const handleStoreSelectorVisibility = (visible) => {
    setIsStoreSelectorVisible(visible)
  }

  return (
    <>
      {isHamburger ? (
        <Row align="middle" className="px-5">
          <Col xs={6} md={8} className="text-left cursor-pointer">
            <CustomMenuButton
              type="text"
              icon={<MenuOutlined style={{ fontSize: 18 }} />}
              onClick={collapseToggle}
            />
          </Col>
          <Col xs={12} md={8} className="text-center truncate">
            {currentStore &&
            stores.length > 1 &&
            (getCurrentLocation() === COMMONS.ADMIN_MEMBERS_ROUTE ||
              getCurrentLocation() === COMMONS.ADMIN_AUDIENCES_ROUTE) ? (
              <Popover
                placement="bottom"
                title={""}
                content={
                  <div
                    className="rounded-full bg-white border py-3 px-6 overflow-x-auto overflow-y-hidden whitespace-no-wrap text-center no-scrollbar"
                    style={{
                      borderColor: publicSettings?.PRIMARY_COLOR || COMMONS.PRIMARY_COLOR,
                    }}
                  >
                    {stores.map((store) => (
                      <div
                        key={store.storeId}
                        className="block md:inline-block m-2"
                      >
                        <Row justify="center">
                          <Col
                            onClick={() => handleStore(store.storeId)}
                            className="rounded-full cursor-pointer"
                            style={{
                              borderColor:
                                store.storeId === currentStore
                                  ? publicSettings?.PRIMARY_COLOR || COMMONS.PRIMARY_COLOR
                                  : COMMONS.GRAY_COLOR,
                              borderWidth:
                                store.storeId === currentStore ? "2px" : "1px",
                            }}
                          >
                            {store.picUrl ? (
                              <CustomAvatar
                                size={50}
                                src={`${API.UPLOADS_URL}${store.picUrl}`}
                              />
                            ) : (
                              <Avatar
                                size={50}
                                className="bg-white"
                                icon={
                                  <ShopTwoTone
                                    twoToneColor={
                                      store.storeId === currentStore
                                        ? publicSettings?.PRIMARY_COLOR || COMMONS.PRIMARY_COLOR
                                        : COMMONS.GRAY_COLOR
                                    }
                                  />
                                }
                              />
                            )}
                          </Col>
                        </Row>
                        <Row justify="center">
                          <Col>
                            <span
                              style={{
                                color:
                                  store.storeId === currentStore
                                    ? publicSettings?.PRIMARY_COLOR || COMMONS.PRIMARY_COLOR
                                    : COMMONS.GRAY_COLOR,
                                fontWeight:
                                  store.storeId === currentStore
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {store.name}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                }
                trigger="click"
                overlayInnerStyle={{ borderRadius: "9999px" }}
                visible={isStoreSelectorVisible}
                onVisibleChange={handleStoreSelectorVisibility}
              >
                {stores.map((store) =>
                  store.storeId === currentStore ? (
                    <Button
                      key={store.storeId}
                      className="rounded-full w-full md:w-auto"
                      type="primary"
                      icon={<CaretDownOutlined />}
                    >
                      店舗：{store.name}
                    </Button>
                  ) : (
                    ""
                  )
                )}
              </Popover>
            ) : (
              ""
            )}
          </Col>
          <Col xs={6} md={8}>
            <Row wrap={false} align="middle" justify="end">
              <Col className="rounded-full mr-2">
                <UserOutlined
                  style={{
                    fontSize: "1rem",
                    color: publicSettings?.PRIMARY_COLOR || COMMONS.PRIMARY_COLOR,
                    verticalAlign: "-0.150em",
                  }}
                />
              </Col>
              <Col className="truncate">
                <span className="text-xs font-bold">
                  {(auth.username && auth.username.toUpperCase()) || ""}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row align="middle" className="px-5">
          <Col offset={8} span={8} className="text-center truncate">
            {currentStore &&
            stores.length > 1 &&
            (getCurrentLocation() === COMMONS.ADMIN_MEMBERS_ROUTE ||
              getCurrentLocation() === COMMONS.ADMIN_AUDIENCES_ROUTE) ? (
              <Popover
                placement="bottom"
                title={""}
                content={
                  <div
                    className="rounded-full bg-white border py-3 px-6 overflow-hidden text-center no-scrollbar"
                    style={{
                      borderColor: publicSettings?.PRIMARY_COLOR || COMMONS.PRIMARY_COLOR,
                    }}
                  >
                    {stores.map((store) => (
                      <div key={store.storeId} className="inline-block m-2">
                        <Row justify="center">
                          <Col
                            onClick={() => handleStore(store.storeId)}
                            className="rounded-full cursor-pointer"
                            style={{
                              borderColor:
                                store.storeId === currentStore
                                  ? publicSettings?.PRIMARY_COLOR || COMMONS.PRIMARY_COLOR
                                  : COMMONS.GRAY_COLOR,
                              borderWidth:
                                store.storeId === currentStore ? "2px" : "1px",
                            }}
                          >
                            {store.picUrl ? (
                              <CustomAvatar
                                size={50}
                                src={`${API.UPLOADS_URL}${store.picUrl}`}
                              />
                            ) : (
                              <Avatar
                                size={50}
                                className="bg-white"
                                icon={
                                  <ShopTwoTone
                                    twoToneColor={
                                      store.storeId === currentStore
                                        ? publicSettings?.PRIMARY_COLOR || COMMONS.PRIMARY_COLOR
                                        : COMMONS.GRAY_COLOR
                                    }
                                  />
                                }
                              />
                            )}
                          </Col>
                        </Row>
                        <Row justify="center">
                          <Col>
                            <span
                              style={{
                                color:
                                  store.storeId === currentStore
                                    ? publicSettings?.PRIMARY_COLOR || COMMONS.PRIMARY_COLOR
                                    : COMMONS.GRAY_COLOR,
                                fontWeight:
                                  store.storeId === currentStore
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {store.name}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                }
                trigger="click"
                overlayInnerStyle={{ borderRadius: "9999px" }}
                visible={isStoreSelectorVisible}
                onVisibleChange={handleStoreSelectorVisibility}
              >
                {stores.map((store) =>
                  store.storeId === currentStore ? (
                    <Button
                      key={store.storeId}
                      className="rounded-full"
                      type="primary"
                      icon={<CaretDownOutlined />}
                    >
                      店舗：{store.name}
                    </Button>
                  ) : (
                    ""
                  )
                )}
              </Popover>
            ) : (
              ""
            )}
          </Col>
          <Col span={8}>
            <Row wrap={false} align="middle" justify="end">
              <Col className="rounded-full mr-2">
                <UserOutlined
                  style={{
                    fontSize: "1.25rem",
                    color: publicSettings?.PRIMARY_COLOR,
                    verticalAlign: "-0.150em",
                  }}
                />
              </Col>
              <Col className="truncate">
                <span className="text-xs font-bold">
                  {(auth.username && auth.username.toUpperCase()) || ""}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  )
}

export default Topbar
