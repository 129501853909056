import { Col, Divider, Image, message, Row } from "antd"
import * as API from "common/api"
import * as COMMONS from "common/common"
import { socket } from "common/socket"
import { ClientTopBarComponent } from "components"
import moment from "moment"
import "moment/locale/ja"
import React, { useEffect, useRef, useState } from "react"
import Barcode from "react-barcode"
import { IoPaw } from "react-icons/io5"
import { TbPencil } from "react-icons/tb"
import { useQuery, useQueryClient } from "react-query"
import { useParams, Link } from "react-router-dom"

moment.locale("ja")

const Membership = (props) => {
  const { publicSettings, storeInfo, personalInfo, accessToken } = props
  const { liffId, storeId } = useParams()
  const queryClient = useQueryClient()

  const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()
  const membershipInfoRef = useRef()
  const [membershipInfo, setMembershipInfo] = useState({})

  useQuery(
    [API.QUERY_KEY_CLIENT_MEMBERSHIP_INFO, accessToken],
    () => API.CLIENT_GET_MEMBERSHIP_INFO(accessToken),
    {
      enabled: !!accessToken,
      onSuccess: (response) => {
        if (isMountedRef.current) {
          setMembershipInfo(response?.data || {})
        }
      },
      onError: (error) => {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      },
    }
  )

  useEffect(() => {
    socket.on("member", (response) => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        if (
          response.storeId &&
          response.storeId === parseInt(storeId) &&
          response.memberId &&
          response.memberId === membershipInfoRef.current?.memberId
        ) {
          queryClient.invalidateQueries(API.QUERY_KEY_CLIENT_MEMBERSHIP_INFO)
        }
      }
    })

    return () => {
      socket.off("member")
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    membershipInfoRef.current = membershipInfo
  }, [membershipInfo])

  return (
    <div className="mb-8">
      <div className="relative mb-4">
        {personalInfo?.coverUrl !== "" && personalInfo?.coverUrl !== null ? (
          <Image
            preview={false}
            width={"100%"}
            style={{ maxHeight: "150px" }}
            className="w-full object-cover"
            src={`${API.UPLOADS_URL}${personalInfo.coverUrl}`}
            fallback="/no-image-cover.png"
          />
        ) : (
          <Image
            preview={false}
            width={"100%"}
            style={{ maxHeight: "150px" }}
            className="w-full object-cover"
            src="/no-image-cover.png"
          />
        )}
        <div className="absolute left-1/2 -translate-x-1/2 -bottom-14">
          {personalInfo?.picUrl !== "" && personalInfo?.picUrl !== null ? (
            <Image
              preview={false}
              className="rounded-full w-28 h-28"
              src={`${personalInfo.picUrl}`}
              fallback="/no-image.png"
            />
          ) : (
            <Image
              preview={false}
              className="rounded-full w-28 h-28"
              src="/no-image.png"
            />
          )}
        </div>
      </div>
      <div className="mt-20">
        <ClientTopBarComponent
          publicSettings={publicSettings}
          storeInfo={storeInfo}
        />
      </div>
      <div className="px-6">
        <div className="mt-4 mb-8 rounded-lg bg-white p-2">
          <div className="rounded-lg text-center border border-dashed border-line">
            <p className="m-2 py-4 text-2xl bg-custom-light-gray text-black font-bold">
              {personalInfo?.lastName || ""} {personalInfo?.firstName || ""}様
            </p>
            {moment().isAfter("2022-06-05", "day") ? (
              <div className="mt-4">
                <Barcode
                  value={membershipInfo?.memberCode || "000000000000000"}
                  displayValue={false}
                  height={80}
                  width={2}
                  margin={0}
                />
                <p className="pb-4 text-lg text-black">
                  ID : {membershipInfo?.memberCode || "000000000000000"}
                </p>
              </div>
            ) : (
              <img
                src="/campaign.jpg"
                alt="キャンペーン"
                className="w-full object-contain rounded-lg p-2 max-w-full"
                style={{ maxHeight: "350px" }}
              />
            )}
          </div>
        </div>
        <div>
          <div className="mt-4 mb-2 rounded-lg bg-custom-orange p-2">
            <div className="rounded-lg p-4 flex justify-between w-full ">
              <p className="m-2 py-4 text-base text-white w-11/12">
                オンラインショップで <br /> 利用可能なポイント *
              </p>
              <p className="m-2 py-4 text-2xl text-white align-middle">
                {personalInfo?.point || "0"}pt
              </p>
            </div>
            <Divider style={{ borderColor: "#f5f5f5" }} className="m-0" />
            <div className="rounded-lg p-4 flex justify-between w-full ">
              <p className="m-2 py-4 text-base text-white w-11/12">
                販売会などのイベントで
                <br /> 利用可能なポイント
              </p>
              <p className="m-2 py-4 text-2xl text-white align-middle">
                {personalInfo?.kakeruPoint || "0"}pt
              </p>
            </div>
            {/* <div className="flex w-full justify-center mb-4">
              <Button
                href="https://lovelybone.testweb-demo.com/plugin_line_login"
                type="primary"
                size="large"
                className="w-full rounded-full px-4 btn-custom-navy h-[4rem] leading-[4rem]"
              >
                通販サイトはこちら
              </Button>
            </div> */}
            {/* <div className="flex w-full justify-around my-4">
              <Button
                href="https://lovelybone.testweb-demo.com/mypage/"
                type="secondary"
                size="large"
                className="mx-4 w-full rounded-full px-4 font-bold btn-custom-white"
              >
                ご注文履歴
              </Button>
              <Button
                href="https://lovelybone.testweb-demo.com/mypage/favorite"
                type="secondary"
                size="large"
                className="mx-4 w-full rounded-full px-4 font-bold btn-custom-white"
              >
                お気に入り商品
              </Button>
            </div> */}
          </div>
        </div>
        <p className="text-xs m-0 tracking-tight w-full p-0 whitespace-pre-wrap text-center ">
          {`※ポイントの加算タイミングは\n新規登録時・商品発送時となります。`}
        </p>
        {personalInfo?.memberPets && personalInfo?.memberPets?.length > 0 ? (
          <div className="mt-4">
            <div className="bg-custom-black rounded-lg mb-4">
              <p className="p-6 text-center text-white font-bold text-xl">
                ペットプロフィール
              </p>
              <Row gutter={[8, 8]} className="p-4">
                {personalInfo.memberPets.map((pet) => (
                  <Col span={24} key={pet?.petId}>
                    <div className="rounded-lg border border-dashed border-white text-white p-4">
                      <p className="text-center">
                        <IoPaw className="align-middle text-lg mr-2" />
                        <span className="align-middle text-lg font-bold ">
                          {pet?.name}
                        </span>
                      </p>
                      <p className="text-center text-sm mb-4"></p>
                      <p className="text-center text-sm">
                        {pet?.birthday
                          ? moment().diff(moment(pet.birthday), "years")
                          : "ー"}
                        歳 （
                        {pet?.birthday
                          ? moment(pet.birthday).format("YYYY年M月D日")
                          : "ー年ー月ー日"}
                        ⽣まれ）
                      </p>
                      <p className="text-center text-sm">
                        {COMMONS.GET_PET_SIZE_BY_VALUE(pet?.size)}・
                        {COMMONS.GET_PET_GENDER_BY_VALUE(pet?.gender)}
                      </p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="flex mt-8 justify-center">
          <Link
            to={`${COMMONS.CLIENT_PROFILE_UPDATE_ROUTE}/${liffId}/${storeId}`}
            className="text-base text-black font-bold border-b border-solid"
          >
            <TbPencil className="anticon text-2xl mr-2" />
            飼い主・ペットプロフィールを編集する
          </Link>
        </div>

        {/* <div className="mt-12">
          <Divider className="my-3" />
          <Row
            justify="space-between"
            className="font-bold"
            style={{ color: publicSettings?.PRIMARY_COLOR }}
          >
            <Col>入会日</Col>
            <Col>
              {personalInfo?.memberSince
                ? moment(personalInfo.memberSince).format("YYYY年M月D日")
                : "ー"}
            </Col>
          </Row>
          <Divider className="my-3" />
          <Row
            justify="space-between"
            className="font-bold"
            style={{ color: publicSettings?.PRIMARY_COLOR }}
          >
            <Col>有効期限</Col>
            <Col>
              {membershipInfo?.activeUntil
                ? moment(membershipInfo.activeUntil, "YYYY-MM-DD").format(
                    "YYYY年M月D日"
                  )
                : "ー"}
            </Col>
          </Row>
          <Divider className="my-3" />
        </div> */}
      </div>
    </div>
  )
}

export default Membership
