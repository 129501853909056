import { CameraOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons"
import {
  Button,
  Col,
  DatePicker as AntdDatePicker,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Upload,
} from "antd"
import * as API from "common/api"
import * as COMMONS from "common/common"
import { ClientTopBarComponent } from "components"
import moment from "moment"
import "moment/locale/ja"
import React, { useState } from "react"
import { isMobile } from "react-device-detect"
import { IoPaw } from "react-icons/io5"
import ImgCrop from "antd-img-crop"
import DatePicker from "react-mobile-datepicker"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import "antd/es/slider/style"

moment.locale("ja")

const CustomUpload = styled(Upload)`
  .ant-upload {
    width: 100%;
  }
`

const ProfileUpdate = (props) => {
  const { publicSettings, storeInfo, accessToken } = props
  const { liffId, storeId } = useParams()
  const navigate = useNavigate()

  const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()
  const [updateProfileForm] = Form.useForm()
  const queryClient = useQueryClient()
  const [profile, setProfile] = useState({})
  const [isBirthdayPickerOpen, setIsBirthdayPickerOpen] = useState(false)
  const [birthdayPickerValue, setBirthdayPickerValue] = useState(
    moment("2000-01-01", "YYYY-MM-DD").toDate()
  )
  const [currentBirthdayPicker, setCurrentBirthdayPicker] = useState(undefined)
  const [coverPhoto, setCoverPhoto] = useState(undefined)

  useQuery(
    [API.QUERY_KEY_CLIENT_PERSONAL_INFO, accessToken],
    () => API.CLIENT_GET_PERSONAL_INFO(accessToken),
    {
      enabled: !!accessToken,
      onSuccess: (response) => {
        if (isMountedRef.current) {
          setProfile(response?.data || {})

          updateProfileForm.setFieldsValue({
            lastName: response?.data?.lastName,
            firstName: response?.data?.firstName,
            lastNameKana: response?.data?.lastNameKana,
            firstNameKana: response?.data?.firstNameKana,
            // postalCode: response?.data.postalCode,
            // prefecture: response?.data.prefecture,
            // city: response?.data.city,
            // address: response?.data.address,
            // phoneNumber: response?.data.phoneNumber,
            // email: response?.data.email,
            // password: response?.data.password,
            cover: response?.data?.coverUrl,
            pets: response?.data?.memberPets
              ? response?.data.memberPets.map((pet) => ({
                  name: pet?.name,
                  birthday: isMobile
                    ? pet?.birthday || ""
                    : pet?.birthday
                    ? moment(pet?.birthday)
                    : undefined,
                  birthdayText: pet?.birthday
                    ? moment().diff(moment(pet.birthday), "years")
                    : undefined,
                  size: pet?.size,
                  gender: pet?.gender,
                }))
              : [{}],
          })
        }
      },
      onError: (error) => {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      },
    }
  )

  const updateProfileMutation = useMutation(API.CLIENT_UPDATE_PROFILE, {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        message.success(COMMONS.SUCCESS_UPDATE_MSG)
        queryClient.invalidateQueries({
          queryKey: [API.QUERY_KEY_CLIENT_PERSONAL_INFO],
        })
        navigate(`${COMMONS.CLIENT_MEMBERSHIP_ROUTE}/${liffId}/${storeId}`)
      }
    },
    onError: (error) => {
      message.error(COMMONS.ERROR_SYSTEM_MSG)
    },
  })

  const updateProfileHandler = (data) => {
    const paramData = {
      lastName: data.lastName,
      firstName: data.firstName,
      lastNameKana: data.lastNameKana,
      firstNameKana: data.firstNameKana,
      // postalCode: data.postalCode,
      // prefecture: data.prefecture,
      // city: data.city,
      // address: data.address,
      // phoneNumber: data.phoneNumber,
      // email: data.email,
      // password: data.password,
      coverUrl: coverPhoto?.file || undefined,
      pets: data.pets
        ? data.pets.map((pet) => ({
            ...pet,
            birthday: pet?.birthday
              ? moment(pet.birthday).format("YYYY-MM-DD")
              : undefined,
          }))
        : [],
      affiliateStore: storeId,
      accessToken: accessToken,
    }

    updateProfileMutation.mutate(paramData)
  }

  return (
    <div className="mt-4">
      <ClientTopBarComponent
        publicSettings={publicSettings}
        storeInfo={storeInfo}
      />
      <p className="my-12 text-center text-2xl font-bold">プロフィール編集</p>
      <Row gutter={[0, 0]} justify="center" className="mb-8">
        <Col span={24}>
          <Form
            form={updateProfileForm}
            name="updateProfileForm"
            onFinish={updateProfileHandler}
            size="large"
            layout="horizontal"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            labelAlign="left"
            requiredMark={false}
            colon={false}
            scrollToFirstError
            initialValues={{
              pets: [{}],
            }}
          >
            <p className="text-xl font-bold w-full border-b border-dashed py-4 px-6 mb-8">
              <IoPaw className="align-middle text-2xl mr-2" />
              <span className="align-middle">飼い主さまのプロフィール</span>
            </p>
            <Form.Item
              required
              label={<p className="text-lg">氏名（漢字）</p>}
              className="px-6"
            >
              <Form.Item
                name="lastName"
                className="inline-block mr-2"
                style={{ width: "calc(50% - 0.25rem)" }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "姓を入力してください",
                  },
                  {
                    max: 50,
                    message: "50文字未満である必要があります",
                  },
                ]}
              >
                <Input
                  placeholder="例：山田"
                  onPressEnter={(e) => e.preventDefault()}
                />
              </Form.Item>
              <Form.Item
                name="firstName"
                className="inline-block"
                style={{ width: "calc(50% - 0.25rem)" }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "名を入力してください",
                  },
                  {
                    max: 50,
                    message: "50文字未満である必要があります",
                  },
                ]}
              >
                <Input
                  placeholder="例：太郎"
                  onPressEnter={(e) => e.preventDefault()}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              required
              label={<p className="text-lg">氏名（フリガナ）</p>}
              className="px-6"
            >
              <Form.Item
                name="lastNameKana"
                className="inline-block mr-2"
                style={{ width: "calc(50% - 0.25rem)" }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "セイを入力してください",
                  },
                  {
                    max: 50,
                    message: "50文字未満である必要があります",
                  },
                ]}
              >
                <Input
                  placeholder="例：ヤマダ"
                  onPressEnter={(e) => e.preventDefault()}
                />
              </Form.Item>
              <Form.Item
                name="firstNameKana"
                className="inline-block"
                style={{ width: "calc(50% - 0.25rem)" }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "メイを入力してください",
                  },
                  {
                    max: 50,
                    message: "50文字未満である必要があります",
                  },
                ]}
              >
                <Input
                  placeholder="例：タロウ"
                  onPressEnter={(e) => e.preventDefault()}
                />
              </Form.Item>
            </Form.Item>
            {/* <Form.Item
              name="postalCode"
              className="text-center px-6"
              label={<p className="text-lg">郵便番号</p>}
              rules={[
                {
                  required: true,
                  message: "郵便番号を入力してください",
                },
                {
                  whitespace: true,
                  message: "有効な郵便番号を入力してください",
                },
                {
                  length: 7,
                  message: "",
                },
              ]}
            >
              <COMMONS.NUMERIC_INPUT
                placeholder=" 〒 1030015"
                maxLength={7}
                allowClear
                onPressEnter={(e) => {
                  e.preventDefault();
                  postalSearchHandler();
                }}
                onChange={(e) => {
                  postalSearchHandler();
                }}
                pattern="[0-9]*"
                inputMode="numeric"
              />
            </Form.Item>
            <Form.Item
              name="prefecture"
              className="text-center px-6"
              label={<p className="text-lg">都道府県</p>}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "入力してください",
                },
              ]}
            >
              <select
                className="w-full hover:border hover:shadow-green-600"
                style={{
                  minHeight: "36px",
                  lineHeight: "34px",
                  backgroundImage:
                    "url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6dHJhbnNwYXJlbnQ7fS5jbHMtMntmaWxsOiM0NDQ7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5hcnJvd3M8L3RpdGxlPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjQuOTUiIGhlaWdodD0iMTAiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMS40MSA0LjY3IDIuNDggMy4xOCAzLjU0IDQuNjcgMS40MSA0LjY3Ii8+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjMuNTQgNS4zMyAyLjQ4IDYuODIgMS40MSA1LjMzIDMuNTQgNS4zMyIvPjwvc3ZnPg==)",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "100% 50%",
                  WebkitAppearance: "none",
                  MozAppearance: "none",
                  textAlign: "left",
                  paddingRight: "34px",
                  paddingLeft: "10px",
                  outline: 0,
                  fontSize: "15px",
                  border: "1px solid #D4D4D4",
                  borderRadius: "2px",
                }}

              >
                <option value="" disabled selected hidden>
                  選択してください
                </option>
                {COMMONS.PREFECTURES.map((pref) => (
                  <option
                    key={pref.value}
                    value={pref.value}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {pref.label}
                  </option>
                ))}
              </select>
            </Form.Item>
            <Form.Item
              name="city"
              className="text-center px-6"
              label={<p className="text-lg">市区町村</p>}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "入力してください",
                },
              ]}
            >
              <Input placeholder="入力してください" autoSize />
            </Form.Item>
            <Form.Item
              name="address"
              className="text-center px-6"
              label={<p className="text-lg">番地</p>}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "入力してください",
                },
              ]}
            >
              <Input placeholder="4-3" autoSize />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              className="text-center px-6"
              label={<p className="text-lg">電話番号</p>}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "入力してください",

                },
              ]}
            >
              <Input placeholder="44444444444"
                maxLength={11}
                allowClear
                pattern="[0-9]*"
                inputMode="numeric" />
            </Form.Item>
            <Form.Item
              name="email"
              className="text-center px-6"
              label={<p className="text-lg">メールアドレス</p>}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "入力してください",
                },
              ]}
            >
              <Input placeholder="kakeru@example.com" autoSize />
            </Form.Item>
            <Form.Item
              name="password"
              className="text-center px-6"
              label={<p className="text-lg">パスワード</p>}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "入力してください",
                },
              ]}
            >
              <Input placeholder="" minLength={8} maxLength={32} autoSize type="password" />
            </Form.Item> */}
            <Form.Item
              name="cover"
              label={<p className="text-lg">会員証の背景画像</p>}
              className="text-center px-6"
              valuePropName="file"
              extra={
                <p className="mt-2 text-xs">
                  ※かわいいペットたちの写真をぜひ登録してください
                </p>
              }
            >
              <ImgCrop
                grid
                zoom={false}
                rotate={false}
                aspect={2.6}
                quality={0.8}
                minZoom={1}
                maxZoom={1}
                modalTitle="会員証の背景画像"
                modalOk="確定"
                modalCancel="キャンセル"
              >
                <CustomUpload
                  accept=".jpg, .jpeg, .png"
                  maxCount={1}
                  listType="picture"
                  showUploadList={false}
                  beforeUpload={async (file) => {
                    setCoverPhoto({
                      file: await COMMONS.RESIZE_FILE(file, "file"),
                      preview: await COMMONS.GET_BASE_64(file),
                    })

                    return false
                  }}
                >
                  {(coverPhoto && coverPhoto?.preview) || profile?.coverUrl ? (
                    <div
                      className="flex justify-center items-center rounded w-full cursor-pointer"
                      style={{ height: "150px", maxHeight: "150px" }}
                    >
                      <img
                        src={
                          coverPhoto?.preview ||
                          `${API.UPLOADS_URL}${profile?.coverUrl}`
                        }
                        alt="会員証の背景画像"
                        className="max-w-full rounded"
                        style={{
                          maxHeight: "150px",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className="flex justify-center items-center bg-white border border-custom-gray rounded w-full cursor-pointer"
                      style={{ height: "150px", maxHeight: "150px" }}
                    >
                      <p className="text-center text-2xl font-bold">
                        <CameraOutlined className="mr-2" />
                      </p>
                    </div>
                  )}
                </CustomUpload>
              </ImgCrop>
            </Form.Item>
            <p className="text-xl font-bold w-full border-b border-dashed py-4 px-6 mb-8">
              <IoPaw className="align-middle text-2xl mr-2" />
              <span className="align-middle">わんちゃんのプロフィール</span>
            </p>
            <Form.List name="pets">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      key={field.key}
                      label={
                        <p className="mr-8 text-lg">{`第${index + 1}ペット`}</p>
                      }
                      className="px-6"
                    >
                      <Form.Item
                        label={<p className="text-base">名前</p>}
                        name={[field.name, "name"]}
                        fieldKey={[field.fieldKey, "name"]}
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                        rules={[
                          {
                            required: true,
                            message: "名前を入力してください",
                          },
                        ]}
                      >
                        <Input
                          placeholder="例：チョコ"
                          onPressEnter={(e) => e.preventDefault()}
                        />
                      </Form.Item>
                      <Form.Item
                        label={<p className="text-base">生年月日</p>}
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                        className="mb-0"
                      >
                        <Form.Item
                          name={[field.name, "birthday"]}
                          fieldKey={[field.fieldKey, "birthday"]}
                          className="inline-block mr-2"
                          style={{ width: "calc(70% - 0.25rem)" }}
                        >
                          {isMobile ? (
                            <Input
                              placeholder="例：2010-01-01"
                              readOnly
                              className="cursor-pointer w-full"
                              onPressEnter={(e) => e.preventDefault()}
                              onClick={() => {
                                setCurrentBirthdayPicker(field.name)
                                setBirthdayPickerValue(
                                  updateProfileForm.getFieldValue("pets")[
                                    field.name
                                  ]?.birthday
                                    ? moment(
                                        updateProfileForm.getFieldValue("pets")[
                                          field.name
                                        ].birthday
                                      ).toDate()
                                    : undefined
                                )
                                setIsBirthdayPickerOpen(true)
                              }}
                            />
                          ) : (
                            <AntdDatePicker
                              inputReadOnly
                              showToday={false}
                              allowClear={false}
                              defaultPickerValue={moment(
                                "2000-01-01",
                                "YYYY-MM-DD"
                              )}
                              disabledDate={(currentDate) =>
                                currentDate.isAfter()
                              }
                              onChange={(value) => {
                                updateProfileForm.setFieldsValue({
                                  pets: updateProfileForm
                                    .getFieldValue("pets")
                                    ?.map((pet, i) =>
                                      i === field.name
                                        ? {
                                            ...pet,
                                            birthdayText: moment().diff(
                                              value,
                                              "years"
                                            ),
                                          }
                                        : pet
                                    ),
                                })
                              }}
                              className="w-full"
                            />
                          )}
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "birthdayText"]}
                          fieldKey={[field.fieldKey, "birthdayText"]}
                          className="inline-block"
                          style={{ width: "calc(30% - 0.25rem)" }}
                        >
                          <Input
                            readOnly
                            pattern="[0-9]*"
                            inputMode="numeric"
                            onPressEnter={(e) => e.preventDefault()}
                            placeholder=""
                            maxLength={2}
                            suffix="歳"
                          />
                        </Form.Item>
                      </Form.Item>
                      <Form.Item
                        label={<p className="text-base">サイズ</p>}
                        name={[field.name, "size"]}
                        fieldKey={[field.fieldKey, "size"]}
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                        rules={[
                          {
                            required: true,
                            message: "サイズを選択してください",
                          },
                        ]}
                      >
                        <Radio.Group
                          options={COMMONS.PET_SIZE}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                      <Form.Item
                        label={<p className="text-base">性別</p>}
                        name={[field.name, "gender"]}
                        fieldKey={[field.fieldKey, "gender"]}
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                        rules={[
                          {
                            required: true,
                            message: "性別を選択してください",
                          },
                        ]}
                      >
                        <Radio.Group
                          options={COMMONS.PET_GENDER}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <Form.Item {...field} className="text-right">
                          <Button
                            size="default"
                            danger
                            onClick={() => remove(field.name)}
                            icon={<CloseOutlined />}
                          >
                            削除する
                          </Button>
                        </Form.Item>
                      ) : null}
                      <Divider className="border-dashed" />
                    </Form.Item>
                  ))}
                  <Form.Item wrapperCol={24} className="px-6">
                    <div className="flex justify-end">
                      <Button
                        type="dashed"
                        size="default"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        ペットを追加する
                      </Button>
                    </div>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Divider />
            <Form.Item className="text-center" wrapperCol={{ span: 24 }}>
              <Button
                className="px-12 m-1"
                size="large"
                onClick={() => {
                  navigate(
                    `${COMMONS.CLIENT_MEMBERSHIP_ROUTE}/${liffId}/${storeId}`
                  )
                }}
              >
                戻る
              </Button>
              <Button
                type="primary"
                className="px-12 m-1"
                htmlType="submit"
                size="large"
                loading={updateProfileMutation.isLoading}
              >
                保存する
              </Button>
            </Form.Item>
          </Form>
          {isMobile ? (
            <DatePicker
              theme="ios"
              isOpen={isBirthdayPickerOpen}
              confirmText="確定"
              cancelText="キャンセル"
              value={birthdayPickerValue}
              min={moment("2000-01-01", "YYYY-MM-DD").toDate()}
              max={moment().toDate()}
              onSelect={(time) => {
                setIsBirthdayPickerOpen(false)
                updateProfileForm.setFieldsValue({
                  pets: updateProfileForm.getFieldValue("pets")?.map((pet, i) =>
                    i === currentBirthdayPicker
                      ? {
                          ...pet,
                          birthday: moment(time).format("YYYY-MM-DD"),
                          birthdayText: moment().diff(time, "years"),
                        }
                      : pet
                  ),
                })

                setBirthdayPickerValue(undefined)
              }}
              onCancel={() => {
                setIsBirthdayPickerOpen(false)
              }}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </div>
  )
}

export default ProfileUpdate
