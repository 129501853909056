import { Result, Spin } from "antd"
import React from "react"
import * as COMMONS from "common/common"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({ error: error })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex h-screen">
          <div className="m-auto">
            <Result
              status="500"
              title={COMMONS.ERROR_SYSTEM_MSG}
              subTitle={
                process.env.NODE_ENV !== "production" && this.state.error
                  ? this.state.error.toString()
                  : ""
              }
            />
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
