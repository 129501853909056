import { React, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Form, Input, Divider, message } from "antd"
import { LockOutlined, MailOutlined } from "@ant-design/icons"
import { useMutation } from "react-query"
import * as COMMONS from "common/common"
import * as API from "common/api"
import liff from "@line/liff"

const title = "ログイン"

const EcLogin = (props) => {
  const { logo } = props
  const { liffId, storeId } = useParams()
  const navigate = useNavigate()
  const [accessToken, setAccessToken] = useState(undefined)
  const [form] = Form.useForm()

  const ecLoginMutation = useMutation(API.EC_LOGIN, {
    onSuccess: (response) => {
      // message.success(COMMONS.ECCUBE_INTEGRATION_MSG)
      navigate(`${COMMONS.CLIENT_MEMBERSHIP_ROUTE}/${liffId}/${storeId}`)
    },
    onError: (error) => {
      if (error?.response?.status === 401) {
        message.warning("パスワードが一致しません")
      } else if (error?.response?.status === 400) {
        message.warning("パスワードが一致しません")
      } else if (error?.response?.status === 409) {
        message.warning("メール ユーザーが見つかりません")
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const registerHandler = () => {
    navigate(`${COMMONS.CLIENT_REGISTER_ROUTE}/${liffId}/${storeId}`)
  }

  const loginHandler = (formData) => {
    const data = {
      username: formData["loginUsername"],
      password: formData["loginPassword"],
      accessToken: accessToken,
    }
    ecLoginMutation.mutate(data)
  }
  useEffect(() => {
    try {
      setAccessToken(liff.getAccessToken())
    } catch (err) {
      navigate(`${COMMONS.CLIENT_LOGIN_ROUTE}/${liffId}/${storeId}`)
    }

    // eslint-disable-next-line
  }, [liffId])

  return (
    <>
      <div className="flex h-screen">
        <div className="w-11/12 sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-5 m-auto z-10 bg-white bg-opacity-75 animate__animated animate__fadeIn">
          <div className="flex mb-4">
            <img
              src={logo ? API.UPLOADS_URL + logo : "logo.svg"}
              alt="ロゴ"
              className="mx-auto rounded max-w-full"
              style={{ maxHeight: "150px" }}
            />
          </div>
          <div className="mb-10">
            <p className="text-xl text-center mb-1 font-bold w-full">{title}</p>
            <p className="text-xs text-left font-normal w-full">
              <span className="text-custom-orange">
                通販サイト会員の方は、ここからLINE ID連携ができます。
              </span>
              <br />
              連携後はID・パスワード入力ログインに加え、入力不要の便利なLINEログインをご利用いただけます♪
            </p>
          </div>
          <div className="mb-4">
            <p className="text-lg font-bold text-center w-full">
              既に通販サイト会員のお客さま
            </p>
          </div>
          <Form
            name="loginForm"
            onFinish={loginHandler}
            size="large"
            form={form}
          >
            <Form.Item
              name="loginUsername"
              rules={[
                {
                  required: true,
                  message: "ユーザー名を入力してください",
                },
              ]}
            >
              <Input
                // disabled={loginMutation.isLoading}
                name="loginUsername"
                autoCapitalize="none"
                prefix={<MailOutlined />}
                placeholder="メールアドレス"
                autoComplete="username"
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="loginPassword"
              rules={[
                {
                  required: true,
                  message: "パスワードを入力してください",
                },
              ]}
            >
              <Input.Password
                // disabled={loginMutation.isLoading}
                name="loginPassword"
                prefix={<LockOutlined />}
                type="password"
                placeholder="パスワード"
                autoComplete="current-password"
              />
            </Form.Item>
            <Form.Item className="text-center">
              <Button
                type="primary"
                htmlType="submit"
                className="ant-btn-custom w-full px-8"
                loading={ecLoginMutation.isLoading}
              >
                ログインしてLINE ID連携
              </Button>
            </Form.Item>

            <a href={COMMONS.FORGOT_URL}>パスワードの再設定はこちら</a>

            <Divider />
            <div className="text-center">
              <div className="mb-4">
                <p className="text-lg font-bold text-center  w-full">
                  まだ通販サイト会員登録が
                  <br />
                  お済みでないお客さま
                </p>
              </div>
              <Button
                type="primary"
                className="w-full"
                onClick={registerHandler}
              >
                LINEから会員登録
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}

export default EcLogin
