import React from "react"
import { Button, Divider, Menu, message, Col, Row, Image } from "antd"
import {
  SolutionOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  IdcardOutlined,
  SettingOutlined,
} from "@ant-design/icons"
import { useLocation, useNavigate } from "react-router-dom"
import { useMutation } from "react-query"
import styled from "styled-components"
import * as COMMONS from "common/common"
import * as API from "common/api"

const CustomMenu = styled(Menu)`
  .ant-menu-item-active,
  .ant-menu-item:hover,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: ${(props) =>
      props.publicSettings?.PRIMARY_COLOR
        ? props.publicSettings.PRIMARY_COLOR
        : COMMONS.PRIMARY_COLOR};
  }
`

const Sidebar = (props) => {
  const {
    publicSettings,
    isHamburger,
    isCollapsed,
    logo,
    collapseToggle,
    currentStore,
    setCurrentStore,
    stores,
  } = props
  const location = useLocation()
  const navigate = useNavigate()
  const storeId = currentStore

  const getCurrentPathWithoutLastPart = () => {
    return location.pathname.slice(0, location.pathname.lastIndexOf("/")) !== ""
      ? location.pathname.slice(0, location.pathname.lastIndexOf("/"))
      : location.pathname
  }

  const logoutMutation = useMutation(API.ADMIN_LOGOUT, {
    onSuccess: (response) => {
      message.success(COMMONS.SUCCESS_LOGOUT_MSG)
      navigate(COMMONS.ADMIN_LOGIN_ROUTE)
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      } else {
        message.error(COMMONS.ERROR_SYSTEM_MSG)
      }
    },
  })

  const logoutHandler = () => {
    logoutMutation.mutate()
  }

  const handleClick = (event) => {
    switch (event.key) {
      case COMMONS.ADMIN_MEMBERS_ROUTE:
        if (storeId) {
          navigate(COMMONS.ADMIN_MEMBERS_ROUTE + "/" + storeId)
        } else {
          if (stores.length > 0) {
            setCurrentStore(stores[0])
            navigate(COMMONS.ADMIN_MEMBERS_ROUTE + "/" + stores[0])
          } else {
            message.warning(COMMONS.ERROR_NO_STORE)
          }
        }
        break
      case COMMONS.ADMIN_AUDIENCES_ROUTE:
        if (storeId) {
          navigate(COMMONS.ADMIN_AUDIENCES_ROUTE + "/" + storeId)
        } else {
          if (stores.length > 0) {
            setCurrentStore(stores[0])
            navigate(COMMONS.ADMIN_AUDIENCES_ROUTE + "/" + stores[0])
          } else {
            message.warning(COMMONS.ERROR_NO_STORE)
          }
        }
        break
      case COMMONS.ADMIN_SETTINGS_ROUTE:
        navigate(COMMONS.ADMIN_SETTINGS_ROUTE)
        break
      case "collapse":
        collapseToggle()
        break
      default:
        break
    }

    if (isHamburger) collapseToggle()
  }

  const menuItems = [
    {
      key: COMMONS.ADMIN_MEMBERS_ROUTE,
      icon: <SolutionOutlined className="text-base" />,
      label: "会員管理",
    },
    {
      key: COMMONS.ADMIN_AUDIENCES_ROUTE,
      icon: <IdcardOutlined className="text-base" />,
      label: "オーディエンス",
    },
    {
      key: COMMONS.ADMIN_SETTINGS_ROUTE,
      icon: <SettingOutlined className="text-base" />,
      label: "設定",
    },
  ]

  return (
    <div
      className="flex flex-col h-full"
      style={{ overflowX: "hidden", overflowY: "auto" }}
    >
      <div className="flex m-4" style={{ height: "32px" }}>
        <img
          src={logo ? API.UPLOADS_URL + logo : "/logo.svg"}
          alt="ロゴ"
          className="mx-auto rounded max-w-full"
          style={{ maxHeight: "30px" }}
        />
      </div>
      <div className="mb-2">
        <p
          className={`${
            isCollapsed ? "text-xs text-center" : "text-sm text-center"
          }`}
        >
          株式会社守田ミート
          <span> Lovelybone</span>
        </p>
      </div>
      <Divider className="my-2" />

      <div className="text-center px-1">
        <p
          className={`${
            isCollapsed ? "text-xs text-center" : "text-base text-center"
          }`}
        >
          KAKERU <br />
          {publicSettings?.TITLE || process.env.REACT_APP_SYSTEM_NAME}
        </p>
      </div>
      <Divider className="my-2" />
      <div className="flex-grow flex-shrink">
        <CustomMenu
          publicSettings={publicSettings}
          onClick={handleClick}
          defaultSelectedKeys={[COMMONS.ADMIN_MEMBERS_ROUTE]}
          selectedKeys={[getCurrentPathWithoutLastPart()]}
          items={menuItems}
        />
        <Divider />
        <Row justify="center" align="middle" className="mt-8">
          <Col>
            {isCollapsed ? (
              <Button
                icon={<LogoutOutlined />}
                onClick={logoutHandler}
                danger
              />
            ) : (
              <Button onClick={logoutHandler} danger>
                ログアウト
              </Button>
            )}
          </Col>
        </Row>
        <div style={{position: 'absolute', bottom: 60}}>
            <Image src={process.env.REACT_APP_LINE_QR_URL} />
        </div>
      </div>
      <Button
        block
        className="p-0"
        onClick={collapseToggle}
        icon={
          isCollapsed ? (
            <MenuUnfoldOutlined className="text-base" />
          ) : (
            <MenuFoldOutlined className="text-base" />
          )
        }
      />
    </div>
  )
}

export default Sidebar
