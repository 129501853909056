import React from "react"
import { Result } from "antd"
import * as COMMONS from "common/common"

const PermissionError = props => {
  return (
    <div className="flex h-screen animate__animated animate__zoomIn">
      <div className="m-auto">
        <Result
          status="403"
          title="401"
          subTitle={COMMONS.ERROR_401_MSG}
        />
      </div>
    </div>
  )
}

export default PermissionError
