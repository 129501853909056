import React, { Component } from "react"
import { BrowserRouter } from "react-router-dom"
import { createBrowserHistory } from "history"
import RouteManagement from "./RouteManagement"
import "./tailwind-generated.css"
import "animate.css"

const browserHistory = createBrowserHistory()

export default class App extends Component {
  render() {
    return (
      <BrowserRouter
        location={browserHistory.location}
        navigator={browserHistory}
      >
        <RouteManagement />
      </BrowserRouter>
    )
  }
}
